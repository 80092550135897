<dx-data-grid
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [showBorders]="true"
  [showRowLines]="true"
  [showColumnLines]="true"

  [dataSource]="dataSource"
  (onSelectionChanged)="onSelectionChanged($event)"
  [(selectedRowKeys)]="selectedRowKeys"
  class="grid"
>
  <dxo-toolbar>
    <dxi-item locateInMenu="auto" location="before" *ngIf="this.tokenService.getUserRole()===Role.Admin">
      <div *dxTemplate>
        <dx-button
          (onClick)="openUpdatePopup()"
          [disabled]="!this.selectedRow"
          icon="edit"
          type="default"
          [text]="'laboratoryList.updateLaboratory' | translate">
        </dx-button>
      </div>
    </dxi-item>

    <dxi-item locateInMenu="auto" location="after" *ngIf="this.tokenService.getUserRole()===Role.Admin">
      <div *dxTemplate>
        <dx-button
          (onClick)="openCreatePopup()"
          icon="plus"
          [text]="'laboratoryList.createNewLaboratory' | translate"
          stylingMode="contained"
          type="success"
        >
        </dx-button>
      </div>
    </dxi-item>
  </dxo-toolbar>

  <dxo-selection mode="single"></dxo-selection>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column
    alignment="center"
    [caption]="'laboratoryList.location' | translate"
    dataField="location">
  </dxi-column>

  <dxi-column
    alignment="center"
    [caption]="'laboratoryList.contactNumber' | translate"
    dataField="contactNumber">
  </dxi-column>

  <dxi-column
    alignment="center"
    [caption]="'laboratoryList.testTypes' | translate"
    cellTemplate="testTypesTemplate">
  </dxi-column>

  <div *dxTemplate="let laboratory of 'testTypesTemplate'">
    <span *ngFor="let testType of laboratory.data.testTypes; let last = last">
      {{ testType.name }}{{ !last ? ', ' : '' }}
    </span>
  </div>
</dx-data-grid>

<app-laboratory-form-popup
  [(visible)]="editPopupVisible"
  [isEditing]="true"
  [laboratoryId]="selectedRow?.id"
  (saveLaboratory)="onSaveLaboratory($event)"
></app-laboratory-form-popup>

<app-laboratory-form-popup
  [(visible)]="createPopupVisible"
  [isEditing]="false"
  [laboratoryId]="selectedRow?.id"
  (saveLaboratory)="onSaveLaboratory($event)"
></app-laboratory-form-popup>
