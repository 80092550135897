<div class="clinic-selector" *ngIf="allClinics.length > 0">
  <dx-button *ngIf="!isOpen" (click)="toggleSelector()"
             [text]="'clinicSelector.selectClinic' | translate">
  </dx-button>
  <div *ngIf="isOpen" class="selector-dropdown">
    <dx-select-box
      width="150"
      [(value)]="selectedClinicId"
      (onValueChanged)="changeClinic($event.value)"
      [items]="allClinics"
      [displayExpr]="'name'"
      [valueExpr]="'id'">
      <div *dxTemplate="let clinic of 'item'">
        <div class="clinic-item">
          <img *ngIf="clinic.logo" [src]="this.imageUtils.getImageSource(clinic.logo)" [alt]="'clinicInfo.clinicLogo' | translate" class="clinic-logo">
          <span>{{ clinic.name }}</span>
        </div>
      </div>
    </dx-select-box>
  </div>
</div>
