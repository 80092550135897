import {CommonModule} from '@angular/common';
import {Component, NgModule, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {ValidationCallbackData} from 'devextreme-angular/common';
import {DxFormModule} from 'devextreme-angular/ui/form';
import {DxLoadIndicatorModule} from 'devextreme-angular/ui/load-indicator';
import {AuthenticationService, ClinicDto, ClinicService, ResetPasswordCommand} from "../../services/swagger";
import {NotificationService} from "../../services/notification.service";
import {ImageUtilsService} from "../../services/image-utils.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
  loading = false;
  formData: any = {};
  clinic: ClinicDto = {} as ClinicDto;

  constructor(
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private clinicService: ClinicService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const clinicId = +params.get('clinicId')!;
      this.loadClinic(clinicId);
    });
  }

  loadClinic(clinicId: number) {
    this.clinicService.getClinicById(clinicId).subscribe({
      next: result => {
        this.clinic = result;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  async onSubmit(e: Event) {
    e.preventDefault();

    this.loading = true;

    const resetRequest: ResetPasswordCommand = {
      email: this.formData.email,
      password: this.formData.password,
      confirmPassword: this.formData.confirmPassword,
      passwordResetCode: this.formData.passwordResetCode,
    }

    this.authenticationService.resetPassword(this.clinic.id, resetRequest).subscribe({
      next: (response) => {
        this.loading = false;
        this.router.navigate(["/login-form"])
        this.notificationService.success(this.translate.instant("changePassword.changed"));
      },
      error: (err) => {
        switch (err.status) {
          case 401:
            this.notificationService.warning(this.translate.instant("changePassword.incorrectResetCode"));
            break;
          case 404:
            this.notificationService.warning(this.translate.instant("changePassword.emailNotFound"));
            break;
          default:
            this.notificationService.error(this.translate.instant("changePassword.errorUpdating"));
            console.log(err);
            break;
        }

        this.loading = false;

      }, complete: () => {
        this.loading = false
      }
    })
  }

  confirmPassword = (e: ValidationCallbackData) => {
    return e.value === this.formData.password;
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    TranslateModule
  ],
  declarations: [ChangePasswordFormComponent],
  exports: [ChangePasswordFormComponent]
})
export class CreateAccountFormModule {
}
