import {Component} from '@angular/core';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data";
import {lastValueFrom} from "rxjs";
import {NotificationService} from "../../shared/services/notification.service";
import {TokenService} from "../../shared/services/token.service";
import {Role} from "../../shared/services/role.enum";
import {
  CreateTestTypeCommand,
  GroupingInfo,
  SortingInfo,
  SummaryInfo,
  TestTypeDto,
  TestTypeService,
  UpdateTestTypeCommand
} from "../../shared/services/swagger";
import {confirm} from "devextreme/ui/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-test-type-list',
  templateUrl: './test-type-list.component.html',
  styleUrls: ['./test-type-list.component.scss']
})
export class TestTypeListComponent {
  // @ts-ignore
  dataSource: DataSource;

  clinicId: number = 0;
  selectedRowKeys: any[] = [];
  selectedRow: TestTypeDto | null = null;

  popupVisible: boolean = false;
  isEditing: boolean = false;

  isAdmin: boolean;

  constructor(
    private testTypeService: TestTypeService,
    private notificationService: NotificationService,
    private tokenService: TokenService,
    private translate: TranslateService
  ) {
    this.isAdmin = this.tokenService.getUserRole() === Role.Admin;
    this.clinicId = this.tokenService.getClinicId();
    this.initializeDataSource();
  }

  private initializeDataSource(): void {
    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: LoadOptions) => this.isAdmin ? this.loadAllTestTypes(loadOptions) : this.loadAllTestTypesNotSoftDeleted(loadOptions),
        remove: (key) => lastValueFrom(this.testTypeService.deleteTestType(key))
      }),
    });
  }

  private async loadAllTestTypes(loadOptions: LoadOptions): Promise<any> {
    try {
      let response = await lastValueFrom(
        this.testTypeService.getAllTestTypes(
          loadOptions.requireTotalCount,
          loadOptions.requireGroupCount,
          false, // isCountQuery
          false, // isSummaryQuery
          loadOptions.skip,
          loadOptions.take,
          loadOptions.sort as SortingInfo[],
          loadOptions.group as GroupingInfo[],
          undefined, // filter
          loadOptions.totalSummary as SummaryInfo[],
          loadOptions.groupSummary as SummaryInfo[],
          loadOptions.select as string[],
          undefined, // preSelect
          undefined, // remoteSelect
          undefined, // remoteGrouping
          undefined, // expandLinqSumType
          undefined, // primaryKey
          undefined, // defaultSort
          undefined, // stringToLower
          undefined, // paginateViaPrimaryKey
          undefined, // sortByPrimaryKey
          undefined  // allowAsyncOverSync
        )
      );
      return {
        data: response.data,
        totalCount: response.totalCount,
        summary: response.summary,
        groupCount: response.groupCount,
      };
    } catch (e) {
      throw 'Data loading error';
    }
  }

  private async loadAllTestTypesNotSoftDeleted(loadOptions: LoadOptions): Promise<any> {
    try {
      let response = await lastValueFrom(
        this.testTypeService.getAllTestTypesNotSoftDeleted(
          loadOptions.requireTotalCount,
          loadOptions.requireGroupCount,
          false, // isCountQuery
          false, // isSummaryQuery
          loadOptions.skip,
          loadOptions.take,
          loadOptions.sort as SortingInfo[],
          loadOptions.group as GroupingInfo[],
          undefined, // filter
          loadOptions.totalSummary as SummaryInfo[],
          loadOptions.groupSummary as SummaryInfo[],
          loadOptions.select as string[],
          undefined, // preSelect
          undefined, // remoteSelect
          undefined, // remoteGrouping
          undefined, // expandLinqSumType
          undefined, // primaryKey
          undefined, // defaultSort
          undefined, // stringToLower
          undefined, // paginateViaPrimaryKey
          undefined, // sortByPrimaryKey
          undefined  // allowAsyncOverSync
        )
      );
      return {
        data: response.data,
        totalCount: response.totalCount,
        summary: response.summary,
        groupCount: response.groupCount,
      };
    } catch (e) {
      throw 'Data loading error';
    }
  }

  onSaveTestType(testTypeData: CreateTestTypeCommand | UpdateTestTypeCommand) {
    if (this.isEditing) {
      this.updateTestType(testTypeData as UpdateTestTypeCommand);
    } else {
      this.createTestType(testTypeData as CreateTestTypeCommand);
    }
  }

  createTestType(createTestTypeRequest: CreateTestTypeCommand): void {
    this.testTypeService.createTestType(this.clinicId, createTestTypeRequest).subscribe({
      next: () => {
        this.popupVisible = false;
        this.dataSource.reload();
        this.notificationService.success(this.translate.instant("testTypeList.created"));
      },
      error: (error) => {
        this.notificationService.error(this.translate.instant("testTypeList.errorCreating"));
        console.log(error)
      }
    });
  }

  updateTestType(updateTestTypeCommand: UpdateTestTypeCommand): void {
    this.testTypeService.updateTestType(this.selectedRow?.id!, updateTestTypeCommand).subscribe({
      next: () => {
        this.popupVisible = false;
        this.dataSource.reload();
        this.notificationService.success(this.translate.instant("testTypeList.updated"));
      },
      error: (error) => {
        this.notificationService.error(this.translate.instant("testTypeList.errorUpdating"));
        console.log(error)
      }
    });
  }

  deleteTestType(): void {
    if (this.selectedRow) {
      this.confirmDeletion()
        .then((dialogResult: boolean) => {
          if (dialogResult) {
            this.checkIfTestTypeInUse();
          }
        });
    }
  }

  private confirmDeletion(): Promise<boolean> {
    return confirm(
      this.translate.instant("testTypeList.confirmDeletion"),
      this.translate.instant("testTypeList.confirmDeletionTitle")
    );
  }

  private checkIfTestTypeInUse(): void {
    this.testTypeService.isInUse(this.selectedRow!.id).subscribe({
      next: (isInUse) => {
        if (isInUse) {
          this.handleSoftDelete();
        } else {
          this.deleteTestTypePermanently();
        }
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("testTypeList.errorDeleting"));
      }
    });
  }

  private handleSoftDelete(): void {
    this.confirmSoftDelete().then((dialogResult: boolean) => {
      if (dialogResult) {
        this.testTypeService.softDeleteTestType(this.selectedRow!.id).subscribe({
          next: () => {
            this.notificationService.success(this.translate.instant("testTypeList.updated"));
            this.deselectRow();
            this.dataSource.reload();
          },
          error: (error) => {
            console.log(error);
            this.notificationService.error(this.translate.instant("testTypeList.errorDeleting"));
          }
        });
      }
    });
  }

  private confirmSoftDelete(): Promise<boolean> {
    return confirm(
      this.translate.instant("testTypeList.confirmSoftDeletion"),
      this.translate.instant("testTypeList.confirmSoftDeletionTitle")
    );
  }

  private deleteTestTypePermanently(): void {
    this.dataSource.store().remove(this.selectedRow!.id)
      .then(() => {
        this.notificationService.success(this.translate.instant("testTypeList.deleted"));
        this.deselectRow();
        this.dataSource.reload();
      })
      .catch((err: any) => {
        console.log(err);
        this.notificationService.error(this.translate.instant("testTypeList.errorDeleting"));
      });
  }

  private deselectRow() {
    this.selectedRow = null;
    this.selectedRowKeys = [];
  }

  openCreatePopup() {
    this.isEditing = false;
    this.popupVisible = true;
  }

  openUpdatePopup() {
    this.isEditing = true;
    this.popupVisible = true;
  }

  onSelectionChanged(e: any): void {
    this.selectedRow = e.selectedRowsData[0];
  }

  undeleteTestType(): void {
    if (this.selectedRow) {
      this.confirmSoftUndelete()
        .then((dialogResult: boolean) => {
          if (dialogResult) {
            this.performUndelete();
          }
        });
    }
  }

  private confirmSoftUndelete(): Promise<boolean> {
    return confirm(
      this.translate.instant("testTypeList.confirmSoftUndeletion"),
      this.translate.instant("testTypeList.confirmSoftUndeletionTitle")
    );
  }

  private performUndelete(): void {
    this.testTypeService.softUndeleteTestType(this.selectedRow!.id).subscribe({
      next: () => {
        this.notificationService.success(this.translate.instant("testTypeList.undeleted"));
        this.deselectRow();
        this.dataSource.reload();
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("testTypeList.errorUndeleting"));
      }
    });
  }

}
