import {CommonModule} from '@angular/common';
import {Component, Input, NgModule} from '@angular/core';
import {CardAnalyticsModule} from '../card-analytics/card-analytics.component';
import {ApplyPipeModule} from "../../../pipes/apply.pipe";
import {PatientDto, TestRequestDto} from "../../../shared/services/swagger";

@Component({
  selector: 'ticker-card',
  templateUrl: './ticker-card.component.html',
  styleUrls: ['./ticker-card.component.scss'],
})

export class TickerCardComponent {
  // @ts-ignore
  @Input() titleText: string;

  @Input() data: Array<any> | null = null;

  @Input() total: string | null = null;

  // @ts-ignore
  @Input() percentage: number;

  // @ts-ignore
  @Input() icon: string;

  @Input() tone?: 'warning' | 'info';

  @Input() contentClass: string | null = null;

  getTotal(data: Array<{ value?: number, total?: number }>): number {
    console.log(data)
    return data.length;
  }

  abs(value: number): number {
    return Math.abs(value);
  }

  getIconClass = () => this.tone || (this.percentage > 0 ? 'positive' : 'negative');
}

@NgModule({
  imports: [
    CardAnalyticsModule,
    ApplyPipeModule,
    CommonModule,
  ],
  declarations: [TickerCardComponent],
  exports: [TickerCardComponent],
})
export class TickerCardModule {
}
