import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  CreateEmailTemplateCommand,
  EmailTemplateDto,
  EmailTemplateService,
  MessageType,
  MessageTypeDto,
  SmsTemplateDto,
  UpdateEmailTemplateCommand
} from "../../shared/services/swagger";
import {DxHtmlEditorComponent} from "devextreme-angular";

@Component({
  selector: 'app-email-template-form-popup',
  templateUrl: './email-template-form-popup.component.html',
  styleUrls: ['./email-template-form-popup.component.scss']
})
export class EmailTemplateFormPopupComponent {
  @ViewChild(DxHtmlEditorComponent, {static: false}) htmlEditor!: DxHtmlEditorComponent;

  @Input() visible: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() templateId: number | undefined = undefined;
  @Input() clinicId: number = 0;
  @Input() isReadonly: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() saveTemplate = new EventEmitter<CreateEmailTemplateCommand | UpdateEmailTemplateCommand>();

  messageTypes: { value: MessageType, name: string }[] = [];
  availableParameters: string[] = [];
  formData: any = {};
  emailTemplate: EmailTemplateDto = {} as EmailTemplateDto;

  constructor(
    private emailTemplateService: EmailTemplateService
  ) {
  }

  onShowing() {
    this.resetForm();
    this.initializeForm();
  }

  initializeForm() {
    this.loadMessageTypes();

    if ((this.isEditing || this.isReadonly) && this.templateId != undefined) {
      this.emailTemplateService.getTemplateById(this.templateId).subscribe({
        next: (emailTemplateDto: EmailTemplateDto) => {
          this.emailTemplate = emailTemplateDto;
          this.formData = {
            messageType: this.emailTemplate.messageType,
            subject: this.emailTemplate.subject,
            htmlContent: this.emailTemplate.htmlContent
          };
          this.loadAvailableParameters(this.emailTemplate.messageType);
        },
        error: err => {
          console.error('Error fetching SMS template:', err);
        }
      });
    }
  }

  resetForm() {
    this.formData = {};
    this.availableParameters = [];
    this.messageTypes = [];
  }

  onHiding() {
    this.visibleChange.emit(false);
  }

  loadMessageTypes() {
    if (this.isEditing || this.isReadonly) {
      this.messageTypes = Object.entries(MessageType).map(([key, value]) => ({value, name: key}));
    } else {
      if (this.clinicId === null) {
        console.error('Clinic ID is null');
        return;
      }
      this.emailTemplateService.getUndefinedEmailTypes(this.clinicId).subscribe({
        next: (types: MessageType[]) => {
          this.messageTypes = types.map(type => ({value: type, name: MessageType[type]}));
        },
        error: err => {
          console.error('Error fetching undefined SMS types:', err);
          this.messageTypes = [];
        }
      });
    }
  }

  onMessageTypeChange(messageType: MessageType) {
    if (messageType !== undefined && messageType !== null) {
      this.loadAvailableParameters(messageType);
    }
  }

  loadAvailableParameters(messageType: MessageType) {
    this.emailTemplateService.getParametersForEmailType(messageType).subscribe({
      next: (response: MessageTypeDto) => {
        this.availableParameters = response.parameters!;
      },
      error: err => {
        console.error('Error fetching parameters:', err);
        this.availableParameters = [];
      }
    });
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const templateData = {
      messageType: this.formData.messageType,
      subject: this.formData.subject,
      htmlContent: this.formData.htmlContent
    };

    this.saveTemplate.emit(templateData);
    this.onHiding();
  }

  insertParameterToEditor(param: string) {
    const paramText = `{{${param}}}`;
    const selection = this.htmlEditor.instance.getSelection();
    const index = selection ? selection.index : 0;
    this.htmlEditor.instance.insertText(index, paramText, {});
  }
}
