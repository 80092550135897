import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Component, Input } from '@angular/core';
import { SettingsService } from "../../shared/services/AppSettings/settings.service";
import { DxButtonModule, DxMenuModule } from 'devextreme-angular';

@Component({
  selector: 'app-lang-picker',
  templateUrl: './lang-picker.component.html',
  styleUrls: ['./lang-picker.component.scss']
})
export class LangPickerComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private settings: SettingsService
  ) {
    const lang = this.settings.getLanguage();
    this.selectedValue = this.langs.find((x) => x.value === lang)!.value;
  }

  @Input() isMenuVisible = false; // Menu visibility flag
  selectedValue = '';

  langs = [
    { value: 'en-US', text: 'English' },
    { value: 'ro-RO', text: 'Română' },
  ];

  ngOnInit() {
    const lang = this.settings.getLanguage();
    this.selectedValue = this.langs.find((x) => x.value === lang)!.value;
  }

  langSelectionChanged(e: any) {
    this.translate.use(e.itemData.value);
    this.settings.setLanguage(e.itemData.value);
    window.location.reload();
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }
}

@NgModule({
  declarations: [LangPickerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DxButtonModule,
    DxMenuModule
  ],
  exports: [LangPickerComponent],
  providers: [SettingsService]
})
export class LangPickerModule { }
