/**
 * MedHub-Backend.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MessageType = 'PatientRegistration' | 'PasswordReset' | 'TestResultsComplete' | 'TestRequestCreated' | 'TwoFactorAuthentication';

export const MessageType = {
    PatientRegistration: 'PatientRegistration' as MessageType,
    PasswordReset: 'PasswordReset' as MessageType,
    TestResultsComplete: 'TestResultsComplete' as MessageType,
    TestRequestCreated: 'TestRequestCreated' as MessageType,
    TwoFactorAuthentication: 'TwoFactorAuthentication' as MessageType
};

