import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CreateTestTypeCommand, TestTypeDto, TestTypeService, UpdateTestTypeCommand} from "../../shared/services/swagger";

@Component({
  selector: 'app-test-type-form-popup',
  templateUrl: './test-type-form-popup.component.html',
  styleUrl: './test-type-form-popup.component.scss'
})
export class TestTypeFormPopupComponent {

  @Input() visible: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() testTypeId: number | undefined = undefined;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() saveTestType = new EventEmitter<UpdateTestTypeCommand | CreateTestTypeCommand>();

  formData: any = {}
  testType: TestTypeDto = {} as TestTypeDto;

  constructor(
    private testTypeService: TestTypeService,
  ) {
  }

  onShowing() {
    this.resetFormData();
    this.initializeFormData();
  }

  initializeFormData() {
    if (this.isEditing && this.testTypeId !== undefined) {
      this.testTypeService.getTestTypeById(this.testTypeId).subscribe({
        next: (testTypeDto: TestTypeDto) => {
          this.testType = testTypeDto;
          this.formData = {
            name: this.testType.name,
          }
        }
      })
    }
  }

  resetFormData() {
    this.formData = {};
  }

  onHiding() {
    this.visibleChange.emit(false);
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const testTypeData: CreateTestTypeCommand | UpdateTestTypeCommand = {
      name: this.formData.name,
    }

    this.saveTestType.emit(testTypeData);
  }

}
