<div class="page-container">
  <form (submit)="onSubmit($event)" class="form-container">
    <dx-form [disabled]="loading" [formData]="formData">
      <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: 'labels.email' | translate, mode: 'email' }" dataField="email" editorType="dxTextBox">
        <dxi-validation-rule [message]="'validation.emailRequired' | translate" type="required"></dxi-validation-rule>
        <dxi-validation-rule type="email" [message]="'validation.emailInvalid' | translate"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>

      <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: 'labels.resetCode' | translate }" dataField="passwordResetCode" editorType="dxTextBox">
        <dxi-validation-rule [message]="'validation.resetCodeRequired' | translate" type="required"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>

      <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: 'labels.password' | translate, mode: 'password' }" dataField="password" editorType="dxTextBox">
        <dxi-validation-rule [message]="'validation.passwordRequired' | translate" type="required"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>

      <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: 'labels.confirmPassword' | translate, mode: 'password' }" dataField="confirmPassword" editorType="dxTextBox">
        <dxi-validation-rule [message]="'validation.confirmPasswordRequired' | translate" type="required"></dxi-validation-rule>
        <dxi-validation-rule [validationCallback]="confirmPassword" message="Passwords do not match" type="custom">
        </dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div class='policy-info'>
          {{ 'policy.createAccountAgreement' | translate }} <a routerLink="#">{{ 'policy.termsOfService' | translate }}</a> {{ 'policy.and' | translate }} <a routerLink="#">{{ 'policy.privacyPolicy' | translate }}</a>
        </div>
      </dxi-item>

      <dxi-item itemType="button">
        <dxo-button-options [template]="'createAccountTemplate'" [useSubmitBehavior]="true" type="default" width="100%">
        </dxo-button-options>
      </dxi-item>

      <dxi-item>
        <div class="login-link">
          {{'changePassword.haveAnAccount' | translate}} <a routerLink="/login">{{ 'authentication.signIn' | translate }}</a>
        </div>
      </dxi-item>

      <ng-container *dxTemplate="let item of 'createAccountTemplate'">
        <div>
            <span class="dx-button-text">
              <ng-container *ngIf="loading; else notLoading">
                <dx-load-indicator [visible]="true" height="24px" width="24px"></dx-load-indicator>
              </ng-container>
            <ng-template #notLoading>{{ 'changePassword.title' | translate }}</ng-template>
            </span>
        </div>
      </ng-container>
    </dx-form>
  </form>
</div>
