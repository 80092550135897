import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import DataSource from "devextreme/data/data_source";
import {
  CreateTestRequestCommand,
  GroupingInfo,
  LaboratoryDto,
  LaboratoryService,
  PatientDto,
  PatientService,
  SortingInfo,
  SummaryInfo,
  TestRequestDto,
  TestRequestService,
  TestResultDto,
  TestTypeDto,
  UserDto,
} from "../../shared/services/swagger";
import {TokenService} from "../../shared/services/token.service";
import {Role} from "../../shared/services/role.enum";
import {AuthService} from "../../shared/services";
import {NotificationService} from "../../shared/services/notification.service";
import {lastValueFrom} from "rxjs";
import CustomStore from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data";
import {DateFormatterService} from "../../shared/services/date-formater.service";
import {TranslateService} from "@ngx-translate/core";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'app-patient-tests',
  templateUrl: './patient-tests.component.html',
  styleUrls: ['./patient-tests.component.scss'],
})
export class PatientTestsComponent implements OnInit {
  // @ts-ignore
  dataSource: DataSource;
  patient: PatientDto = {} as PatientDto;
  role: Role | null;
  remainingTestTypes: { [key: number]: TestTypeDto[] } = {};

  createTestRequestPopupVisible: boolean = false;
  doctor: UserDto = {} as UserDto;
  laboratories: LaboratoryDto[] = [];

  testResultFileUploadPopVisible: boolean = false;
  selectedRow: TestRequestDto | null = null;
  selectedRemainingTestTypes: TestTypeDto[] = [];

  clinicId: number = 0;
  isAdmin: boolean = false;
  protected readonly Role = Role;

  constructor(
    private route: ActivatedRoute,
    private testRequestService: TestRequestService,
    private tokenService: TokenService,
    private authService: AuthService,
    private laboratoryService: LaboratoryService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private router: Router,
    private patientService: PatientService,
    private dateFormatterService: DateFormatterService
  ) {
    this.role = this.tokenService.getUserRole();
    this.isAdmin = this.role === Role.Admin;
    this.clinicId = this.tokenService.getClinicId();
  }

  ngOnInit(): void {
    const patientId = Number(this.route.snapshot.paramMap.get('patientId'));

    this.patientService.getPatient(patientId).subscribe({
      next: (patient: PatientDto) => {
        this.patient = patient;
        this.initializeDataSource();
      },
      error: (error) => {
        console.error('Error fetching user:', error);
        this.notificationService.error(this.translate.instant('patientTests.failedToLoadUserUdata'));
      }
    });

    this.getCurrentUser();
    this.getLaboratories();
  }

  onSelectionChanged(e: any): void {
    this.selectedRow = e.selectedRowsData[0];
  }

  // Create TestRequest popup
  createTestRequest(createTestRequestCommand: CreateTestRequestCommand) {
    this.testRequestService.createTestRequest(createTestRequestCommand).subscribe({
      next: () => {
        this.dataSource.reload();
        this.createTestRequestPopupVisible = false;
        this.notificationService.success(this.translate.instant("patientTests.created"));
      }, error: (error) => {
        this.notificationService.error(this.translate.instant("patientTests.errorCreating"));
        console.log(error)
      }
    });
  }

  // Create test result Popup
  openTestResultUploadPopup() {
    this.selectedRemainingTestTypes = this.remainingTestTypes[this.selectedRow?.id!] || [];
    this.testResultFileUploadPopVisible = true;
  }

  onTestResultFileCreated(testResultDto: TestResultDto) {
    this.dataSource.reload();
  }

  navigateToManageFiles(testRequestId: number) {
    this.router.navigate(['pages/test-result-files', this.patient.id, testRequestId]);
  }

  navigateToViewTestResult(testResultFileId: number | null) {
    if (testResultFileId !== null) {
      this.router.navigate(['pages/test-result-view', this.patient.id, testResultFileId]);
    } else {
      this.notificationService.error(this.translate.instant('patientTests.noValid'));
    }
  }

  // Calculated values
  formatDateUpdated = (testRequestDto: TestRequestDto): string => {
    if (!testRequestDto.dateUpdated) return '';
    return this.dateFormatterService.formatToRomanianTime(testRequestDto.dateUpdated);
  }

  formatDateAdded = (testRequestDto: TestRequestDto): string => {
    if (!testRequestDto.dateAdded) return '';
    return this.dateFormatterService.formatToRomanianTime(testRequestDto.dateAdded);
  }

  formatDateAddedTestResult = (testResultDto: TestResultDto): string => {
    if (!testResultDto.dateAdded) return '';
    return this.dateFormatterService.formatToRomanianTime(testResultDto.dateAdded);
  }

  formatDateUpdatedTestResult = (testResultDto: TestResultDto): string => {
    if (!testResultDto.dateUpdated) return '';
    return this.dateFormatterService.formatToRomanianTime(testResultDto.dateUpdated);
  }

  getDoctorName(testRequestDto: TestRequestDto): string {
    return testRequestDto.doctor ? testRequestDto.doctor.firstName + " " + testRequestDto.doctor.familyName : 'N/A';
  }

  getLaboratoryLocation(testRequestDto: TestRequestDto): string {
    return testRequestDto.laboratory ? testRequestDto.laboratory.location : 'N/A';
  }

  getRemainingTestTypes(testRequestId: number): void {
    this.testRequestService.getRemainingTestTypes(testRequestId).subscribe({
      next: (result: TestTypeDto[]) => {
        this.remainingTestTypes[testRequestId] = result;
      }
    });
  }

  getTestTypeNames(testTypes: TestTypeDto[]): string {
    return testTypes.map(testType => testType.name).join(', ');
  }

  boBackToPatientList() {
    this.router.navigate(["pages/patient-list"]);
  }

  private async loadAllPatientTestsNotDeleted(loadOptions: LoadOptions): Promise<any> {
    try {
      let response = await lastValueFrom(
        this.testRequestService.getAllTestRequestsOfUserNotSoftDeleted(
          this.patient.id,
          loadOptions.requireTotalCount,
          loadOptions.requireGroupCount,
          false, // isCountQuery
          false, // isSummaryQuery
          loadOptions.skip,
          loadOptions.take,
          loadOptions.sort as SortingInfo[],
          loadOptions.group as GroupingInfo[],
          loadOptions.filter,
          loadOptions.totalSummary as SummaryInfo[],
          loadOptions.groupSummary as SummaryInfo[],
          loadOptions.select as string[],
          undefined, // preSelect
          undefined, // remoteSelect
          undefined, // remoteGrouping
          undefined, // expandLinqSumType
          undefined, // primaryKey
          undefined, // defaultSort
          undefined, // stringToLower
          undefined, // paginateViaPrimaryKey
          undefined, // sortByPrimaryKey
          undefined  // allowAsyncOverSync
        )
      );

      for (let testRequest of response.data!) {
        this.getRemainingTestTypes(testRequest.id);
      }

      return {
        data: response.data,
        totalCount: response.totalCount,
        summary: response.summary,
        groupCount: response.groupCount,
      };
    } catch (e) {
      console.log(e);
      throw 'Data loading error';
    }
  }

  private initializeDataSource() {
    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: LoadOptions) => this.loadAllPatientTestsNotDeleted(loadOptions),
        remove: (key) => lastValueFrom(this.testRequestService.deleteTestRequest(key))
      }),
    });
  }

  private getLaboratories() {
    this.laboratoryService.getAllLaboratoriesOfClinicNotDeleted().subscribe({
      next: result => {
        this.laboratories = result.data!;
      }
    });
  }

  private getCurrentUser() {
    this.authService.getUser().subscribe({
      next: (result) => {
        if (result.isOk && result.data) {
          this.doctor = result.data;
        }
      },
      error: (error) => {
        console.error('Error fetching current user:', error);
      }
    });
  }

  deleteTestRequest(): void {
    if (this.selectedRow) {
      this.confirmPatientDeletion()
        .then((dialogResult: boolean) => {
          if (dialogResult) {
            this.checkIfTestRequestIsInUse();
          }
        });
    }
  }

  private confirmPatientDeletion(): Promise<boolean> {
    return confirm(
      this.translate.instant("patientTests.confirmDeletion"),
      this.translate.instant("patientTests.confirmDeletionTitle")
    );
  }

  private checkIfTestRequestIsInUse(): void {
    this.testRequestService.isInUse(this.selectedRow!.id).subscribe({
      next: (isInUse) => {
        if (isInUse) {
          this.confirmSoftDelete().then((dialogResult: boolean) => {
            if (dialogResult) {
              this.softDeletePatient();
            }
          });
        } else {
          this.deletePatientPermanently();
        }
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("patientTests.errorDeleting"));
      }
    });
  }

  private confirmSoftDelete(): Promise<boolean> {
    return confirm(
      this.translate.instant("patientTests.confirmSoftDeletion"),
      this.translate.instant("patientTests.confirmSoftDeletionTitle")
    );
  }

  private softDeletePatient(): void {
    this.testRequestService.softDelete(this.selectedRow!.id).subscribe({
      next: () => {
        this.notificationService.success(this.translate.instant("patientTests.softDeleted"));
        this.deselectRow();
        this.dataSource.reload();
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("patientTests.errorDeleting"));
      }
    });
  }

  private deletePatientPermanently(): void {
    this.testRequestService.deleteTestRequest(this.selectedRow!.id).subscribe({
      next: () => {
        this.notificationService.success(this.translate.instant("patientTests.deleted"));
        this.deselectRow();
        this.dataSource.reload();
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("patientTests.errorDeleting"));
      }
    });
  }

  private deselectRow(): void {
    this.selectedRow = null;
  }

}
