<dx-button
  icon="fa fa-language "
  (onClick)="toggleMenu()"
  stylingMode="text"
  class="icon-button"
></dx-button>

<dx-menu
  *ngIf="isMenuVisible"
  [items]="langs"
  orientation="vertical"
  displayExpr="text"
  valueExpr="value"
  (onItemClick)="langSelectionChanged($event)"
  class="language-menu"
></dx-menu>
