<div class="user-panel">
  <div class="user-info">

    <div class="user-name">{{ user?.email }}</div>
  </div>
  <dx-context-menu
    *ngIf="menuMode === 'context'"
    [items]="menuItems"
    [position]="{
      my: 'top',
      at: 'bottom'
    }"
    cssClass="user-menu"
    showEvent="dxclick"
    target=".user-button"
    width="210px">
  </dx-context-menu>
  <dx-list
    *ngIf="menuMode === 'list'"
    [items]="menuItems"
    class="dx-toolbar-menu-action">
  </dx-list>
</div>
