import {Component, EventEmitter, Input, NgModule, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserPanelModule} from '../user-panel/user-panel.component';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxToolbarModule} from 'devextreme-angular/ui/toolbar';
import {DxSelectBoxModule} from "devextreme-angular";

import {Router} from '@angular/router';
import {ClinicDto, ClinicService, UserDto} from "../../services/swagger";
import {AuthService} from "../../services";
import {TokenService} from "../../services/token.service";
import {SettingsService} from "../../services/AppSettings/settings.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ThemeService} from "../../services/theme.service";
import {ThemeSelectorModule} from "../../../components/theme-selector/theme-selector.component";
import {LangPickerModule} from "../../../components/lang-picker/lang-picker.component";
import {ClinicSelector} from "../../../components/clinic-selector/clinic-selector.component";

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  userMenuItems = [{
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.authService.logOut();
    }
  }];

  langs = [
    {key: 'en-US', value: 'English'},
    {key: 'ro-RO', value: 'Română'},
  ];

  selectedValue: string = "";
  isSuperAdmin: boolean = false;
  allClinics: ClinicDto[] = [];
  user: UserDto = {} as UserDto;
  clinic: ClinicDto = {} as ClinicDto;

  currentTheme: string;
  themeSource: any[];

  constructor(
    private authService: AuthService,
    private router: Router,
    private tokenService: TokenService,
    private clinicService: ClinicService,
    private translate: TranslateService,
    private settings: SettingsService,
    private themeService: ThemeService
  ) {
    this.currentTheme = this.themeService.getTheme();
    this.themeSource = this.themeService.getThemeData();
  }

  navigateHome() {
    this.router.navigate(["/home"])
  }

  ngOnInit() {
    this.authService.getUser().subscribe({
      next: (result) => {
        if (result.isOk) {
          this.user = result.data!;
          this.isSuperAdmin = this.tokenService.isSuperAdmin();
          this.getClinic();
          if (this.isSuperAdmin) {
            this.getAllClinics();
          }
        } else {
          console.log('No user data available');
        }
      },
      error: (error) => {
        console.error('Error fetching user data:', error);
      }
    });
    const lang = this.settings.getLanguage();
    this.selectedValue = this.langs.find((x) => x.key === lang)!.key;
  }

  getAllClinics() {
    this.clinicService.getAllClinics().subscribe({
      next: (clinics) => {
        this.allClinics = clinics.data || [];
      },
      error: (error) => {
        console.error('Error fetching all clinics:', error);
      }
    });
  }

  getClinic() {
    const currentClinicId = this.tokenService.getClinicId();
    if (currentClinicId) {
      this.clinicService.getClinicById(currentClinicId).subscribe({
        next: (clinic) => {
          this.clinic = clinic;
        },
        error: (error) => {
          console.error('Error fetching clinic data:', error);
        }
      });
    }
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }

  langSelectionChanged(e: any) {
    this.translate.use(e.selectedItem.key);
    this.settings.setLanguage(e.selectedItem.key);
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxSelectBoxModule,
    TranslateModule,
    ThemeSelectorModule,
    LangPickerModule,
    ClinicSelector
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {
}
