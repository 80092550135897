import {Component} from '@angular/core';
import {ClinicDto, ClinicService, CreateClinicRequest, GroupingInfo, SortingInfo, SummaryInfo, UpdateClinicRequest} from "../../shared/services/swagger";
import {NotificationService} from "../../shared/services/notification.service";
import {TokenService} from "../../shared/services/token.service";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data";
import {lastValueFrom} from "rxjs";
import {Role} from "../../shared/services/role.enum";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-clinic-list',
  templateUrl: './clinic-list.component.html',
  styleUrls: ['./clinic-list.component.scss']
})
export class ClinicListComponent {
  dataSource: DataSource = {} as DataSource;

  selectedRowKeys: any[] = [];
  selectedRow: ClinicDto | null = null;

  constructor(
    private clinicService: ClinicService,
    protected tokenService: TokenService,
    private notificationService: NotificationService,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.initializeDataSource();
  }

  private initializeDataSource(): void {
    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: LoadOptions) => this.loadData(loadOptions),
      }),
    });
  }

  private async loadData(loadOptions: LoadOptions): Promise<any> {
    try {
      let response = await lastValueFrom(
        this.clinicService.getClinicByIdLoadOptions(
          this.tokenService.getClinicId(),
          loadOptions.requireTotalCount,
          loadOptions.requireGroupCount,
          false, // isCountQuery
          false, // isSummaryQuery
          loadOptions.skip,
          loadOptions.take,
          loadOptions.sort as SortingInfo[],
          loadOptions.group as GroupingInfo[],
          loadOptions.filter,
          loadOptions.totalSummary as SummaryInfo[],
          loadOptions.groupSummary as SummaryInfo[],
          loadOptions.select as string[],
          undefined, // preSelect
          undefined, // remoteSelect
          undefined, // remoteGrouping
          undefined, // expandLinqSumType
          undefined, // primaryKey
          undefined, // defaultSort
          undefined, // stringToLower
          undefined, // paginateViaPrimaryKey
          undefined, // sortByPrimaryKey
          undefined  // allowAsyncOverSync
        )
      );
      return {
        data: response.data,
        totalCount: response.totalCount,
        summary: response.summary,
        groupCount: response.groupCount,
      };
    } catch (e) {
      throw 'Data loading error';
    }
  }


  onSaveClinic(clinicData: CreateClinicRequest | UpdateClinicRequest) {
    if (this.isEditing) {
      this.updateClinic(clinicData as UpdateClinicRequest);
    } else {
      this.createClinic(clinicData as CreateClinicRequest);
    }
  }

  createClinic(createClinicRequest: CreateClinicRequest) {
    this.clinicService.createClinic(
      createClinicRequest.Name,
      createClinicRequest.Location,
      createClinicRequest.SendgridApiKey,
      createClinicRequest.Email,
      createClinicRequest.ThemeName,
      createClinicRequest.ContactNumber,
      createClinicRequest.logo
    ).subscribe({
      next: () => {
        this.popupVisible = false;
        this.dataSource.reload();
        this.notificationService.success(this.translate.instant("clinicList.created"));
      },
      error: (error) => {
        this.notificationService.error(this.translate.instant("clinicList.errorCreating"));
        console.log(error);
      }
    });
  }

  updateClinic(updateClinicRequest: UpdateClinicRequest) {
    this.clinicService.updateClinic(
      updateClinicRequest.Name,
      updateClinicRequest.Location,
      updateClinicRequest.SendgridApiKey,
      updateClinicRequest.Email,
      updateClinicRequest.ThemeName,
      updateClinicRequest.ContactNumber,
      this.selectedRow?.id!,
      updateClinicRequest.logo
    ).subscribe({
      next: () => {
        this.popupVisible = false;
        this.notificationService.success(this.translate.instant("clinicList.updated"));
        this.dataSource.reload();
      },
      error: (error) => {
        this.notificationService.error(this.translate.instant("clinicList.errorUpdating"));
        console.log(error);
      }
    });
  }

  onSelectionChanged(e: any) {
    this.selectedRow = e.selectedRowsData[0];
  }

  openCreatePopup() {
    this.isEditing = false;
    this.popupVisible = true;
  }

  openUpdatePopup() {
    if (this.selectedRow) {
      this.isEditing = true;
      this.popupVisible = true;
    }
  }


  navigateToManageEmailTemplates() {
    this.router.navigate(["pages/manage-email-templates", this.tokenService.getClinicId()])
  }

  navigateToManageSmsTemplates() {
    this.router.navigate(["pages/manage-sms-templates", this.tokenService.getClinicId()])
  }

  protected readonly Role = Role;
  popupVisible: boolean = false;
  isEditing: boolean = false;

}
