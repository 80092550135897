import {Component, EventEmitter, NgModule, Output} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DxSelectBoxModule} from 'devextreme-angular/ui/select-box';
import {DxTextBoxModule} from 'devextreme-angular/ui/text-box';
import {ThemeService} from "../../shared/services/theme.service";

@Component({
  selector: 'theme-selector',
  templateUrl: 'theme-selector.component.html',
  styleUrls: ['./theme-selector.component.scss']
})

export class ThemeSelectorComponent {
  @Output() themeChanged = new EventEmitter<string>();

  currentTheme
  themeSource

  constructor(private theme: ThemeService) {
    this.currentTheme = this.theme.getTheme()
    this.themeSource = this.theme.getThemeData()
    this.themeChanged.emit(this.currentTheme);
  }

  onThemeChanged(e: any) {
    this.currentTheme = e.value;
    this.themeChanged.emit(e.value);
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxSelectBoxModule,
    DxTextBoxModule
  ],
  declarations: [ThemeSelectorComponent],
  exports: [ThemeSelectorComponent]
})
export class ThemeSelectorModule {
}
