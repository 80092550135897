<dx-popup
  [dragEnabled]="false"
  [showCloseButton]="true"
  [showTitle]="true"
  [hideOnOutsideClick]="true"
  [title]="'registerPatient.title' | translate"
  [height]="'auto'"
  [width]="500"
  [maxHeight]="600"
  (onHiding)="onHiding()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'">
    <form (submit)="onSubmit($event)">
      <dx-form #patientForm
               [formData]="formData"
               labelLocation="left"
               [showColonAfterLabel]="true"
               [alignItemLabels]="true">
        <dxi-item [editorOptions]="{ stylingMode: 'filled', mode: 'email' }" dataField="email" editorType="dxTextBox">
          <dxi-validation-rule [message]="'registerPatient.emailRequired' | translate" type="required"></dxi-validation-rule>
          <dxi-validation-rule [message]="'registerPatient.emailInvalid' | translate" type="email"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'registerPatient.email' | translate"></dxo-label>
        </dxi-item>

        <dxi-item [editorOptions]="{ stylingMode: 'filled'  }" dataField="firstName" editorType="dxTextBox">
          <dxi-validation-rule [message]="'registerPatient.firstNameRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'registerPatient.firstName' | translate"></dxo-label>
        </dxi-item>

        <dxi-item [editorOptions]="{ stylingMode: 'filled' }" dataField="familyName" editorType="dxTextBox">
          <dxi-validation-rule [message]="'registerPatient.familyNameRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'registerPatient.familyName' | translate"></dxo-label>
        </dxi-item>

        <dxi-item
          [editorOptions]="{ stylingMode: 'filled'}"
          dataField="phoneNumber"
          editorType="dxTextBox"
        >
          <dxi-validation-rule [message]="('validation.phoneNumberRequired') | translate"
                               type="required"></dxi-validation-rule>
          <dxi-validation-rule [message]="('validation.phoneNumberInvalid') | translate"
                               [pattern]="phonePattern"
                               type="pattern"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="('registerPatient.phoneNumber') | translate"></dxo-label>
        </dxi-item>

        <dxi-item itemType="button">
          <dxo-button-options
            [text]="'registerPatient.registerNewPatient' | translate"
            [useSubmitBehavior]="true"
            type="success"
            width="100%">
          </dxo-button-options>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>
