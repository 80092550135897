import {Component, OnInit} from '@angular/core';
import {
  AuthenticationService,
  ClinicDto,
  ClinicService,
  DoctorDto,
  DoctorService,
  GroupingInfo,
  SortingInfo,
  SummaryInfo,
  UpdateDoctorCommand,
  UserRegisterCommand,
  UserService
} from "../../shared/services/swagger";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data";
import {lastValueFrom} from "rxjs";
import {NotificationService} from "../../shared/services/notification.service";
import {TokenService} from "../../shared/services/token.service";
import {Role} from "../../shared/services/role.enum";
import {TranslateService} from "@ngx-translate/core";
import {confirm} from "devextreme/ui/dialog";


@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss']
})
export class DoctorListComponent implements OnInit {
  // @ts-ignore
  dataSource: DataSource;

  popupVisible: boolean = false;
  isEditing: boolean = false;

  selectedRowKeys: any[] = [];
  selectedRow: DoctorDto | null = null;
  isAdmin: boolean = false;

  role: Role = {} as Role;
  clinic: ClinicDto = {} as ClinicDto;

  constructor(
    private doctorService: DoctorService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private clinicService: ClinicService,
    private tokenService: TokenService,
    private userService: UserService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.isAdmin = this.tokenService.getUserRole() === Role.Admin;
    this.setUserRole();
    this.loadClinicData();
    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: LoadOptions) => this.loadAllDoctorsNotDeleted(loadOptions),
        remove: (key) => lastValueFrom(this.doctorService.deleteDoctor(key))
      }),
    });
  }

  createDoctor(userRegisterCommand: UserRegisterCommand) {
    this.authenticationService.registerDoctor(this.clinic.id, userRegisterCommand).subscribe({
      next: () => {
        this.popupVisible = false;
        this.dataSource.reload();
        this.notificationService.success(this.translate.instant("doctorList.registered"));
      }, error: (error) => {
        if (error.status === 409) {
          this.notificationService.error(this.translate.instant("errors.emailAlreadyExists"));
        } else if (error.status === 400) {
          this.notificationService.error(this.translate.instant("errors.invalidInput"));
        } else {
          this.notificationService.error(this.translate.instant("errors.generalError"));
        }
        console.error("Error registering doctor:", error);
      }
    });
  }

  updateDoctor(updateDoctorCommand: UpdateDoctorCommand) {
    this.doctorService.updateDoctor(this.selectedRow?.id!, updateDoctorCommand).subscribe({
      next: () => {
        this.popupVisible = false;
        this.dataSource.reload();
        this.notificationService.success(this.translate.instant("doctorList.updated"));
      }, error: (error) => {
        this.notificationService.error(this.translate.instant("errors.generalError"));
        console.log(error)
      }
    });
  }

  onSaveDoctor(doctorData: UserRegisterCommand | UpdateDoctorCommand) {
    if (this.isEditing) {
      this.updateDoctor(doctorData as UpdateDoctorCommand)
    } else {
      this.createDoctor(doctorData as UserRegisterCommand)
    }
  }

  openCreatePopup() {
    this.isEditing = false;
    this.popupVisible = true;
  }

  openUpdatePopup() {
    if (this.selectedRow) {
      this.isEditing = true;
      this.popupVisible = true;
    }
  }

  onSelectionChanged(e: any) {
    this.selectedRow = e.selectedRowsData[0];
  }

  private async loadAllDoctorsNotDeleted(loadOptions: LoadOptions): Promise<any> {
    try {
      let response = await lastValueFrom(
        this.doctorService.getAllDoctorsOfClinicNotSoftDeleted(
          this.tokenService.getClinicId(),
          loadOptions.requireTotalCount,
          loadOptions.requireGroupCount,
          false, // isCountQuery
          false, // isSummaryQuery
          loadOptions.skip,
          loadOptions.take,
          loadOptions.sort as SortingInfo[],
          loadOptions.group as GroupingInfo[],
          loadOptions.filter,
          loadOptions.totalSummary as SummaryInfo[],
          loadOptions.groupSummary as SummaryInfo[],
          loadOptions.select as string[],
          undefined, // preSelect
          undefined, // remoteSelect
          undefined, // remoteGrouping
          undefined, // expandLinqSumType
          undefined, // primaryKey
          undefined, // defaultSort
          undefined, // stringToLower
          undefined, // paginateViaPrimaryKey
          undefined, // sortByPrimaryKey
          undefined  // allowAsyncOverSync
        )
      );
      return {
        data: response.data,
        totalCount: response.totalCount,
        summary: response.summary,
        groupCount: response.groupCount,
      };
    } catch (e) {
      throw 'Data loading error';
    }
  }

  private setUserRole(): void {
    this.role = this.tokenService.getUserRole()!;
  }

  private loadClinicData(): void {
    const clinicId = this.tokenService.getClinicId();
    this.clinicService.getClinicById(clinicId).subscribe({
      next: (result: ClinicDto) => {
        this.clinic = result;
      },
      error: error => {
        console.log(error)
      }
    });
  }

  protected readonly Role = Role;

  deleteDoctor(): void {
    if (this.selectedRow) {
      this.confirmDoctorDeletion()
        .then((dialogResult: boolean) => {
          if (dialogResult) {
            this.checkIfDoctorInUse();
          }
        });
    }
  }

  private confirmDoctorDeletion(): Promise<boolean> {
    return confirm(
      this.translate.instant("doctorList.confirmDeletion"),
      this.translate.instant("doctorList.confirmDeletionTitle")
    );
  }

  private checkIfDoctorInUse(): void {
    this.userService.isInUse(this.selectedRow!.id).subscribe({
      next: (isInUse) => {
        if (isInUse) {
          this.confirmSoftDelete().then((dialogResult: boolean) => {
            if (dialogResult) {
              this.softDeleteDoctor();
            }
          });
        } else {
          this.deleteDoctorPermanently();
        }
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("doctorList.errorDeleting"));
      }
    });
  }

  private confirmSoftDelete(): Promise<boolean> {
    return confirm(
      this.translate.instant("doctorList.confirmSoftDeletion"),
      this.translate.instant("doctorList.confirmSoftDeletionTitle")
    );
  }

  private softDeleteDoctor(): void {
    this.userService.softDelete(this.selectedRow!.id).subscribe({
      next: () => {
        this.notificationService.success(this.translate.instant("doctorList.updated"));
        this.deselectRow();
        this.dataSource.reload();
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("doctorList.errorDeleting"));
      }
    });
  }

  private deleteDoctorPermanently(): void {
    this.dataSource.store().remove(this.selectedRow!.id)
      .then(() => {
        this.notificationService.success(this.translate.instant("doctorList.deleted"));
        this.deselectRow();
        this.dataSource.reload();
      })
      .catch((err: any) => {
        console.log(err);
        this.notificationService.error(this.translate.instant("doctorList.errorDeleting"));
      });
  }

  private deselectRow(): void {
    this.selectedRow = null;
    this.selectedRowKeys = [];
  }
}
