<div class="container">
  <div class="two-factor-methods">
    <!-- App Method -->
    <div class="method-card">
      <div class="method-icon">
        <img [src]="'assets/icons/twoFactorApp.jpg'" alt="App Authentication">
      </div>
      <div class="method-info">
        <h3 class="method-title">{{ 'selectTwoFactorMethod.twoFactorApp.title' | translate }}</h3>
        <p class="method-description">{{ 'selectTwoFactorMethod.twoFactorApp.description' | translate }}</p>
      </div>
      <div class="method-action">
        <dx-button
          stylingMode="contained"
          [text]="'selectTwoFactorMethod.setup' | translate"
          (onClick)="navigateToGoogleTwoFactor()"
          class="setup-button"
        ></dx-button>
      </div>
    </div>

    <!-- Email Method -->
    <div class="method-card">
      <div class="method-icon">
        <img [src]="'assets/icons/email.jpg'" alt="Email Authentication">
      </div>
      <div class="method-info">
        <h3 class="method-title">{{ 'selectTwoFactorMethod.email.title' | translate }}</h3>
        <p class="method-description">{{ 'selectTwoFactorMethod.email.description' | translate }}</p>
      </div>
      <div class="method-action">
        <dx-button
          stylingMode="contained"
          [text]="'selectTwoFactorMethod.setup' | translate"
          (onClick)="navigateToEmailTwoFactor()"
          class="setup-button"
        ></dx-button>
      </div>
    </div>

    <!-- SMS Method -->
    <div class="method-card">
      <div class="method-icon">
        <img [src]="'assets/icons/sms.png'" alt="SMS Authentication">
      </div>
      <div class="method-info">
        <h3 class="method-title">{{ 'selectTwoFactorMethod.sms.title' | translate }}</h3>
        <p class="method-description">{{ 'selectTwoFactorMethod.sms.description' | translate }}</p>
      </div>
      <div class="method-action">
        <dx-button
          stylingMode="contained"
          [text]="'selectTwoFactorMethod.setup' | translate"
          (onClick)="navigateToSmsTwoFactor()"
          class="setup-button"
        ></dx-button>
      </div>
    </div>
  </div>

  <div class="login-link">
    {{ 'authentication.returnTo' | translate }} <a routerLink="/login">{{ 'authentication.signIn' | translate }}</a>
  </div>
</div>
