<dx-toolbar class="theme-dependent">
  <dxi-item location="before">
    <span class="toolbar-header">{{ titleText }}</span>
  </dxi-item>

  <dxi-item *ngIf="panelItems" location="before">
    <dx-tabs
      secondary="secondary"
      keyExpr="key"
      [width]="'auto'"
      [showNavButtons]="false"
      [scrollByContent]="true"
      [selectedItemKeys]="selectedItems"
      [items]="panelItems"
      (onSelectionChanged)="selectionChange($event)"
    ></dx-tabs>
  </dxi-item>

  <!-- New Button Item to Call loadData on Click -->
  <dxi-item location="after" locateInMenu="auto">
    <dx-button
      [text]="'dashboard.refresh' | translate"
      icon="refresh"
      stylingMode="text"
      (onClick)="onLoadDataClick($event)"
    ></dx-button>
  </dxi-item>

  <dxi-item location="after" locateInMenu="auto">
    <div class="separator"></div>
  </dxi-item>
</dx-toolbar>
