import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageUtilsService {

  constructor() {
  }

  detectImageType(base64String: string): string {
    if (/^\/9j\//.test(base64String)) {
      return 'jpeg';
    } else if (/^iVBOR/.test(base64String)) {
      return 'png';
    } else if (/^R0lGO/.test(base64String)) {
      return 'gif';
    } else if (/^UklGR/.test(base64String)) {
      return 'webp';
    } else {
      return 'jpeg'; // Default to JPEG if we can't detect the type
    }
  }

  getImageSource(logo: string | null): string {
    if (!logo) {
      return ''; // or return path to a placeholder image
    }

    // Check if the logo already has the data URL prefix
    if (logo.startsWith('data:image')) {
      return logo;
    } else {
      // If not, add the prefix
      const imageType = this.detectImageType(logo);
      return `data:image/${imageType};base64,${logo}`;
    }
  }
}
