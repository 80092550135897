import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {SideNavInnerToolbarModule, SideNavOuterToolbarModule, SingleCardModule} from './layouts';
import {ChangePasswordFormModule, CreateAccountFormModule, FooterModule, LoginFormModule, ResetPasswordFormModule} from './shared/components';
import {AppInfoService, AuthService, ScreenService} from './shared/services';
import {UnauthenticatedContentModule} from './unauthenticated-content';
import {AppRoutingModule} from './app-routing.module';
import {ApiModule} from "./shared/services/swagger";
import {
  DevExtremeModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTagBoxModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxValidatorModule
} from "devextreme-angular";
import {DxiColumnModule, DxoFilterRowModule, DxoPagerModule, DxoPagingModule} from "devextreme-angular/ui/nested";
import {httpTokenInterceptor} from "./shared/services/http-token.interceptor";
import {ChangeDefaultPasswordComponent} from './shared/components/change-default-password/change-default-password.component';
import {PatientInfoComponent} from './pages/patient-info/patient-info.component';
import localeRo from '@angular/common/locales/ro';
import {registerLocaleData} from "@angular/common";
import {PatientUpdateComponent} from './components/patient-update-popup/patient-update.component';
import {FormsModule} from "@angular/forms";

registerLocaleData(localeRo, 'ro-RO');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({ declarations: [
        AppComponent,
        ChangeDefaultPasswordComponent,
        PatientInfoComponent,
        PatientUpdateComponent,
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    ResetPasswordFormModule,
    CreateAccountFormModule,
    ChangePasswordFormModule,
    LoginFormModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    ApiModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DxDataGridModule,
    DxTemplateModule,
    DxiColumnModule,
    DxoFilterRowModule,
    DxoPagerModule,
    DxoPagingModule,
    DxButtonModule,
    DxFileUploaderModule,
    DxTagBoxModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxPopupModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DevExtremeModule, FormsModule], providers: [
        AuthService,
        ScreenService,
        AppInfoService,
        provideHttpClient(withInterceptors([httpTokenInterceptor])),
        { provide: LOCALE_ID, useValue: 'ro-RO' },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
