import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ChangePasswordFormComponent, ForgotPasswordFormComponent, LoginFormComponent} from './shared/components';
import {AuthGuardService} from './shared/services';
import {HomeComponent} from './pages/home/home.component';
import {
  DevExtremeModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFileManagerModule,
  DxFileUploaderModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTagBoxModule,
  DxTextBoxModule
} from 'devextreme-angular';
import {NgClass, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {PatientListComponent} from './pages/patient-list/patient-list.component';
import {PatientTestsComponent} from "./pages/patient-tests/patient-tests.component";
import {TestTypeListComponent} from './pages/test-type-list/test-type-list.component';
import {TestResultViewComponent} from "./pages/test-result-view/test-result-view.component";
import {ClinicListComponent} from './pages/clinic-list/clinic-list.component';
import {DoctorListComponent} from './pages/doctor-list/doctor-list.component';
import {ChangeDefaultPasswordComponent} from "./shared/components/change-default-password/change-default-password.component";
import {PatientInfoComponent} from "./pages/patient-info/patient-info.component";
import {PatientRegisterPopupComponent} from "./components/patient-register-popup/patient-register-popup.component";
import {LaboratoriesDatagridComponent} from "./components/laboratories-datagrid/laboratories-datagrid.component";
import {TestRequestCreatePopupComponent} from "./components/test-request-create-popup/test-request-create-popup.component";
import {TestResultFilesListComponent} from "./pages/test-result-files-list/test-result-files-list.component";
import {TestResultFileUploadComponent} from "./components/test-result-file-upload-popup/test-result-file-upload.component";
import {TestResultUpdateComponent} from "./components/test-result-update-popup/test-result-update.component";
import {PatientInfoDisplayComponent} from "./components/patient-info-display/patient-info-display.component";
import {TestRequestInfoDisplayComponent} from "./components/test-request-info-display/test-request-info-display.component";
import {ManageEmailTemplatesComponent} from "./pages/manage-email-templates/manage-email-templates.component";
import {TranslateModule} from "@ngx-translate/core";
import {SelectTwoFactorMethodComponent} from "./shared/components/select-two-factor-method/select-two-factor-method.component";
import {ValidateEmailTwoFactorComponent} from "./shared/components/validate-email-two-factor/validate-email-two-factor.component";
import {ValidateGoogleTwoFactorComponent} from "./shared/components/validate-google-two-factor/validate-google-two-factor.component";
import {ClinicFormPopupComponent} from "./components/clinic-form/clinic-form.component";
import {ThemeSelectorModule} from "./components/theme-selector/theme-selector.component";
import {EmailTemplateFormPopupComponent} from "./components/email-template-form-popup/email-template-form-popup.component";
import {ManageSmsTemplatesComponent} from "./pages/manage-sms-templates/manage-sms-templates.component";
import {SmsTemplateFormPopupComponent} from "./components/sms-template-form-popup/sms-template-form-popup.component";
import {LaboratoryFormPopupComponent} from "./components/laboratory-form-popup/laboratory-form-popup.component";
import {SelectLoginMethodComponent} from "./shared/components/select-login-method/select-login-method.component";
import {TestTypeFormPopupComponent} from "./components/test-type-form-popup/test-type-form-popup.component";
import {ValidateSmsTwoFactorComponent} from "./shared/components/validate-sms-two-factor/validate-sms-two-factor.component";
import {GenericHelpPopupComponent} from "./components/generic-help-popup/generic-help-popup.component";
import {ToolbarAnalyticsModule} from "./components/library/toolbar/toolbar.component";
import {OpportunitiesTickerModule} from "./components/library/opportunities-ticker/opportunities-ticker.component";
import {TickerCardModule} from "./components/library/ticker-card/ticker-card.component";
import {SnapshotCard} from "./components/library/snapshot-card/snapshot-card.component";
import {CardAnalyticsModule} from "./components/library/card-analytics/card-analytics.component";
import {DoctorFormPopupComponent} from "./components/doctor-form-popup/doctor-form-popup.component";


const routes: Routes = [
  {
    path: 'pages/doctor-list',
    component: DoctorListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pages/clinic-list',
    component: ClinicListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pages/test-type-list',
    component: TestTypeListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pages/patient-list',
    component: PatientListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pages/patient-tests/:patientId',
    component: PatientTestsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pages/test-result-view/:patientId/:testResultId',
    component: TestResultViewComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'change-default-password/:clinicId/:userId',
    component: ChangeDefaultPasswordComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login-form/:clinicId',
    component: LoginFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'reset-password/:clinicId',
    component: ForgotPasswordFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'change-password/:clinicId',
    component: ChangePasswordFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'patient-info/:patientId',
    component: PatientInfoComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pages/test-result-files/:patientId/:testRequestId',
    component: TestResultFilesListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pages/manage-email-templates/:clinicId',
    component: ManageEmailTemplatesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pages/manage-sms-templates/:clinicId',
    component: ManageSmsTemplatesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'select-two-factor-method/:clinicId/:userId',
    component: SelectTwoFactorMethodComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'validate-google-two-factor/:clinicId/:userId',
    component: ValidateGoogleTwoFactorComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'validate-email-two-factor/:clinicId/:userId',
    component: ValidateEmailTwoFactorComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'validate-sms-two-factor/:clinicId/:userId',
    component: ValidateSmsTwoFactorComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'select-login-method/:clinicId/:userId',
    component: SelectLoginMethodComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    DxDataGridModule, DxFormModule,
    DxLoadIndicatorModule,
    NgIf, DxSelectBoxModule,
    DxTagBoxModule,
    DxButtonModule,
    DxPopupModule,
    DxTextBoxModule,
    DxFileManagerModule,
    NgForOf, DxFileUploaderModule, DxCheckBoxModule, DevExtremeModule, TranslateModule, ThemeSelectorModule, NgClass, NgOptimizedImage, ToolbarAnalyticsModule, OpportunitiesTickerModule, TickerCardModule, SnapshotCard, CardAnalyticsModule],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [
    HomeComponent,
    // Components created with ng generate are added automatically in app.module.ts and they cant be reached in components created with the template create view command
    // To be able to use components in generated views they need to be added here and removed from app.module.ts
    PatientRegisterPopupComponent,
    DoctorFormPopupComponent,
    TestResultFilesListComponent,
    ManageSmsTemplatesComponent,
    LaboratoriesDatagridComponent,
    ValidateSmsTwoFactorComponent,
    TestTypeFormPopupComponent,
    TestResultFileUploadComponent,
    GenericHelpPopupComponent,
    LaboratoryFormPopupComponent,
    SelectTwoFactorMethodComponent,
    ValidateEmailTwoFactorComponent,
    ValidateGoogleTwoFactorComponent,
    TestResultViewComponent,
    TestResultUpdateComponent,
    ClinicFormPopupComponent,
    SmsTemplateFormPopupComponent,
    EmailTemplateFormPopupComponent,
    TestRequestCreatePopupComponent,
    ManageEmailTemplatesComponent,
    SelectLoginMethodComponent,
    PatientTestsComponent,
    TestRequestInfoDisplayComponent,
    PatientListComponent,
    TestTypeListComponent,
    ClinicListComponent,
    PatientInfoDisplayComponent,
    DoctorListComponent
  ]
})
export class AppRoutingModule {
}
