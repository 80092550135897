import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-select-login-method',
  templateUrl: './select-login-method.component.html',
  styleUrl: './select-login-method.component.scss'
})
export class SelectLoginMethodComponent implements OnInit {

  userId: number = 0;
  clinicId: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    this.userId = Number(this.route.snapshot.paramMap.get('userId'));
  }

  navigateToChangeDefaultPassword() {
    this.router.navigate(['/change-default-password', this.clinicId, this.userId]);
  }

  navigateToGoogleRegister() {
    this.router.navigate(['/register-google', this.clinicId, this.userId])
  }

  navigateToMicrosoftLogin() {
    this.router.navigate(['/login-microsoft', this.clinicId, this.userId])
  }

}
