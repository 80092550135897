<dx-popup
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  [dragEnabled]="false"
  [title]="isReadonly ? ('smsTemplateForm.viewTitle' | translate) : (isEditing ? 'smsTemplateForm.updateTitle' : 'smsTemplateForm.createTitle') | translate"
  height="auto"
  width="60%"
  (onHiding)="onHiding()"
  (onShowing)="onShowing()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'" class="sms-template-content">
    <form (submit)="onSubmit($event)" class="sms-template-form">
      <dx-form validationGroup="smsTemplateValidation" [formData]="formData" labelLocation="top" [showColonAfterLabel]="true" [alignItemLabels]="true">

        <dxi-item>
          <dxo-label [text]="'smsTemplateForm.messageType' | translate" [visible]="true"></dxo-label>
          <dx-select-box
            [(value)]="formData.messageType"
            [items]="messageTypes"
            displayExpr="name"
            valueExpr="value"
            [placeholder]="'smsTemplateForm.messageType' | translate"
            [width]="'100%'"
            (onValueChanged)="onMessageTypeChange($event.value)"
            [disabled]="isEditing || isReadonly">

            <dx-validator validationGroup="smsTemplateValidation">
              <dxi-validation-rule
                type="required"
                [message]="'smsTemplateForm.messageTypeRequired' | translate"
              ></dxi-validation-rule>
            </dx-validator>

          </dx-select-box>
        </dxi-item>

        <dxi-item>
          <dxo-label [text]="'smsTemplateForm.htmlEditor' | translate" [visible]="true"></dxo-label>
          <dx-html-editor height="350px" [(value)]="formData.text" [readOnly]="isReadonly">
            <dxo-toolbar [multiline]="true" *ngIf="!isReadonly">
              <dxi-item name="undo"></dxi-item>
              <dxi-item name="redo"></dxi-item>
            </dxo-toolbar>
            <dxo-media-resizing [enabled]="true"></dxo-media-resizing>
            <dx-validator validationGroup="smsTemplateValidation">
              <dxi-validation-rule
                type="required"
                [message]="'smsTemplateForm.contentRequired' | translate"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-html-editor>
        </dxi-item>
      </dx-form>

      <div class="submit-button-container" *ngIf="!isReadonly">
        <dx-button
          [text]="'smsTemplateForm.save' | translate"
          type="success"
          [width]="200"
          validationGroup="smsTemplateValidation"
          [useSubmitBehavior]="true"
        ></dx-button>
      </div>

    </form>

    <div class="sms-type-parameters">
      <h3>{{ 'smsTemplateForm.availableParameters'| translate }}</h3>
      <div *ngIf="availableParameters.length > 0">
        <div *ngFor="let param of availableParameters" class="parameter-item">
          <p><strong>
            <a href="javascript:void(0)" (click)="!isReadonly && insertParameterToEditor(param)">{{ param }}</a>
          </strong></p>
          <p>{{ 'smsTemplateForm.parameterDescriptions.'  + param | translate }}</p>
        </div>
      </div>
      <p *ngIf="availableParameters.length === 0">{{ 'smsTemplateForm.noParametersAvailable'  | translate }}</p>
    </div>
  </div>
</dx-popup>
