import {Component, Input} from '@angular/core';
import {ClinicDto} from "../../shared/services/swagger";
import {NgIf} from "@angular/common";
import {ImageUtilsService} from "../../shared/services/image-utils.service";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-clinic-display',
  standalone: true,
  templateUrl: './clinic-display.component.html',
  imports: [
    NgIf,
    TranslateModule
  ],
  styleUrl: './clinic-display.component.scss'
})
export class ClinicDisplayComponent {
  @Input() clinic: ClinicDto = {} as ClinicDto;

  constructor(protected imageUtils: ImageUtilsService) {
  }
}
