import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CreateClinicRequest,
  CreateLaboratoryCommand,
  LaboratoryDto,
  LaboratoryService,
  TestTypeDto,
  TestTypeService, UpdateClinicRequest,
  UpdateLaboratoryCommand
} from "../../shared/services/swagger";
import {GlobalConfig} from "../../shared/services/global-variables";

@Component({
  selector: 'app-laboratory-form-popup',
  templateUrl: './laboratory-form-popup.component.html',
  styleUrl: './laboratory-form-popup.component.scss'
})
export class LaboratoryFormPopupComponent {
  @Input() laboratoryId: number | undefined = undefined;
  @Input() visible: boolean = false;
  @Input() isEditing: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() saveLaboratory = new EventEmitter<CreateLaboratoryCommand | UpdateLaboratoryCommand>();

  phonePattern = GlobalConfig.phoneNumberPattern;
  formData: any = {};
  selectedTestTypesIds: number[] = [];
  availableTestTypes: TestTypeDto[] = [];

  constructor(
    private laboratoryService: LaboratoryService,
    private testTypeService: TestTypeService
  ) {
  }

  onShowing() {
    this.initializeForm();
  }

  onHiding() {
    this.visibleChange.emit(false);
    this.resetForm();
  }

  initializeForm() {
    if (this.isEditing && this.laboratoryId != undefined) {
      this.laboratoryService.getLaboratoryById(this.laboratoryId).subscribe({
        next: (laboratoryDto: LaboratoryDto) => {
          this.selectedTestTypesIds = laboratoryDto.testTypes.map(t => t.id);
          this.formData = {
            location: laboratoryDto.location,
            contactNumber: laboratoryDto.contactNumber,
            isSoftDeleted: laboratoryDto.isSoftDeleted,
          };
        }
      });
    }
    this.loadAvailableTestTypes();
  }

  private loadAvailableTestTypes() {
    this.testTypeService.getAllTestTypesNotSoftDeleted().subscribe({
      next: value => {
        this.availableTestTypes = value.data!;
      }
    })
  }

  resetForm() {
    this.selectedTestTypesIds = [];
    this.availableTestTypes = [];
    this.formData = {};
  }


  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const laboratoryData: CreateLaboratoryCommand | UpdateLaboratoryCommand = {
      location: this.formData.location,
      contactNumber: this.formData.contactNumber,
      testTypesId: this.selectedTestTypesIds,
      isSoftDeleted: this.formData.isSoftDeleted
    };

    this.visible = false;
    this.saveLaboratory.emit(laboratoryData);
  }
}
