import { TokenService } from "./shared/services/token.service";
import { TranslateService } from "@ngx-translate/core";

export const getNavigation = (tokenService: TokenService, translateService: TranslateService) => {
  const userId = tokenService.isTokenValid() ? tokenService.getUserId() : null;
  const userRole = tokenService.isTokenValid() ? tokenService.getUserRole() : null;

  const doctorNavigation = [
    {
      text: translateService.instant('navigation.home'),
      path: '/home',
      icon: 'fas fa-home'
    },
    {
      text: translateService.instant('navigation.patientList'),
      path: '/pages/patient-list',
      icon: 'fas fa-users'
    },
    {
      text: translateService.instant('navigation.testTypeList'),
      path: '/pages/test-type-list',
      icon: 'fas fa-vial'
    },
    {
      text: translateService.instant('navigation.clinicList'),
      path: '/pages/clinic-list',
      icon: 'fas fa-clinic-medical'
    },
    {
      text: translateService.instant('navigation.doctorList'),
      path: '/pages/doctor-list',
      icon: 'fas fa-user-md'
    }
  ];

  const patientNavigation = [
    {
      text: translateService.instant('navigation.patientTests'),
      path: `/pages/patient-tests/${userId}`,
      icon: 'fas fa-notes-medical'
    }
  ];

  const adminNavigation = [
    {
      text: translateService.instant('navigation.home'),
      path: '/home',
      icon: 'fas fa-home'
    },
    {
      text: translateService.instant('navigation.patientList'),
      path: '/pages/patient-list',
      icon: 'fas fa-users'
    },
    {
      text: translateService.instant('navigation.testTypeList'),
      path: '/pages/test-type-list',
      icon: 'fas fa-vial'
    },
    {
      text: translateService.instant('navigation.clinicList'),
      path: '/pages/clinic-list',
      icon: 'fas fa-clinic-medical'
    },
    {
      text: translateService.instant('navigation.doctorList'),
      path: '/pages/doctor-list',
      icon: 'fas fa-user-md'
    }
  ];

  switch (userRole) {
    case "Admin":
      return adminNavigation;
    case "Doctor":
      return doctorNavigation;
    case "Patient":
      return patientNavigation;
    default:
      return [];
  }
};
