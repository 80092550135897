import {CommonModule} from '@angular/common';
import {Component, NgModule, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {DxFormModule} from 'devextreme-angular/ui/form';
import {DxLoadIndicatorModule} from 'devextreme-angular/ui/load-indicator';
import {AuthService} from '../../services';
import {ClinicDto, ClinicService, LoginRequest} from '../../services/swagger';
import {DxButtonModule, DxCheckBoxModule, DxSelectBoxModule} from "devextreme-angular";
import {ClinicDisplayComponent} from "../../../components/clinic-display/clinic-display.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ThemeService} from "../../services/theme.service";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  loading = false;
  formData: any = {
    email: '',
    password: '',
    rememberMe: true
  };

  clinics: ClinicDto[] = [];
  clinic: ClinicDto | null = null;
  selectedClinicId: number = 0;
  // user: SocialUser | null;

  constructor(
    private authService: AuthService,
    private clinicService: ClinicService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private themeService: ThemeService,
    private notificationService: NotificationService
  ) {
    // this.user = null;
    //
    // // social authentication
    // // subscribe to see when a social login is done
    // this.socialAuthService.authState.subscribe((user: SocialUser) => {
    //   this.user = user;
    //   this.authService.logInGoogle(this.selectedClinicId, this.user).subscribe({
    //     next: (result) =>{
    //       if(result.isOk){
    //         this.notificationService.success(this.translate.instant("notification.loginSuccessful"));
    //         this.loadClinicAndSetTheme(this.selectedClinicId);
    //       } else {
    //         this.notificationService.error(result.message || 'Login failed');
    //       }
    //     },
    //     complete: () => this.loading = false
    //   })
    // });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const clinicId = params.get('clinicId');
      if (clinicId) {
        this.loadClinicAndSetThemeGuid(clinicId);
      } else {
        this.loadClinics();
      }
    });
  }

  loadClinicAndSetTheme(clinicId: number) {
    this.clinicService.getClinicById(clinicId).subscribe({
      next: (result: ClinicDto) => {
        this.clinic = result;
        this.themeService.applyTheme(result.themeName)
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  loadClinicAndSetThemeGuid(clinicId: string) {
    this.clinicService.getClinicByDisplayId(clinicId).subscribe({
      next: (result: ClinicDto) => {
        this.clinic = result;
        this.selectedClinicId = result.id;
        this.themeService.applyTheme(result.themeName)
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  loadClinics() {
    this.clinicService.getAllClinics().subscribe({
      next: (loadResult) => {
        this.clinics = loadResult.data!;
      },
      error: err => {
        console.log(err);
      }
    });
  }

  onSubmit($event: any) {
    $event.preventDefault();
    this.loading = true;

    const loginRequest: LoginRequest = {
      email: this.formData.email,
      password: this.formData.password
    };

    this.authService.logIn(this.selectedClinicId, loginRequest).subscribe({
      next: (result) => {
        if (result.isOk) {
          this.notificationService.success(this.translate.instant("loginForm.loginSuccessful"));
          this.loadClinicAndSetTheme(this.selectedClinicId);
        } else {
          this.notificationService.error(result.message || 'Login failed');
        }
      },
      complete: () => this.loading = false
    });
  }


  clinicDisplayExpr = (item: ClinicDto | null): string => {
    if (!item) return '';
    return `${this.translate.instant('loginForm.name')}: ${item.name} - ${this.translate.instant('loginForm.location')}: ${item.location}`;
  }

  onClinicSelected(e: any) {
    this.selectedClinicId = e.value;
  }

  navigateToResetPassword() {
    if (this.selectedClinicId) {
      this.router.navigate(['/reset-password', this.selectedClinicId]);
    } else {
      this.notificationService.warning(this.translate.instant("loginForm.selectClinic"));
    }
  }

  getSelectedClinic(): ClinicDto | null {
    return this.clinics.find(c => c.id === this.selectedClinicId) || null;
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxCheckBoxModule,
    ClinicDisplayComponent,
    TranslateModule,
  ],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent]
})
export class LoginFormModule {
}
