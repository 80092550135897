import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "../../shared/services/notification.service";
import { formatDate } from "@angular/common";
import { Role } from "../../shared/services/role.enum";
import { PatientDto, PatientService, UpdatePatientCommand } from "../../shared/services/swagger";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss']
})
export class PatientInfoComponent implements OnInit {

  patient: PatientDto = {} as PatientDto;
  patientId: number = 0;

  updatePopupVisible: boolean = false;
  formData: any = {};

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.getPatientInfo();
  }

  showUpdatePopup() {
    this.updatePopupVisible = true;
  }

  hideUpdatePopup() {
    this.updatePopupVisible = false;
  }

  updatePatientInformation(updatePatientCommand: UpdatePatientCommand) {
    this.patientService.updatePatient(this.patientId, updatePatientCommand).subscribe({
      next: () => {
        this.hideUpdatePopup();
        this.getPatientInfo();
        this.notificationService.success(this.translate.instant("patientInfo.updated"));
      },
      error: (err) => {
        console.log(err);
        this.notificationService.error(this.translate.instant("patientInfo.errorUpdating"));
      }
    });
  }

  private getPatientInfo() {
    this.route.paramMap.subscribe(params => {
      this.patientId = Number(params.get('patientId'));

      this.patientService.getPatient(this.patientId).subscribe({
        next: result => {
          this.patient = result;
          this.formData = {}; // Reset formData
        },
        error: (err) => {
          console.log(err);
          this.notificationService.error(this.translate.instant("notification.failedToGetPatientInfo"));
        }
      });
    });
  }

  private formatDateForServer(date: Date | string | null): string | null {
    if (!date) return null;
    return formatDate(date, 'yyyy-MM-dd', 'ro-RO');
  }

  protected readonly String = String;

  goBackToPatientList() {
    this.router.navigate(["pages/patient-list"])
  }

  protected readonly Role = Role;
}
