<dx-drawer
  [(opened)]="menuOpened"
  [closeOnOutsideClick]="shaderEnabled"
  [minSize]="minMenuSize"
  [openedStateMode]="menuMode"
  [revealMode]="menuRevealMode"
  [shading]="shaderEnabled"
  class="drawer"
  position="before">

  <app-side-navigation-menu
    (openMenu)="navigationClick()"
    (selectedItemChanged)="navigationChanged($event)"
    *dxTemplate="let dataMenu of 'panel'"
    [compactMode]="!menuOpened"
    [selectedItem]="selectedRoute"
    class="dx-swatch-additional">
    <dx-toolbar id="navigation-header">
      <dxi-item
        *ngIf="minMenuSize !== 0"
        [options]="{
            icon: 'menu',
            stylingMode: 'text',
            onClick: toggleMenu
          }"
        cssClass="menu-button"
        location="before"
        widget="dxButton">
      </dxi-item>
      <dxi-item
        [text]="title"
        cssClass="header-title"
        location="before">
      </dxi-item>
    </dx-toolbar>
  </app-side-navigation-menu>

  <div class="container">
    <app-header
      (menuToggle)="menuOpened = !menuOpened;"
      [menuToggleEnabled]="minMenuSize === 0">
    </app-header>

    <dx-scroll-view class="layout-body with-footer">
      <div class="content">
        <ng-content></ng-content>
      </div>

      <div class="content-block">
        <ng-content select="app-footer"></ng-content>
      </div>
    </dx-scroll-view>
  </div>
</dx-drawer>
