import {Component, OnInit} from '@angular/core';
import {AnalyticsPanel, Dates, PanelItem} from "../../components/library/resource";
import {
  DoctorDto,
  DoctorService,
  LaboratoryService,
  PatientDto,
  PatientService,
  TestRequestDto,
  TestRequestService,
  TestResultDto,
  TestResultService,
  TestTypeDto,
  TestTypeService
} from "../../shared/services/swagger";
import {forkJoin, Observable} from "rxjs";
import {LaboratoryInfoDto} from "../../shared/services/swagger/model/laboratoryInfoDto";
import {TranslateService} from '@ngx-translate/core';
import {TestTypeInfoDto} from "../../shared/services/swagger/model/testTypeInfoDto";

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  analyticsPanelItems: Array<PanelItem>;
  isLoading: boolean = true;
  startDate: Date;
  endDate: Date;

  patients: PatientDto[] = [];
  tests: TestRequestDto[] = [];
  doctors: { name: string; value: number }[] = [];
  completedTestRequests: TestRequestDto[] = [];
  laboratoriesInfo: LaboratoryInfoDto[] = [];
  testTypeInfo: { name: string; value: number }[] = [];
  uncompletedTestRequests: TestRequestDto[] = [];

  constructor(
    private patientService: PatientService,
    private testRequestService: TestRequestService,
    private doctorService: DoctorService,
    private laboratoryService: LaboratoryService,
    private translate: TranslateService,
    private testTypeService: TestTypeService
  ) {
    const analyticsPanel = new AnalyticsPanel(this.translate);
    this.analyticsPanelItems = analyticsPanel.analyticsPanelItems;
    this.startDate = this.analyticsPanelItems[4].value.startDate;
    this.endDate = this.analyticsPanelItems[4].value.endDate;
  }

  selectionChange(dates: Dates) {
    this.startDate = dates.startDate;
    this.endDate = dates.endDate;
    this.loadData();
  }

  loadData = () => {
    this.isLoading = true;
    const startDateString = this.startDate!.toDateString();
    const endDateString = this.endDate!.toDateString();
    const tasks: Observable<any>[] = [
      this.getTestRequestsBetweenDates(startDateString, endDateString),
      this.getPatients(startDateString, endDateString),
      this.getDoctorsByTestCount(startDateString, endDateString),
      this.getCompleteTestRequests(startDateString, endDateString),
      this.getLaboratoryInfo(startDateString, endDateString),
      this.getTestTypeInfo(startDateString, endDateString),
      this.getIncompleteTestRequests(startDateString, endDateString)
    ];

    forkJoin(tasks).subscribe({
      next: ([tests, patients, doctors, completedTestRequests, laboratoriesInfo, testTypeInfo, incompleteTestRequests]) => {
        this.tests = tests;
        this.patients = patients;
        this.doctors = doctors.map((doctor: DoctorDto) => ({
          name: doctor.firstName || 'Unknown',
          value: doctor.testCount
        }));
        this.completedTestRequests = completedTestRequests;
        this.laboratoriesInfo = laboratoriesInfo;
        this.testTypeInfo = testTypeInfo.map((testTypeInfo: TestTypeInfoDto) => ({
          name: testTypeInfo.testTypeName,
          value: testTypeInfo.testCount
        }));
        this.uncompletedTestRequests = incompleteTestRequests;

        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error loading data:', error);
        this.isLoading = false;
      }
    });
  };

  getPatients = (startDate: string, endDate: string): Observable<PatientDto[]> => {
    return this.patientService.getPatientsAddedBetween(startDate, endDate);
  };

  getTestRequestsBetweenDates = (startDate: string, endDate: string): Observable<TestRequestDto[]> => {
    return this.testRequestService.getAllTestRequestsBetweenDates(startDate, endDate);
  };

  getDoctorsByTestCount = (startDate: string, endDate: string): Observable<DoctorDto[]> => {
    return this.doctorService.getDoctorsByTests(startDate, endDate);
  }

  getCompleteTestRequests = (startDate: string, endDate: string): Observable<TestRequestDto[]> => {
    return this.testRequestService.getCompleteTestRequests(startDate, endDate);
  }

  getLaboratoryInfo = (startDate: string, endDate: string): Observable<LaboratoryInfoDto[]> => {
    return this.laboratoryService.getLaboratoryInfo(startDate, endDate);
  }

  getTestTypeInfo = (startDate: string, endDate: string): Observable<TestTypeInfoDto[]> => {
    return this.testTypeService.getTestTypeInfo(startDate, endDate);
  }

  getIncompleteTestRequests = (startDate: string, endDate: string): Observable<TestRequestDto[]> => {
    return this.testRequestService.getIncompleteTestRequests(startDate, endDate);
  }

  loadDataClickHandler() {
    this.loadData();
  }

  ngOnInit(): void {
    this.loadData();
  }
}
