import {Component, Input, NgModule,} from '@angular/core';
import {DxPieChartModule} from 'devextreme-angular/ui/pie-chart';
import {CardAnalyticsModule} from "../card-analytics/card-analytics.component";
import {DoctorDto} from "../../../shared/services/swagger";

@Component({
  selector: 'snapshot-card',
  templateUrl: './snapshot-card.component.html',
})
export class SnapshotCardComponent {
  // @ts-ignore
  @Input() data: DoctorDto[];

  customizeSaleText(arg: { percentText: string }) {
    return arg.percentText;
  }
}

@NgModule({
  imports: [
    CardAnalyticsModule,
    DxPieChartModule,
  ],
  declarations: [SnapshotCardComponent],
  exports: [SnapshotCardComponent],
})
export class SnapshotCard {
}
