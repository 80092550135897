<div class="content-block">
  <div class="header-container">
    <div class="left-section">
      <dx-button
        (onClick)="goBackToPatientTests()"
        icon="arrowback"
        type="default"
        [text]="'testResultManagement.backToPatientTests' | translate"
      ></dx-button>
    </div>
    <div class="center-section">
      <h1 class="page-title">{{ 'testResultManagement.title' | translate }}</h1>
    </div>
    <div class="right-section"></div>
  </div>

  <div class="info-container" *ngIf="patient && testRequest && testRequest.doctor">
    <app-patient-info-display [patient]="patient"></app-patient-info-display>
    <app-test-request-info-display [testRequest]="testRequest"></app-test-request-info-display>
  </div>

  <div class="dx-card responsive-paddings grid-container">
    <dx-data-grid
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [showBorders]="true"
      [showRowLines]="true"
      [showColumnLines]="true"

      [dataSource]="dataSource"
      (onSelectionChanged)="onSelectionChanged($event)"
      [(selectedRowKeys)]="selectedRowKeys"
      class="grid"
    >
      <dxo-toolbar>
        <dxi-item locateInMenu="auto" location="before">
          <div *dxTemplate>
            <dx-button
              (onClick)="navigateToViewTestResult(selectedRowKeys[0])"
              [disabled]="selectedRowKeys.length !== 1"
              icon="image"
              [text]="'testResultManagement.viewPatientTest' | translate">
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="before">
          <div *dxTemplate>
            <dx-button
              (onClick)="validateSelectedTestResults()"
              [disabled]="selectedRowKeys.length === 0"
              type="success"
              icon="plus"
              [text]="'testResultManagement.validateSelectedTests' | translate">
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="before">
          <div *dxTemplate>
            <dx-button
              (onClick)="invalidateSelectedTestResults()"
              [disabled]="selectedRowKeys.length === 0"
              type="danger"
              icon="minus"
              [text]="'testResultManagement.invalidateSelectedTests' | translate">
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="after">
          <div *dxTemplate>
            <dx-button
              (onClick)="this.testResultUpdatePopupVisible=true;"
              [disabled]="!this.selectedRow || this.selectedRowKeys.length!==1"
              icon="edit"
              [text]="'testResultManagement.editTestResult' | translate">
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="after" *ngIf="this.tokenService.getUserRole()===Role.Doctor">
          <div *dxTemplate>
            <dx-button
              (onClick)="this.testResultFileUploadPopupVisible=true;"
              icon="upload"
              type="success"
              [text]="'testResultManagement.uploadNewTestResult' | translate">
            </dx-button>
          </div>
        </dxi-item>
      </dxo-toolbar>

      <dxo-selection mode="multiple"></dxo-selection>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"></dxo-pager>
      <dxo-filter-row [visible]="true">
        <dxo-operation-descriptions
          [between]="'filterRow.operationDescriptions.between' | translate"
          [contains]="'filterRow.operationDescriptions.contains' | translate"
          [endsWith]="'filterRow.operationDescriptions.endsWith' | translate"
          [equal]="'filterRow.operationDescriptions.equal' | translate"
          [greaterThan]="'filterRow.operationDescriptions.greaterThan' | translate"
          [greaterThanOrEqual]="'filterRow.operationDescriptions.greaterThanOrEqual' | translate"
          [lessThan]="'filterRow.operationDescriptions.lessThan' | translate"
          [lessThanOrEqual]="'filterRow.operationDescriptions.lessThanOrEqual' | translate"
          [notContains]="'filterRow.operationDescriptions.notContains' | translate"
          [notEqual]="'filterRow.operationDescriptions.notEqual' | translate"
          [startsWith]="'filterRow.operationDescriptions.startsWith' | translate"
        ></dxo-operation-descriptions>
      </dxo-filter-row>

      <dxi-column [calculateCellValue]="formatDateAdded" alignment="center" [caption]="'testResultManagement.dateAdded' | translate" dataField="dateAdded"></dxi-column>
      <dxi-column [calculateCellValue]="formatDateUpdated" [sortIndex]="0" [sortOrder]="'desc'" alignment="center" [caption]="'testResultManagement.dateUpdated' | translate" dataField="dateUpdated"></dxi-column>
      <dxi-column alignment="center" [caption]="'testResultManagement.testTypes' | translate" cellTemplate="testTypesTemplate"></dxi-column>
      <dxi-column alignment="center" [caption]="'testResultManagement.fileName' | translate" dataField="fileName"></dxi-column>
      <dxi-column [caption]="'testResultManagement.isValid' | translate" dataField="isValid" dataType="boolean"></dxi-column>

      <div *dxTemplate="let testResult of 'testTypesTemplate'">
        {{ getTestTypeNames(testResult.data.testTypes) }}
      </div>
    </dx-data-grid>
  </div>
</div>

<app-test-result-file-upload-popup
  (testResultCreated)="onTestResultFileCreated($event)"
  [(visible)]="testResultFileUploadPopupVisible"
  [isValid]="false"
  [patient]="patient"
  [remainingTestTypes]="this.testRequest.testTypes"
  [testRequest]="this.testRequest">
</app-test-result-file-upload-popup>

<app-test-result-update-popup
  (updateTestResult)="onTestResultUpdated($event)"
  [(visible)]="testResultUpdatePopupVisible"
  [testResultId]="this.selectedRow?.id"
  [availableTestTypes]="this.testRequest.testTypes">
</app-test-result-update-popup>
