<div class="page-container">
  <form (submit)="onSubmit($event)" class="form-container">
    <dx-form [disabled]="loading" [formData]="formData">
      <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: ('labels.email' | translate) }" dataField="email" editorType="dxTextBox">
        <dxi-validation-rule [message]="'validation.emailRequired' | translate" type="required"></dxi-validation-rule>
        <dxi-validation-rule type="email" [message]="'validation.emailInvalid' | translate"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>

      <dxi-item itemType="button">
        <dxo-button-options [elementAttr]="{ class: 'submit-button' }"
                            [template]="'resetPasswordTemplate'"
                            [useSubmitBehavior]="true" type="default"
                            width="100%">
        </dxo-button-options>
      </dxi-item>
      <dxi-item>
        <div class="login-link">
          {{ 'authentication.returnTo' | translate }} <a routerLink="/login">{{ 'authentication.signIn' | translate }}</a>
        </div>
      </dxi-item>

      <ng-container *dxTemplate="let item of 'resetPasswordTemplate'">
        <div>
            <span class="dx-button-text">
              <ng-container *ngIf="loading; else notLoading">
                <dx-load-indicator [visible]="true" height="24px" width="24px"></dx-load-indicator>
              </ng-container>
              <ng-template #notLoading>{{ 'forgotPassword.resetPassword' | translate }}</ng-template>
            </span>
        </div>
      </ng-container>
    </dx-form>
  </form>
</div>
