import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TestResultDto, TestResultService, TestTypeDto, UpdateTestResultCommand} from "../../shared/services/swagger";

@Component({
  selector: 'app-test-result-update-popup',
  templateUrl: './test-result-update.component.html',
  styleUrls: ['./test-result-update.component.scss']
})
export class TestResultUpdateComponent {
  @Input() visible: boolean = false;
  @Input() testResultId: number | undefined = undefined;
  @Input() availableTestTypes: TestTypeDto[] = [];

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() updateTestResult = new EventEmitter<UpdateTestResultCommand>();

  formData: any = {};
  selectedTestTypesIds: number[] = [];
  testResult: TestResultDto = {} as TestResultDto;

  constructor(
    private testResultService: TestResultService,
  ) {
  }

  private resetState() {
    this.selectedTestTypesIds = [];
  }

  closePopup() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.resetState();
  }

  onShowing() {
    this.initializeData();
  }

  initializeData() {
    if(this.testResultId !== undefined) {
      this.testResultService.getTestResult(this.testResultId).subscribe({
        next: (testResultDto: TestResultDto) => {
          this.testResult = testResultDto;
          this.selectedTestTypesIds = this.testResult.testTypes?.map(t => t.id) || [];
        }
      });
    }
  }

  onHiding() {
    this.visibleChange.emit(false);
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const updateTestResultRequest: UpdateTestResultCommand = {
      isValid: this.formData.isValid,
      testTypesIds: this.selectedTestTypesIds
    };

    this.updateTestResult.emit(updateTestResultRequest);
    this.closePopup();
  }
}
