<div class="content-block">
  <div class="header-container">
    <div class="left-section">
      <dx-button
        (onClick)="goBackToPatientList()"
        icon="arrowback"
        type="default"
        [text]="'patientInfo.backToPatientList' | translate"
        class="back-button"
      ></dx-button>
    </div>
    <div class="center-section">
      <h1 class="page-title">{{ 'patientInfo.title' | translate }}</h1>
    </div>
    <div class="right-section"></div>
  </div>

  <div class="center-container">
    <div class="dx-card responsive-paddings grid-container">
      <dx-form id="form" labelLocation="left" [showColonAfterLabel]="true" [alignItemLabels]="true">
        <dxi-item editorType="dxTextBox">
          <dx-text-box
            [readOnly]="true"
            [value]="patient.email || ''">
          </dx-text-box>
          <dxo-label [visible]="true" [text]="'patientInfo.email' | translate"></dxo-label>
        </dxi-item>

        <dxi-item editorType="dxTextBox">
          <dx-text-box
            [readOnly]="true"
            [value]="patient.phoneNumber">
          </dx-text-box>
          <dxo-label [visible]="true" [text]="'patientInfo.phoneNumber' | translate"></dxo-label>
        </dxi-item>

        <dxi-item editorType="dxTextBox">
          <dx-text-box
            [readOnly]="true"
            [value]="patient.firstName || ''">
          </dx-text-box>
          <dxo-label [visible]="true" [text]="'patientInfo.firstName' | translate"></dxo-label>
        </dxi-item>

        <dxi-item editorType="dxTextBox">
          <dx-text-box
            [readOnly]="true"
            [value]="patient.familyName || ''">
          </dx-text-box>
          <dxo-label [visible]="true" [text]="'patientInfo.familyName' | translate"></dxo-label>
        </dxi-item>

        <dxi-item editorType="dxTextBox">
          <dx-text-box
            [readOnly]="true"
            [value]="patient.cnp || ''">
          </dx-text-box>
          <dxo-label [visible]="true" [text]="'patientInfo.cnp' | translate"></dxo-label>
        </dxi-item>

        <dxi-item editorType="dxDateBox">
          <dx-date-box
            [readOnly]="true"
            [value]="patient.dateOfBirth || ''"
            displayFormat="dd.MM.yyyy">
          </dx-date-box>
          <dxo-label [visible]="true" [text]="'patientInfo.dateOfBirth' | translate"></dxo-label>
        </dxi-item>

        <dxi-item editorType="dxTextBox">
          <dx-text-box
            [readOnly]="true"
            [value]="patient.weight || ''">
          </dx-text-box>
          <dxo-label [visible]="true" [text]="'patientInfo.weight' | translate"></dxo-label>
        </dxi-item>

        <dxi-item editorType="dxTextBox">
          <dx-text-box
            [readOnly]="true"
            [value]="patient.height || ''">
          </dx-text-box>
          <dxo-label [visible]="true" [text]="'patientInfo.height' | translate"></dxo-label>
        </dxi-item>

        <dxi-item editorType="dxTextBox">
          <dx-text-box
            [readOnly]="true"
            [value]="patient.gender || ''">
          </dx-text-box>
          <dxo-label [visible]="true" [text]="'patientInfo.gender' | translate"></dxo-label>
        </dxi-item>
      </dx-form>

      <div class="button-container">
        <dx-button
          (onClick)="showUpdatePopup()"
          [text]="'patientInfo.update' | translate"
          type="success"
          width="200"
        ></dx-button>
      </div>
    </div>
  </div>
</div>

<app-patient-update-popup
  [patient]="patient"
  [(visible)]="updatePopupVisible"
  (updatePatient)="updatePatientInformation($event)">
</app-patient-update-popup>
