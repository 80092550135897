import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-generic-help-popup',
  templateUrl: './generic-help-popup.component.html',
  styleUrl: './generic-help-popup.component.scss'
})
export class GenericHelpPopupComponent {
  @Input() visible: boolean = false;
  @Input() dataSource: {} = {};

  @Output() visibleChange = new EventEmitter<boolean>();

  onShowing(){
  }

  onHiding() {
    this.visibleChange.emit(false);
  }

  isZoomed: boolean = false;
  zoomedImageSrc: string = '';

  zoomImage(event: MouseEvent) {
    const img = event.target as HTMLImageElement;
    this.zoomedImageSrc = img.src;
    this.isZoomed = true;
  }

  closeZoom() {
    this.isZoomed = false;
    this.zoomedImageSrc = '';
  }

}
