<div class="content-block">
  <div class="header-container">
    <div class="left-section">
      <dx-button (onClick)="goBackToPatientTests()"
                 type="default"
                 icon="arrowback"
                 [text]="'testResultView.goBack' | translate"
      ></dx-button>
    </div>
    <div class="center-section">
      <h2 class="page-title">{{ 'testResultView.title' | translate }}</h2>
    </div>
    <div class="right-section"></div>
  </div>

  <div class="info-container">
    <app-patient-info-display
      [patient]="patient">
    </app-patient-info-display>
  </div>

  <div class="dx-card responsive-paddings">
    <iframe *ngIf="pdfUrl" [src]="pdfUrl" height="900px" width="100%"></iframe>
  </div>
</div>
