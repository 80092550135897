<dx-popup
  [dragEnabled]="false"
  [showCloseButton]="true"
  [showTitle]="true"
  [hideOnOutsideClick]="true"

  [title]="(isEditing ? 'testTypeForm.updateTitle' : 'testTypeForm.createTitle') | translate"
  [height]="'auto'"
  [width]="600"

  (onShowing)="onShowing()"
  (onHiding)="onHiding()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'" class="custom-popup-content">
    <form (submit)="onSubmit($event)">
      <dx-form [formData]="formData" [showColonAfterLabel]="true" [alignItemLabels]="true">
        <dxi-item
          dataField="name"
          editorType="dxTextBox"
          [editorOptions]="{ stylingMode: 'filled', placeholder: ('testTypeForm.name') | translate }"
        >
          <dxi-validation-rule [message]="'testTypeForm.nameRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [text]="'testTypeForm.name' | translate" [visible]="true"></dxo-label>
        </dxi-item>

        <dxi-item itemType="button">
          <dxo-button-options
            [text]="(isEditing ? 'testTypeForm.updateTestType' : 'testTypeForm.createTestType') | translate"
            [useSubmitBehavior]="true"
            type="success"
            width="100%"
          >
          </dxo-button-options>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>
