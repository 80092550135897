<div class="content-block">
  <div class="header-container">
    <div class="left-section">
      <dx-button (onClick)="navigateToClinicList()"
                 type="default"
                 icon="arrowback"
                 [text]="'manageSmsTemplates.backToClinicList' | translate"
      ></dx-button>
    </div>
    <div class="center-section">
      <h1 class="page-title">{{ 'manageSmsTemplates.title' | translate }}</h1>
    </div>
    <div class="right-section"></div>
  </div>

  <div class="dx-card responsive-paddings grid-container">
    <dx-data-grid
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [showBorders]="true"
      [showColumnLines]="true"
      [showRowLines]="true"

      [dataSource]="dataSource"
      (onSelectionChanged)="onSelectionChanged($event)"
      class="grid"
    >
      <dxo-toolbar>

        <dxi-item locateInMenu="auto" location="before">
          <div *dxTemplate>
            <dx-button
              (onClick)="openDisplayPopup()"
              [disabled]="!this.selectedRow"
              icon="eyeopen"
              [text]="'manageSmsTemplates.viewSmsTemplate' | translate"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="before">
          <div *dxTemplate>
            <dx-button
              (onClick)="openUpdatePopup()"
              [disabled]="!this.selectedRow || (!tokenService.isSuperAdmin && this.selectedRow.isDefault)"
              icon="edit"
              [text]="'manageSmsTemplates.updateSmsTemplate' | translate"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="before">
          <div *dxTemplate>
            <dx-button
              (onClick)="deleteSmsTemplate()"
              [disabled]="!this.selectedRow || this.selectedRow.isDefault"
              icon="remove"
              [text]="'manageSmsTemplates.deleteSmsTemplate' | translate"
              type="danger"
              stylingMode="contained"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="after">
          <div *dxTemplate>
            <dx-button
              (onClick)="openCreatePopup()"
              icon="plus"
              type="success"
              [disabled]="allTemplatesAreCustom"
              [text]="'manageSmsTemplates.createSmsTemplate' | translate"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="after">
          <div *dxTemplate>
            <dx-button
              (onClick)="helpPopupVisible=true"
              icon="help"
              type="normal"
              [text]="'manageEmailTemplates.help' | translate"
            >
            </dx-button>
          </div>
        </dxi-item>

      </dxo-toolbar>

      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"></dxo-pager>
      <dxo-filter-row [visible]="true">
        <dxo-operation-descriptions
          [between]="'filterRow.operationDescriptions.between' | translate"
          [contains]="'filterRow.operationDescriptions.contains' | translate"
          [endsWith]="'filterRow.operationDescriptions.endsWith' | translate"
          [equal]="'filterRow.operationDescriptions.equal' | translate"
          [greaterThan]="'filterRow.operationDescriptions.greaterThan' | translate"
          [greaterThanOrEqual]="'filterRow.operationDescriptions.greaterThanOrEqual' | translate"
          [lessThan]="'filterRow.operationDescriptions.lessThan' | translate"
          [lessThanOrEqual]="'filterRow.operationDescriptions.lessThanOrEqual' | translate"
          [notContains]="'filterRow.operationDescriptions.notContains' | translate"
          [notEqual]="'filterRow.operationDescriptions.notEqual' | translate"
          [startsWith]="'filterRow.operationDescriptions.startsWith' | translate"
        ></dxo-operation-descriptions>
      </dxo-filter-row>

      <dxi-column
        alignment="center"
        [caption]="'manageSmsTemplates.messageType' | translate"
        dataField="messageType"
        cellTemplate="messageTypeTemplate"
      ></dxi-column>

      <div *dxTemplate="let data of 'messageTypeTemplate'">
        {{ 'messageTypes.' + data.value | translate }}
      </div>

      <dxi-column
        dataField="isDefault"
        [caption]="'manageSmsTemplates.isDefault' | translate"
        alignment="center"
        [groupIndex]="0">
        <dxo-lookup [dataSource]="[{value: false, text: ('manageSmsTemplates.no' | translate)}, {value: true, text: ('manageSmsTemplates.yes' | translate)}]"
                    displayExpr="text"
                    valueExpr="value">
        </dxo-lookup>
        <div *dxTemplate="let data of 'cellTemplate'">
          <dx-check-box
            [readOnly]="true"
            [value]="data.value"
          ></dx-check-box>
        </div>
      </dxi-column>

    </dx-data-grid>
  </div>
</div>

<app-sms-template-form-popup
  [(visible)]="formPopupVisible"
  [isEditing]="isEditing"
  [templateId]="selectedRow?.id"
  [isReadonly]="this.isReadonly"
  [clinicId]="this.clinicId"
  (saveTemplate)="onSaveSmsTemplate($event)"
></app-sms-template-form-popup>

<app-generic-help-popup
  [(visible)]="helpPopupVisible"
  [dataSource]="helpPopupData"
></app-generic-help-popup>
