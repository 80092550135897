import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TestRequestDto, TestResultDto, TestResultService, TestTypeDto, UserDto} from "../../shared/services/swagger";
import {NotificationService} from "../../shared/services/notification.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-test-result-file-upload-popup',
  templateUrl: './test-result-file-upload.component.html',
  styleUrl: './test-result-file-upload.component.scss'
})
export class TestResultFileUploadComponent {
  @Input() visible: boolean = false;
  @Input() isValid: boolean = false;
  @Input() testRequest: TestRequestDto = {} as TestRequestDto;
  @Input() patient: UserDto = {} as UserDto;
  @Input() remainingTestTypes: TestTypeDto[] = [];

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() testResultCreated = new EventEmitter<TestResultDto>();

  selectedFiles: File[] = [];
  selectedTestTypesIds: number[] = [];
  isValidFile: boolean = false;

  constructor(
    private testResultService: TestResultService,
    private notificationService: NotificationService,
    private translate: TranslateService,
  ) {
  }

  onFileUploaderValueChanged(e: any) {
    if (e.value && e.value.length > 0) {
      const file = e.value[0];
      const allowedExtensions = ['.pdf'];
      const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
      this.isValidFile = allowedExtensions.includes(fileExtension);
    } else {
      this.isValidFile = false;
    }
  }

  closePopup() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.resetState();
  }

  private resetState() {
    this.selectedFiles = [];
    this.selectedTestTypesIds = [];
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    if (!this.selectedFiles[0]) {
      this.notificationService.warning(this.translate.instant("testResultUpload.fileRequired"));
      return;
    }

    if (this.selectedTestTypesIds.length === 0) {
      this.notificationService.warning(this.translate.instant("testResultUpload.testTypeRequired"));
      return;
    }

    this.testResultService.createTestResult(this.testRequest.id, this.selectedTestTypesIds, this.isValid, this.selectedFiles[0]).subscribe({
      next: (result: TestResultDto) => {
        this.closePopup();
        this.notificationService.success(this.translate.instant("testResultUpload.created"))
        this.testResultCreated.emit(result,);
      },
      error: (error) => {
        this.notificationService.error(this.translate.instant("testResultUpload.errorCreating"));
        console.log(error);
      }
    });
  }
}
