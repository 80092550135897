import {Component, OnInit} from '@angular/core';
import {
  AuthenticationService,
  GroupingInfo,
  PatientService,
  SortingInfo,
  SummaryInfo,
  UserDto,
  UserRegisterCommand,
  UserService
} from "../../shared/services/swagger";
import {Router} from "@angular/router";
import {lastValueFrom} from "rxjs";
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {LoadOptions} from 'devextreme/data';
import {TokenService} from "../../shared/services/token.service";
import {NotificationService} from "../../shared/services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {Role} from "../../shared/services/role.enum";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit {
  dataSource: DataSource;

  selectedRowKeys: any[] = [];
  selectedRow: UserDto | null = null;

  createPatientPopupVisible: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private patientService: PatientService,
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router
  ) {
    this.isAdmin = this.tokenService.getUserRole() === Role.Admin;
    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: LoadOptions) => this.loadAllPatientsNotDeleted(loadOptions),
        remove: (key) => lastValueFrom(this.patientService.deletePatient(key))
      }),
    });
  }

  private async loadAllPatientsNotDeleted(loadOptions: LoadOptions): Promise<any> {
    try {
      let response = await lastValueFrom(
        this.patientService.getAllPatientsOfClinicNotDeleted(
          this.tokenService.getClinicId(),
          loadOptions.requireTotalCount,
          loadOptions.requireGroupCount,
          false, // isCountQuery
          false, // isSummaryQuery
          loadOptions.skip,
          loadOptions.take,
          loadOptions.sort as SortingInfo[],
          loadOptions.group as GroupingInfo[],
          undefined, // filter
          loadOptions.totalSummary as SummaryInfo[],
          loadOptions.groupSummary as SummaryInfo[],
          loadOptions.select as string[],
          undefined, // preSelect
          undefined, // remoteSelect
          undefined, // remoteGrouping
          undefined, // expandLinqSumType
          undefined, // primaryKey
          undefined, // defaultSort
          undefined, // stringToLower
          undefined, // paginateViaPrimaryKey
          undefined, // sortByPrimaryKey
          undefined  // allowAsyncOverSync
        )
      );
      return {
        data: response.data,
        totalCount: response.totalCount,
        summary: response.summary,
        groupCount: response.groupCount,
      };
    } catch (e) {
      throw 'Data loading error';
    }
  }

  ngOnInit(): void {
  }

  navigateToPatientTests(patientId: number): void {
    this.router.navigate(['pages/patient-tests', patientId]);
  }

  navigateToPatientInformation(userId: number) {
    this.router.navigate(["/patient-info", userId]);
  }

  onSelectionChanged(e: any) {
    this.selectedRow = e.selectedRowsData[0];
  }

  createPatient(patientRegisterCommand: UserRegisterCommand) {
    const clinicId = this.tokenService.getClinicId();

    this.authenticationService.registerPatient(clinicId, patientRegisterCommand).subscribe({
      next: () => {
        this.createPatientPopupVisible = false;
        this.dataSource.reload();
        this.notificationService.success(this.translate.instant("patientList.registered"));
      },
      error: (error) => {
        if (error.status === 409) {
          this.notificationService.error(this.translate.instant("errors.emailAlreadyExists"));
        } else if (error.status === 400) {
          this.notificationService.error(this.translate.instant("errors.invalidInput"));
        } else {
          this.notificationService.error(this.translate.instant("errors.generalError"));
        }
        console.error("Error registering patient:", error);
      }
    });
  }

  deletePatient(): void {
    if (this.selectedRow) {
      this.confirmPatientDeletion()
        .then((dialogResult: boolean) => {
          if (dialogResult) {
            this.checkIfPatientInUse();
          }
        });
    }
  }

  private confirmPatientDeletion(): Promise<boolean> {
    return confirm(
      this.translate.instant("patientList.confirmDeletion"),
      this.translate.instant("patientList.confirmDeletionTitle")
    );
  }

  private checkIfPatientInUse(): void {
    this.userService.isInUse(this.selectedRow!.id).subscribe({
      next: (isInUse) => {
        if (isInUse) {
          this.confirmSoftDelete().then((dialogResult: boolean) => {
            if (dialogResult) {
              this.softDeletePatient();
            }
          });
        } else {
          this.deletePatientPermanently();
        }
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("patientList.errorDeleting"));
      }
    });
  }

  private confirmSoftDelete(): Promise<boolean> {
    return confirm(
      this.translate.instant("patientList.confirmSoftDeletion"),
      this.translate.instant("patientList.confirmSoftDeletionTitle")
    );
  }

  private softDeletePatient(): void {
    this.patientService.softDelete(this.selectedRow!.id).subscribe({
      next: () => {
        this.notificationService.success(this.translate.instant("patientList.softDeleted"));
        this.deselectRow();
        this.dataSource.reload();
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("patientList.errorDeleting"));
      }
    });
  }

  private deletePatientPermanently(): void {
    this.patientService.deletePatient(this.selectedRow!.id).subscribe({
      next: () => {
        this.notificationService.success(this.translate.instant("patientList.deleted"));
        this.deselectRow();
        this.dataSource.reload();
      },
      error: (error) => {
        console.log(error);
        this.notificationService.error(this.translate.instant("patientList.errorDeleting"));
      }
    });
  }

  private deselectRow(): void {
    this.selectedRow = null;
    this.selectedRowKeys = [];
  }
}
