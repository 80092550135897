<dx-popup
  [dragEnabled]="false"
  [showCloseButton]="true"
  [showTitle]="true"
  [hideOnOutsideClick]="false"
  title="Multi-Slide Popup"
  width="75%"
  height="90%"
  (onHiding)="onHiding()"
  (onShowing)="onShowing()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'">
    <dx-gallery
      [showNavButtons]="true"
      [showIndicator]="true"
      [dataSource]="dataSource"
      height="100%"
      width="100%"
      [loop]="false"
    >
      <div *dxTemplate="let galleryItem of 'item'; let i = index">
        <div class="slide-content" [ngClass]="galleryItem.layout">
          <div class="item-title">{{ i + 1 }}. {{ galleryItem.title }}</div>
          <div class="content-wrapper">
            <div class="image-container">
              <img [src]="galleryItem.image" [alt]="galleryItem.title" (click)="zoomImage($event)"/>
            </div>
            <div class="item-text" [innerHTML]="galleryItem.text"></div>
          </div>
        </div>
      </div>
    </dx-gallery>
  </div>
</dx-popup>

<div class="zoom-overlay" [class.active]="isZoomed" (click)="closeZoom()">
  <img [src]="zoomedImageSrc" alt="Zoomed image" *ngIf="isZoomed"/>
</div>
