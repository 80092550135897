<div class="container">
  <div class="button-container">
    <dx-button
      type="default"
      [text]="'selectLoginMethod.changeDefaultPassword' | translate"
      (onClick)="navigateToChangeDefaultPassword()"
    ></dx-button>
    <dx-button
      type="default"
      [text]="'selectLoginMethod.googleLogin' | translate"
      (onClick)="navigateToGoogleRegister()"
    ></dx-button>
    <dx-button
      type="default"
      [text]="'selectLoginMethod.microsoftLogin' | translate"
      (onClick)="navigateToMicrosoftLogin()"
    ></dx-button>
  </div>
  <div class="login-link">
    {{ 'authentication.returnTo' | translate }} <a routerLink="/login">{{ 'authentication.signIn' | translate }}</a>
  </div>
</div>

