<div class="page-container">
  <div class="form-container">
    <form (submit)="onSubmit($event)">
      <dx-form [disabled]="loading" [formData]="formData">
        <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: ('labels.newPassword' | translate), mode: 'password' }" dataField="password"
                  editorType="dxTextBox">
          <dxi-validation-rule [message]="'validation.passwordRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>

        <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: ('labels.confirmNewPassword' | translate), mode: 'password' }"
                  dataField="confirmPassword"
                  editorType="dxTextBox">
          <dxi-validation-rule [message]="'validation.confirmPasswordRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>

        <dxi-item itemType="button">
          <dxo-button-options [template]="'signInTemplate'" [useSubmitBehavior]="true" type="default" width="100%">
          </dxo-button-options>
        </dxi-item>

        <ng-container *dxTemplate="let item of 'signInTemplate'">
          <div>
            <span class="dx-button-text">
              <ng-container *ngIf="loading; else notLoading">
                <dx-load-indicator [visible]="true" height="24px" width="24px"></dx-load-indicator>
              </ng-container>
              <ng-template #notLoading>{{ 'changeDefaultPassword.title'|translate }}</ng-template>
            </span>
          </div>
        </ng-container>
      </dx-form>
    </form>
  </div>
</div>
