import {Component, Input} from '@angular/core';
import {
  ClinicDto,
  CreateLaboratoryCommand,
  GroupingInfo,
  LaboratoryDto,
  LaboratoryService,
  SortingInfo,
  SummaryInfo,
  UpdateLaboratoryCommand
} from "../../shared/services/swagger";
import {NotificationService} from "../../shared/services/notification.service";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data";
import {lastValueFrom} from "rxjs";
import {TokenService} from "../../shared/services/token.service";
import {Role} from "../../shared/services/role.enum";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-laboratories-datagrid',
  templateUrl: './laboratories-datagrid.component.html',
  styleUrl: './laboratories-datagrid.component.scss'
})
export class LaboratoriesDatagridComponent {
  @Input() clinic: ClinicDto = {} as ClinicDto;

  dataSource: DataSource = {} as DataSource;
  selectedRowKeys = [];
  selectedRow: LaboratoryDto | null = null;
  role: Role | null;

  popupVisible: boolean = false;
  isEditing: boolean = false;

  constructor(
    private laboratoryService: LaboratoryService,
    protected tokenService: TokenService,
    private translate: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.role = tokenService.getUserRole();
    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: LoadOptions) => this.loadData(loadOptions),
        remove: (key) => lastValueFrom(this.laboratoryService.deleteLaboratory(key))
      }),
    });
  }

  async loadData(loadOptions: LoadOptions) {
    try {
      let response;
      if (this.role == Role.Admin) {
        response = await lastValueFrom(
          this.laboratoryService.getAllLaboratoriesOfClinic(
            loadOptions.requireTotalCount,
            loadOptions.requireGroupCount,
            false,
            false,
            loadOptions.skip,
            loadOptions.take,
            loadOptions.sort as SortingInfo[],
            loadOptions.group as GroupingInfo[],
            loadOptions.filter,
            loadOptions.totalSummary as SummaryInfo[],
            loadOptions.groupSummary as SummaryInfo[],
            loadOptions.select as string[]
          )
        );
      } else {
        response = await lastValueFrom(
          this.laboratoryService.getAllLaboratoriesOfClinicNotDeleted(
            loadOptions.requireTotalCount,
            loadOptions.requireGroupCount,
            false,
            false,
            loadOptions.skip,
            loadOptions.take,
            loadOptions.sort as SortingInfo[],
            loadOptions.group as GroupingInfo[],
            loadOptions.filter,
            loadOptions.totalSummary as SummaryInfo[],
            loadOptions.groupSummary as SummaryInfo[],
            loadOptions.select as string[]
          )
        );
      }

      return {
        data: response.data,
        totalCount: response.totalCount,
        summary: response.summary,
        groupCount: response.groupCount,
      };
    } catch (e) {
      throw 'Data loading error';
    }
  }

  createLaboratory(createLaboratoryCommand: CreateLaboratoryCommand) {
    this.laboratoryService.createLaboratory(this.clinic.id, createLaboratoryCommand).subscribe({
      next: () => {
        this.popupVisible = false;
        this.notificationService.success(this.translate.instant("laboratoryList.created"));
        this.dataSource.reload();
      },
      error: (error) => {
        this.notificationService.error(this.translate.instant("laboratoryList.errorCreating"));
        console.log(error)
      }
    });
  }

  updateLaboratory(updateLaboratoryCommand: UpdateLaboratoryCommand) {
    this.laboratoryService.updateLaboratory(this.selectedRow?.id!, updateLaboratoryCommand).subscribe({
      next: () => {
        this.popupVisible = false;
        this.notificationService.success(this.translate.instant("laboratoryList.updated"));
        this.dataSource.reload();
      },
      error: (error) => {
        this.notificationService.error(this.translate.instant("laboratoryList.errorUpdating"));
        console.log(error)
      }
    });
  }

  onSelectionChanged(e: any) {
    this.selectedRow = e.selectedRowsData[0];
  }

  protected readonly Role = Role;
  editPopupVisible: boolean = false;
  createPopupVisible: boolean = false;

  onSaveLaboratory(laboratoryData: CreateLaboratoryCommand | UpdateLaboratoryCommand) {
    if (this.isEditing) {
      this.updateLaboratory(laboratoryData as UpdateLaboratoryCommand);
    } else {
      this.createLaboratory(laboratoryData as CreateLaboratoryCommand);
    }
  }

  openCreatePopup() {
    this.createPopupVisible = true;
    this.editPopupVisible = false;
    this.isEditing = false;
  }

  openUpdatePopup() {
    this.editPopupVisible = true;
    this.createPopupVisible = false;
    this.isEditing = true;
  }

}
