import {Component, HostBinding, OnInit} from '@angular/core';
import {AppInfoService, AuthService, ScreenService} from './shared/services';
import {SettingsService} from "./shared/services/AppSettings/settings.service";
import {TranslateService} from "@ngx-translate/core";
import {ThemeService} from "./shared/services/theme.service";
import Popup from "devextreme/ui/popup";
import Form from "devextreme/ui/form"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    private settings: SettingsService,
    private themeService: ThemeService
  ) {
    Popup.defaultOptions({
      device: {deviceType: "phone"},
      options: {
        fullScreen: true
      }
    });

    Popup.defaultOptions({
      device: [{deviceType: "desktop"}, {deviceType: "tablet"}, {deviceType: "phone"}],
      options: {
        showCloseButton: true
      }
    });

    Form.defaultOptions({
      device: [{deviceType: "phone"}],
      options: {
        labelLocation: 'top'
      }
    });

    Form.defaultOptions({
      device: [{deviceType: "desktop"}],
      options: {
        labelLocation: 'left'
      }
    })
  }

  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  ngOnInit(): void {
    if (!this.authService.loggedIn) {
      this.authService.updateUserFromToken();
    }
    this.translate.use(this.settings.getLanguage() || 'en-US');
    this.themeService.applyTheme();
  }

  isAuthenticated() {
    return this.authService.loggedIn;
  }
}
