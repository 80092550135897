<form (submit)="onSubmit($event)">
  <dx-form [disabled]="loading" [formData]="formData">

    <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }" dataField="password"
              editorType="dxTextBox">
      <dxi-validation-rule message="Password is required" type="required"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' }" dataField="confirmedPassword"
              editorType="dxTextBox">
      <dxi-validation-rule message="Password is required" type="required"></dxi-validation-rule>
      <dxi-validation-rule [validationCallback]="confirmPassword" message="Passwords do not match" type="custom">
      </dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options [template]="'changePasswordTemplate'" [useSubmitBehavior]="true" type="default" width="100%">
      </dxo-button-options>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'changePasswordTemplate'">
      <div>
        <span class="dx-button-text">
          <ng-container *ngIf="loading; else notLoading">
            <dx-load-indicator [visible]="true" height="24px" width="24px"></dx-load-indicator>
          </ng-container>
          <ng-template #notLoading>Continue</ng-template>
        </span>
      </div>
    </ng-container>

  </dx-form>
</form>
