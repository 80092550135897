import {Component, OnInit} from '@angular/core';
import {AuthenticationService, ClinicDto, ClinicService, TwoFactorMethod} from "../../services/swagger";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-select-two-factor-method',
  templateUrl: './select-two-factor-method.component.html',
  styleUrl: './select-two-factor-method.component.scss'
})
export class SelectTwoFactorMethodComponent implements OnInit {
  clinicId: number = 0;
  userId: number = 0;
  clinic: ClinicDto = {} as ClinicDto;

  constructor(
    private authenticationService: AuthenticationService,
    private clinicService: ClinicService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    this.userId = Number(this.route.snapshot.paramMap.get('userId'));
    this.loadClinic(this.clinicId);
  }

  loadClinic(clinicId: number) {
    this.clinicService.getClinicById(clinicId).subscribe({
      next: result => {
        this.clinic = result;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  navigateToEmailTwoFactor() {
    this.authenticationService.updateSelectTwoFactorMethod(this.userId, TwoFactorMethod.Email).subscribe({
      next: result => {
        this.router.navigate(['/validate-email-two-factor', this.clinicId, this.userId]);
      }
    });
  }

  navigateToGoogleTwoFactor() {
    this.authenticationService.updateSelectTwoFactorMethod(this.userId, TwoFactorMethod.Google).subscribe({
      next: result => {
        this.router.navigate(['/validate-google-two-factor', this.clinicId, this.userId]);
      }
    });
  }

  navigateToSmsTwoFactor() {
    this.authenticationService.updateSelectTwoFactorMethod(this.userId, TwoFactorMethod.Sms).subscribe({
      next: result => {
        this.router.navigate(['/validate-sms-two-factor', this.clinicId, this.userId]);
      }
    });
  }


}
