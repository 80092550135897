<div class="container">
  <div class="form-container">
    <form (submit)="onSubmit($event)" class="custom-form">
      <dx-form [disabled]="loading" [formData]="formData">
        <dxi-item [editorOptions]="{ stylingMode: 'filled' }" dataField="smsTwoFactorCode" editorType="dxTextBox">
          <dxi-validation-rule [message]="'validation.twoFactorRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'validateSmsTwoFactor.twoFactorLabel' | translate"></dxo-label>
        </dxi-item>

        <dxi-item itemType="button">
          <dxo-button-options [elementAttr]="{ class: 'submit-button' }"
                              [template]="'verifyCodeTemplate'"
                              [useSubmitBehavior]="true" type="default"
                              width="100%">
          </dxo-button-options>
        </dxi-item>
        <dxi-item>
          <div class="login-link">
            {{ 'authentication.returnTo' | translate }} <a routerLink="/login">{{ 'authentication.signIn' | translate }}</a>
          </div>
        </dxi-item>

        <dxi-item>
          <dx-button
            icon="arrowback"
            [text]="'validateSmsTwoFactor.goBackToSelectTwoFactorMethod'|translate"
            (onClick)="navigateToSelectLoginMethod()"
          ></dx-button>
        </dxi-item>

        <ng-container *dxTemplate="let item of 'verifyCodeTemplate'">
          <div>
            <span class="dx-button-text">
              <ng-container *ngIf="loading; else notLoading">
                <dx-load-indicator [visible]="true" height="24px" width="24px"></dx-load-indicator>
              </ng-container>
              <ng-template #notLoading>{{ 'validateSmsTwoFactor.verifyCode'|translate }}</ng-template>
            </span>
          </div>
        </ng-container>
      </dx-form>
    </form>
  </div>
</div>
