<dx-popup
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [showTitle]="true"

  [title]="'createTestRequest.title' | translate"

  [height]="'auto'"
  [width]="600"
  [maxHeight]="600"

  (onHiding)="onHiding()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'" class="custom-popup-content">
    <form (submit)="submitTestRequest($event)">
      <div class="form-layout">
        <dx-form validationGroup="testRequestValidaiton" labelLocation="left" [showColonAfterLabel]="true" [alignItemLabels]="true">

          <dxi-item>
            <dxo-label [visible]="true" [text]="'createTestRequest.patient' | translate"></dxo-label>
            <dx-text-box
              [readOnly]="true"
              [value]="patient.firstName + ' ' + patient.familyName"
            >
            </dx-text-box>
          </dxi-item>

          <dxi-item>
            <dxo-label [visible]="true" [text]="'createTestRequest.doctor' | translate"></dxo-label>
            <dx-text-box
              [readOnly]="true"
              [value]="doctor.firstName + ' ' + doctor.familyName"
            >
            </dx-text-box>
          </dxi-item>

          <dxi-item>
            <dxo-label [visible]="true" [text]="'createTestRequest.laboratory' | translate"></dxo-label>
            <dx-select-box
              (onValueChanged)="onLaboratoryChange($event)"
              [dataSource]="laboratories"
              [searchEnabled]="true"
              [searchExpr]="['name']"
              [searchMode]="'contains'"
              [showClearButton]="true"
              [value]="selectedLaboratoryId"
              displayExpr="location"
              [placeholder]="'createTestRequest.selectLaboratory' | translate"
              valueExpr="id"
            >
              <dx-validator validationGroup="testRequestValidaiton">
                <dxi-validation-rule
                  type="required"
                  [message]="'createTestRequest.laboratoryRequired' | translate"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </dxi-item>

          <dxi-item>
            <dxo-label [visible]="true" [text]="'createTestRequest.testTypes' | translate"></dxo-label>
            <dx-tag-box
              [showSelectionControls]="true"
              (onValueChanged)="selectedTestTypesIds = $event.value"
              [applyValueMode]="'useButtons'"
              [dataSource]="availableTestTypes"
              [disabled]="!selectedLaboratoryId"
              [searchEnabled]="true"
              [searchExpr]="['name']"
              [searchMode]="'contains'"
              [showClearButton]="true"
              [value]="selectedTestTypesIds"
              displayExpr="name"
              valueExpr="id"
              [selectAllText]="'labels.selectAll' | translate"
            >
              <dx-validator validationGroup="testRequestValidaiton">
                <dxi-validation-rule
                  type="required"
                  [message]="'createTestRequest.testTypesRequired' | translate"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-tag-box>
          </dxi-item>

          <dxi-item itemType="button">
            <dxo-button-options
              [text]="'createTestRequest.createTestRequest' | translate"
              [useSubmitBehavior]="true"
              type="success"
              width="100%"
              [disabled]="!selectedLaboratoryId || selectedTestTypesIds.length === 0"
              validationGroup="emailTemplateValidation"
            ></dxo-button-options>
          </dxi-item>

        </dx-form>
      </div>
    </form>
  </div>
</dx-popup>
