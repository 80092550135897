<div class="content-block">
  <div class="header-container">
    <div class="left-section">
      <dx-button (onClick)="boBackToPatientList()"
                 *ngIf="role !== Role.Patient"
                 type="default"
                 icon="arrowback"
                 [text]="'patientTests.backToPatientList' | translate"
      ></dx-button>
    </div>
    <div class="center-section">
      <h2 class="page-title">{{ 'patientTests.title' | translate }}</h2>
    </div>
    <div class="right-section"></div>
  </div>

  <div class="info-container">
    <app-patient-info-display
      [patient]="patient"
    ></app-patient-info-display>
  </div>

  <div class="dx-card responsive-paddings grid-container">
    <dx-data-grid
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [showBorders]="true"
      [showColumnLines]="true"
      [showRowLines]="true"

      [dataSource]="dataSource"
      (onSelectionChanged)="onSelectionChanged($event)"
      class="grid"
    >

      <dxo-toolbar>
        <dxi-item locateInMenu="auto" *ngIf="role !== Role.Patient && role !== Role.Admin" location="before">
          <div *dxTemplate>
            <dx-button
              (onClick)="this.createTestRequestPopupVisible=true;"
              icon="plus"
              type="success"
              [text]="'patientTests.createTestRequest' | translate"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" *ngIf="role !== Role.Patient" location="before">
          <div *dxTemplate>
            <dx-button
              (onClick)="navigateToManageFiles(this.selectedRow?.id!)"
              [disabled]="!this.selectedRow"
              icon="edit"
              type="default"
              [text]="'patientTests.manageTestResultFiles' | translate">
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="after" *ngIf="isAdmin">
          <div *dxTemplate>
            <dx-button
              (onClick)="deleteTestRequest()"
              [disabled]="!this.selectedRow"
              icon="remove"
              [text]="('patientTests.delete') | translate"
              type="danger"
              stylingMode="contained"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" *ngIf="role === Role.Doctor" location="after">
          <div *dxTemplate>
            <dx-button
              (onClick)="openTestResultUploadPopup()"
              [disabled]="!this.selectedRow || this.remainingTestTypes[this.selectedRow.id].length==0"
              type="success"
              icon="upload"
              [text]="'patientTests.addTestResult' | translate">
            </dx-button>
          </div>
        </dxi-item>

      </dxo-toolbar>

      <dxo-selection mode="single"></dxo-selection>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"></dxo-pager>
      <dxo-filter-row [visible]="true"></dxo-filter-row>

      <dxi-column
        [calculateCellValue]="formatDateAdded"
        alignment="center"
        [caption]="'patientTests.dateRequested' | translate"
        dataField="dateAdded"
        [sortIndex]="1"
        [sortOrder]="'desc'">
      </dxi-column>

      <dxi-column
        [calculateCellValue]="formatDateUpdated"
        alignment="center"
        [caption]="'patientTests.dateUpdated' | translate"
        dataField="dateUpdated"
        [sortIndex]="0"
        [sortOrder]="'desc'">
      </dxi-column>

      <dxi-column
        [calculateCellValue]="getDoctorName"
        [caption]="'patientTests.doctor' | translate"
        alignment="center"
        dataField="doctor.familyName">
      </dxi-column>

      <dxi-column
        dataField="laboratory.location"
        alignment="center"
        [caption]="'patientTests.laboratory' | translate"
        [allowSearch]="true">
      </dxi-column>

      <dxi-column
        [allowSearch]="true"
        alignment="center"
        [caption]="'patientTests.testTypes' | translate"
        cellTemplate="testTypesTemplate">
      </dxi-column>

      <dxi-column
        *ngIf="role !== Role.Patient"
        alignment="center"
        [caption]="'patientTests.remainingTestTypes' | translate"
        cellTemplate="remainingTestTypesTemplate">
      </dxi-column>
      <dxo-master-detail [enabled]="true" [autoExpandAll]="true" template="testResultDetail"></dxo-master-detail>

      <div *dxTemplate="let testRequest of 'testTypesTemplate'">
        {{ getTestTypeNames(testRequest.data.testTypes) }}
      </div>

      <div *dxTemplate="let testRequest of 'remainingTestTypesTemplate'">
        <span *ngIf="remainingTestTypes[testRequest.data.id]?.length! > 0">
          <strong>{{ getTestTypeNames(remainingTestTypes[testRequest.data.id]) }}</strong>
        </span>
      </div>

      <div *dxTemplate="let testRequest of 'testResultDetail'">
        <dx-data-grid
          [columnHidingEnabled]="true"
          [columnAutoWidth]="true"
          [dataSource]="testRequest.data.validTestResults"
          [showBorders]="true"
          [showColumnLines]="true"
          [showRowLines]="true"
        >

          <dxi-column alignment="center" [caption]="'patientTests.testTypes' | translate" cellTemplate="testTypesTemplate"></dxi-column>
          <dxi-column alignment="center" [caption]="'patientTests.fileName' | translate" dataField="fileName"></dxi-column>
          <dxi-column alignment="center" [caption]="'patientTests.dateAdded' | translate" [calculateCellValue]="formatDateAddedTestResult"></dxi-column>
          <dxi-column alignment="center" [caption]="'patientTests.dateUpdated' | translate" [calculateCellValue]="formatDateUpdatedTestResult"></dxi-column>
          <dxi-column alignment="center" [caption]="'patientTests.actions' | translate" cellTemplate="actionsTemplate"></dxi-column>

          <div *dxTemplate="let testResult of 'testTypesTemplate'">
            {{ getTestTypeNames(testResult.data.testTypes) }}
          </div>

          <div *dxTemplate="let testResult of 'actionsTemplate'">
            <dx-button
              (onClick)="navigateToViewTestResult(testResult.data.id)"
              [text]="'patientTests.view' | translate">
            </dx-button>
          </div>
        </dx-data-grid>
      </div>

      <div *dxTemplate="let testRequest of 'mainTestTypesTemplate'">
        {{ getTestTypeNames(testRequest.data.testTypes) }}
        <div *ngIf="remainingTestTypes[testRequest.data.id]?.length! > 0">
          <span>{{ 'patientTests.remaining' | translate }}: {{ getTestTypeNames(remainingTestTypes[testRequest.data.id]) }}</span>
        </div>
      </div>

    </dx-data-grid>
  </div>
</div>


<app-test-result-file-upload-popup
  [isValid]="true"
  [patient]="patient"
  [remainingTestTypes]="selectedRemainingTestTypes"
  [testRequest]="selectedRow!"
  (testResultCreated)="onTestResultFileCreated($event)"
  [(visible)]="testResultFileUploadPopVisible"
></app-test-result-file-upload-popup>

<app-test-request-create-popup
  (createTestRequest)="createTestRequest($event)"
  [(visible)]="createTestRequestPopupVisible"
  [doctor]="this.doctor"
  [laboratories]="laboratories"
  [patient]="patient">
</app-test-request-create-popup>
