<div class="ticker">
  <div
    class="icon-wrapper"
    [class]="getIconClass()"
  >
    <i [class]="icon"></i>
  </div>
  <div class="middle">
    <div class="title">
      {{ titleText }}
    </div>
    <div class="total">
      {{
        data?.length
      }}
    </div>
  </div>
  <div *ngIf="percentage !== 0"
       class="percentage"
       [class]="percentage > 0 ? 'positive': 'negative'"
  >
    <div [class]="'dx-icon-'+ (percentage > 0 ? 'spinup' : 'spindown')"></div>
    <i class="value"></i>
    <span>{{ abs(percentage) }}%</span>
  </div>
</div>
