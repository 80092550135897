<dx-popup
  [dragEnabled]="false"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [showTitle]="true"
  [title]="'updateTestResult.title' | translate"
  [height]="'auto'"
  [width]="500"
  [maxHeight]="600"
  (onHiding)="onHiding()"
  (onShowing)="onShowing()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'" class="custom-popup-content">
    <form (submit)="onSubmit($event)">
      <dx-form validationGroup="validateUpdateTestResult" [formData]="formData" labelLocation="left" [showColonAfterLabel]="true" [alignItemLabels]="true">

        <dxi-item dataField="testTypes">
          <dxo-label [text]="'updateTestResult.testTypes' | translate"></dxo-label>
          <dx-tag-box
            [showSelectionControls]="true"
            (onValueChanged)="selectedTestTypesIds = $event.value"
            [applyValueMode]="'useButtons'"
            [dataSource]="availableTestTypes"
            [searchEnabled]="true"
            [searchExpr]="['name']"
            [searchMode]="'contains'"
            [showClearButton]="true"
            [(value)]="selectedTestTypesIds"
            displayExpr="name"
            valueExpr="id"
            [selectAllText]="'labels.selectAll' | translate"
          >
            <dx-validator validationGroup="validateUpdateTestResult">
              <dxi-validation-rule
                type="required"
                [message]="'updateTestResult.testTypesRequired' | translate"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-tag-box>
        </dxi-item>

        <dxi-item
          dataField="isValid"
          editorType="dxCheckBox"
          [editorOptions]="{ value: testResult.isValid }">
          <dxo-label [text]="'updateTestResult.isValid' | translate"></dxo-label>
        </dxi-item>

        <dxi-item itemType="button">
          <dxo-button-options
            [text]="'updateTestResult.updateTestResult' | translate"
            [useSubmitBehavior]="true"
            [width]="'100%'"
            type="success"
            [disabled]="!selectedTestTypesIds || selectedTestTypesIds.length === 0"
            validationGroup="validateUpdateTestResult"
          >
          </dxo-button-options>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>
