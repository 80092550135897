<dx-popup
  [dragEnabled]="false"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [showTitle]="true"

  [title]="(isEditing ? 'laboratoryForm.updateTitle' : 'laboratoryForm.createTitle') | translate"
  [height]="'auto'"
  [width]="600"

  (onHiding)="onHiding()"
  (onShowing)="onShowing()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'">
  <form (submit)="onSubmit($event)">
    <dx-form [formData]="formData" labelLocation="left" [showColonAfterLabel]="true" [alignItemLabels]="true">

      <dxi-item>
        <dxo-label [text]="'laboratoryForm.testTypes' | translate"></dxo-label>
        <dx-tag-box
          [placeholder]="'laboratoryForm.tagboxPlaceholder' | translate"
          [showSelectionControls]="true"
          (onValueChanged)="selectedTestTypesIds = $event.value"
          [applyValueMode]="'useButtons'"
          [dataSource]="availableTestTypes"
          [searchEnabled]="true"
          [searchExpr]="['name']"
          [searchMode]="'contains'"
          [showClearButton]="true"
          [(value)]="selectedTestTypesIds"
          displayExpr="name"
          valueExpr="id"
          [stylingMode]="'filled'"
          [selectAllText]="'labels.selectAll' | translate"
        >
        </dx-tag-box>
        <dxi-validation-rule
          type="required"
          [message]="'laboratoryForm.testTypesRequired' | translate"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        dataField="location"
        editorType="dxTextBox"
        [editorOptions]="{ stylingMode: 'filled', placeholder: ('laboratoryForm.location' | translate) }">
        <dxi-validation-rule [message]="'laboratoryForm.locationRequired' | translate" type="required"></dxi-validation-rule>
        <dxo-label [visible]="true" [text]="'laboratoryForm.location' | translate"></dxo-label>
      </dxi-item>

      <dxi-item
        dataField="contactNumber"
        editorType="dxTextBox"
        [editorOptions]="{ stylingMode: 'filled', placeholder: ('laboratoryForm.phoneNumber' | translate) }">
        <dxi-validation-rule [message]="'laboratoryForm.phoneNumberRequired' | translate" type="required"></dxi-validation-rule>
        <dxi-validation-rule [pattern]="phonePattern" [message]="'validation.phoneNumberInvalid' | translate" type="pattern"></dxi-validation-rule>
        <dxo-label [visible]="true" [text]="'laboratoryForm.phoneNumber' | translate"></dxo-label>
      </dxi-item>

      <dxi-item
        *ngIf="isEditing"
        dataField="isSoftDeleted"
        editorType="dxCheckBox">
        <dxo-label [text]="'laboratoryForm.isSoftDeleted' | translate"></dxo-label>
      </dxi-item>

      <dxi-item
        itemType="button">
        <dxo-button-options
          [disabled]="!selectedTestTypesIds || selectedTestTypesIds.length === 0"
          [text]="(isEditing ? 'laboratoryForm.updateLaboratory' : 'laboratoryForm.createLaboratory') | translate"
          [useSubmitBehavior]="true"
          type="success"
          width="100%"
        >
        </dxo-button-options>
      </dxi-item>

    </dx-form>
  </form>
  </div>
</dx-popup>
