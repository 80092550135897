import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PatientDto, PatientService, TestResultService, TestResultDto } from "../../shared/services/swagger";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NotificationService } from "../../shared/services/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { Role } from "../../shared/services/role.enum";

@Component({
  selector: 'app-test-result-view',
  templateUrl: './test-result-view.component.html',
  styleUrl: './test-result-view.component.scss'
})
export class TestResultViewComponent implements OnInit {
  patient: PatientDto = {} as PatientDto;
  pdfUrl: SafeResourceUrl | null = null;
  testResultId: number;
  patientId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testResultService: TestResultService,
    private patientService: PatientService,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.testResultId = Number(this.route.snapshot.paramMap.get('testResultId'));
    this.patientId = Number(this.route.snapshot.paramMap.get('patientId'));
  }

  ngOnInit(): void {
    this.loadData();
  }

  goBackToPatientTests() {
    this.router.navigate(["pages/patient-tests", this.patient.id]);
  }

  private loadData(): void {
    this.testResultService.getTestResult(this.testResultId).subscribe({
      next: (testResult: TestResultDto) => {
        if (testResult.isValid) {
          this.loadPatientAndPdf();
        } else {
          this.handleInvalidTestResult();
        }
      },
      error: (error) => this.handleError(error)
    });
  }

  private loadPatientAndPdf(): void {
    this.patientService.getPatient(this.patientId).subscribe({
      next: (patient: PatientDto) => {
        this.patient = patient;
        this.downloadPdf();
      },
      error: (error) => this.handleError(error)
    });
  }

  private downloadPdf(): void {
    this.testResultService.downloadFile(this.testResultId).subscribe({
      next: (result: Blob) => {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(result));
      },
      error: (error) => this.handleError(error)
    });
  }

  private handleInvalidTestResult(): void {
    this.router.navigate(['/home']);
    this.notificationService.error(this.translate.instant("testResultView.invalidTestResult"));
  }

  private handleError(error: any): void {
    console.error("Error:", error);
    this.pdfUrl = null;

    const errorMessage = error.status === 401
      ? this.translate.instant("testResultView.noPermissionToViewFile")
      : this.translate.instant("testResultView.errorLoadingData");

    this.notificationService.error(errorMessage);
  }

  protected readonly Role = Role;
}
