<h2 class="content-block">{{ 'testTypeList.title' | translate }}</h2>
<div class="content-block">
  <div class="dx-card responsive-paddings">

    <dx-data-grid
      [columnHidingEnabled]="true"
      [showBorders]="true"
      [showColumnLines]="true"
      [showRowLines]="true"
      [columnAutoWidth]="false"

      [dataSource]="dataSource"
      (onSelectionChanged)="onSelectionChanged($event)"
      [(selectedRowKeys)]="selectedRowKeys"
    >
      <dxo-toolbar>
        <dxi-item locateInMenu="auto" location="before" *ngIf="isAdmin">
          <div *dxTemplate>
            <dx-button
              (onClick)="this.openUpdatePopup()"
              [disabled]="!this.selectedRow"
              icon="edit"
              type="default"
              [text]="'testTypeList.updateTestType' | translate">
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="before" *ngIf="isAdmin">
          <div *dxTemplate>
            <dx-button
              (onClick)="selectedRow?.isSoftDeleted ? undeleteTestType() : deleteTestType()"
              [disabled]="!this.selectedRow"
              [icon]="selectedRow?.isSoftDeleted ? 'undo' : 'remove'"
              [text]="(selectedRow?.isSoftDeleted ? 'testTypeList.undeleteTestType' : 'testTypeList.deleteTestType') | translate"
              [type]="selectedRow?.isSoftDeleted ? 'success' : 'danger'"
              stylingMode="contained"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="after" *ngIf="isAdmin">
          <div *dxTemplate>
            <dx-button
              (onClick)="this.openCreatePopup()"
              [disabled]="!isAdmin"
              [text]="'testTypeList.addNewTestType' | translate"
              icon="plus"
              stylingMode="contained"
              type="success"
            >
            </dx-button>
          </div>
        </dxi-item>
      </dxo-toolbar>

      <dxo-selection mode="single"></dxo-selection>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"></dxo-pager>
      <dxo-filter-row [visible]="true">
        <dxo-operation-descriptions
          [between]="'filterRow.operationDescriptions.between' | translate"
          [contains]="'filterRow.operationDescriptions.contains' | translate"
          [endsWith]="'filterRow.operationDescriptions.endsWith' | translate"
          [equal]="'filterRow.operationDescriptions.equal' | translate"
          [greaterThan]="'filterRow.operationDescriptions.greaterThan' | translate"
          [greaterThanOrEqual]="'filterRow.operationDescriptions.greaterThanOrEqual' | translate"
          [lessThan]="'filterRow.operationDescriptions.lessThan' | translate"
          [lessThanOrEqual]="'filterRow.operationDescriptions.lessThanOrEqual' | translate"
          [notContains]="'filterRow.operationDescriptions.notContains' | translate"
          [notEqual]="'filterRow.operationDescriptions.notEqual' | translate"
          [startsWith]="'filterRow.operationDescriptions.startsWith' | translate"
        ></dxo-operation-descriptions>
      </dxo-filter-row>

      <dxi-column
        dataField="name"
        [caption]="'testTypeList.testName' | translate"
        alignment="center"
      ></dxi-column>

      <dxi-column
        dataField="isSoftDeleted"
        [caption]="'testTypeList.status' | translate"
        alignment="center"
        [visible]="isAdmin">
        <dxo-lookup [dataSource]="[{value: false, text: ('testTypeList.activated' | translate)}, {value: true, text: ('testTypeList.deactivated' | translate)}]" displayExpr="text"
                    valueExpr="value">
        </dxo-lookup>
        <div *dxTemplate="let data of 'cellTemplate'">
          {{ data.value ? ('testTypeList.deactivated' | translate) : ('testTypeList.activated' | translate) }}
        </div>
      </dxi-column>

    </dx-data-grid>
  </div>
</div>

<app-test-type-form-popup
  [(visible)]="popupVisible"
  [isEditing]="isEditing"
  [testTypeId]="selectedRow?.id"
  (saveTestType)="onSaveTestType($event)"
></app-test-type-form-popup>
