import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  CreateEmailTemplateCommand,
  EmailTemplateDto,
  EmailTemplateService,
  GroupingInfo,
  SortingInfo,
  SummaryInfo,
  UpdateEmailTemplateCommand
} from "../../shared/services/swagger";
import {NotificationService} from "../../shared/services/notification.service";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data";
import {lastValueFrom} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {confirm} from "devextreme/ui/dialog";
import {TokenService} from "../../shared/services/token.service";
import {HelpPopupDataService} from "../../shared/services/help-popup-data.service";


@Component({
  selector: 'app-manage-email-templates',
  templateUrl: './manage-email-templates.component.html',
  styleUrl: './manage-email-templates.component.scss'
})
export class ManageEmailTemplatesComponent implements OnInit {

  // @ts-ignore
  customTemplatesDataSource: DataSource;

  clinicId: number = 0;
  selectedRow: EmailTemplateDto | null = null;

  formPopupVisible: boolean = false;
  isEditing: boolean = false;
  isReadonly: boolean = false;
  helpPopupVisible: boolean = false;
  helpDatasource: {};
  allTemplatesAreCustom: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private emailTemplateService: EmailTemplateService,
    private translate: TranslateService,
    protected tokenService: TokenService,
    protected helpPopup: HelpPopupDataService
  ) {
    this.helpDatasource = this.helpPopup.getManageEmailHelpDataSource();
  }

  ngOnInit(): void {
    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    this.initializeCustomTemplatesDataSource();
    this.areAllTemplatesCustom();
  }

  private initializeCustomTemplatesDataSource(): void {
    this.customTemplatesDataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: async (loadOptions: LoadOptions) => {
          try {
            let response = await lastValueFrom(
              this.emailTemplateService.getDefaultAndCustomTemplatesOfClinic(
                this.clinicId,
                loadOptions.requireTotalCount,
                loadOptions.requireGroupCount,
                false, // isCountQuery
                false, // isSummaryQuery
                loadOptions.skip,
                loadOptions.take,
                loadOptions.sort as SortingInfo[],
                loadOptions.group as GroupingInfo[],
                loadOptions.filter,
                loadOptions.totalSummary as SummaryInfo[],
                loadOptions.groupSummary as SummaryInfo[],
                loadOptions.select as string[],
                undefined, // preSelect
                undefined, // remoteSelect
                undefined, // remoteGrouping
                undefined, // expandLinqSumType
                undefined, // primaryKey
                undefined, // defaultSort
                undefined, // stringToLower
                undefined, // paginateViaPrimaryKey
                undefined, // sortByPrimaryKey
                undefined  // allowAsyncOverSync
              )
            );

            return {
              data: response.data,
              totalCount: response.totalCount,
              summary: response.summary,
              groupCount: response.groupCount,
            };
          } catch (e) {
            console.log(e);
            throw 'Data loading error';
          }
        },
        remove: (key) => lastValueFrom(this.emailTemplateService.deleteTemplate(key))
      }),
    });
  }

  navigateToClinicList() {
    this.router.navigate(["/pages/clinic-list"])
  }

  onSelectionChanged(e: any) {
    this.selectedRow = e.selectedRowsData[0];
  }

  areAllTemplatesCustom(){
    this.emailTemplateService.getUndefinedEmailTypes(this.clinicId).subscribe({
      next: data => {
        this.allTemplatesAreCustom = data.length == 0;
      }
    })
  }

  updateEmailTemplate(updatedTemplate: UpdateEmailTemplateCommand) {
    this.emailTemplateService.updateTemplate(this.clinicId, this.selectedRow!.id, updatedTemplate).subscribe({
      next: value => {
        this.notificationService.success(this.translate.instant("manageEmailTemplates.updated"));
        this.customTemplatesDataSource.reload();
      },
      error: err => {
        if (err.error.title === 'TemplateNotValidException') {
          this.notificationService.error(this.translate.instant("manageEmailTemplates.templateNotValid"));
        } else {
          this.notificationService.error(this.translate.instant("manageEmailTemplates.errorUpdating"));
        }

        console.log(err);
      }
    })
  }

  createEmailTemplate(templateData: CreateEmailTemplateCommand) {
    this.emailTemplateService.addTemplateToClinic(this.clinicId, templateData).subscribe({
      next: value => {
        this.notificationService.success(this.translate.instant("manageEmailTemplates.created"));
        this.customTemplatesDataSource.reload();
        this.areAllTemplatesCustom();
      },
      error: err => {
        console.log(err);
        if (err.error.title === 'TemplateNotValidException') {
          this.notificationService.error(this.translate.instant("manageEmailTemplates.templateNotValid"));
        } else {
          this.notificationService.error(this.translate.instant("manageEmailTemplates.errorCreating"));
        }
      }
    })
  }

  onSaveEmailTemplate(templateData: CreateEmailTemplateCommand | UpdateEmailTemplateCommand) {
    if (this.isEditing) {
      this.updateEmailTemplate(templateData as UpdateEmailTemplateCommand);
    } else {
      this.createEmailTemplate(templateData as CreateEmailTemplateCommand);
    }
  }

  openCreatePopup() {
    this.isEditing = false;
    this.isReadonly = false;
    this.formPopupVisible = true;
  }

  openUpdatePopup() {
    if (this.selectedRow) {
      this.isReadonly = false;
      this.isEditing = true;
      this.formPopupVisible = true;
    }
  }

  openDisplayPopup() {
    this.isEditing = false;
    this.isReadonly = true;
    this.formPopupVisible = true;
  }

  openHelpPopup() {
    this.helpPopupVisible = true;
  }

  deleteEmailTemplate(): void {
    if (this.selectedRow) {
      confirm(this.translate.instant("manageEmailTemplates.confirmDeletion"), this.translate.instant("testTypeList.confirmDeletionTitle"))
        .then((dialogResult: boolean) => {
          if (dialogResult) {
            this.customTemplatesDataSource.store().remove(this.selectedRow!.id)
              .then(() => {
                this.notificationService.success(this.translate.instant("manageEmailTemplates.deleted"));
                this.deselectRow();
                this.customTemplatesDataSource.reload();
                this.areAllTemplatesCustom();
              })
              .catch((err: any) => {
                console.log(err);
                this.notificationService.error(this.translate.instant("manageEmailTemplates.errorDeleting"));
              });
          }
        });
    }
  }

  private deselectRow() {
    this.selectedRow = null;
  }

}
