import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PatientDto, UpdatePatientCommand} from "../../shared/services/swagger";
import {GlobalConfig} from "../../shared/services/global-variables";

@Component({
  selector: 'app-patient-update-popup',
  templateUrl: './patient-update.component.html',
  styleUrl: './patient-update.component.scss'
})
export class PatientUpdateComponent {
  @Input() patient!: PatientDto;
  @Input() visible: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() updatePatient = new EventEmitter<UpdatePatientCommand>();

  formData: any = {};
  genderItems: string[] = ['Male', 'Female'];
  phonePattern = GlobalConfig.phoneNumberPattern;


  onHiding() {
    this.visibleChange.emit(false);
  }

  onFormSubmit(e: Event) {
    e.preventDefault();

    const updatePatientCommand: UpdatePatientCommand = {
      email: this.formData.email,
      phoneNumber: this.formData.phoneNumber,
      firstName: this.formData.firstName,
      familyName: this.formData.familyName,
      cnp: this.formData.cnp ? this.formData.cnp : null,
      dateOfBirth: this.formData.dateOfBirth ? this.formData.dateOfBirth : null,
      weight: this.formData.weight ? this.formData.weight : null,
      height: this.formData.height ? this.formData.height : null,
      gender: this.formData.gender ? this.formData.gender : null,
    };

    this.updatePatient.emit(updatePatientCommand);
  }


}
