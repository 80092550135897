import {Component, NgModule, OnInit} from '@angular/core';
import {AuthenticationService, ClinicDto, ClinicService, UserDto} from "../../shared/services/swagger";
import {CommonModule} from "@angular/common";
import {DxSelectBoxModule} from "devextreme-angular/ui/select-box";
import {DxTextBoxModule} from "devextreme-angular/ui/text-box";
import {AuthService} from "../../shared/services";
import {TokenService} from "../../shared/services/token.service";
import {ThemeService} from "../../shared/services/theme.service";
import {FormsModule} from "@angular/forms";
import {DxButtonModule} from "devextreme-angular";
import {TranslateModule} from "@ngx-translate/core";
import {ImageUtilsService} from "../../shared/services/image-utils.service";

@Component({
  selector: 'app-clinic-selector',
  templateUrl: './clinic-selector.component.html',
  styleUrl: './clinic-selector.component.scss'
})
export class ClinicSelectorComponent implements OnInit {
  allClinics: ClinicDto[] = [];
  selectedClinicId: number | null = null;
  isOpen = false;
  user: UserDto | null = null;

  constructor(
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private tokenService: TokenService,
    private clinicService: ClinicService,
    private themeService: ThemeService,
    protected imageUtils: ImageUtilsService
  ) {
  }

  ngOnInit() {
    this.authService.getUser().subscribe({
      next: (result) => {
        if (result.isOk) {
          this.user = result.data!;
          this.getAllClinics();
        } else {
          console.log('No user data available');
        }
      },
      error: (error) => {
        console.error('Error fetching user data:', error);
      }
    });
  }

  getAllClinics() {
    this.clinicService.getAllClinics().subscribe({
      next: (clinics) => {
        this.allClinics = clinics.data || [];
        this.selectedClinicId = this.tokenService.getClinicId();
      },
      error: (error) => {
        console.error('Error fetching all clinics:', error);
      }
    });
  }

  toggleSelector() {
    this.isOpen = !this.isOpen;
  }

  changeClinic(newClinicId: number) {
    if (newClinicId && this.user) {
      const command = {email: this.user.email};
      this.authenticationService.changeClinic(newClinicId, command).subscribe({
        next: (response) => {
          if (response) {
            this.authService.handleSuccessfulLogin(response);
            this.clinicService.getClinicById(newClinicId).subscribe({
              next: (clinic: ClinicDto) => {
                this.themeService.storeTheme(clinic.themeName)
                window.location.reload();
              }
            })
          }
        },
        error: (error) => {
          console.error('Error changing clinic:', error);
        }
      });
    }
    this.isOpen = false;
  }
}


@NgModule({
  imports: [
    CommonModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    FormsModule,
    DxButtonModule,
    TranslateModule
  ],
  declarations: [ClinicSelectorComponent],
  exports: [ClinicSelectorComponent]
})
export class ClinicSelector {
}
