import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ClinicDto, ClinicService, CreateClinicRequest, UpdateClinicRequest } from "../../shared/services/swagger";
import { ImageUtilsService } from "../../shared/services/image-utils.service";
import { ThemeService } from "../../shared/services/theme.service";
import { DxFileUploaderComponent, DxProgressBarComponent } from "devextreme-angular";
import { NotificationService } from "../../shared/services/notification.service";
import { TranslateService } from "@ngx-translate/core";
import {GlobalConfig} from "../../shared/services/global-variables";

@Component({
  selector: 'app-clinic-form',
  templateUrl: './clinic-form.component.html',
  styleUrls: ['./clinic-form.component.scss']
})
export class ClinicFormPopupComponent {
  @ViewChild(DxFileUploaderComponent, { static: false }) fileUploader!: DxFileUploaderComponent;
  @ViewChild(DxProgressBarComponent, { static: false }) progressBar!: DxProgressBarComponent;

  @Input() visible: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() clinicId: number | undefined = 0;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() saveClinic = new EventEmitter<CreateClinicRequest | UpdateClinicRequest>();

  phonePattern = GlobalConfig.phoneNumberPattern;
  formData: any = {};
  selectedImageUrl: string | ArrayBuffer | null = null;
  currentTheme: string;
  themeSource: any[];
  clinic: ClinicDto = {} as ClinicDto;
  themeName: string | undefined = undefined;

  imageSource = '';

  constructor(
    private imageUtils: ImageUtilsService,
    private themeService: ThemeService,
    private clinicService: ClinicService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {
    this.currentTheme = this.themeService.getTheme();
    this.themeSource = this.themeService.getThemeData();
  }

  onShowing() {
    this.resetForm();
    this.initializeForm();
  }

  initializeForm() {
    if (this.isEditing && this.clinicId != 0) {
      this.clinicService.getClinicById(this.clinicId!).subscribe({
        next: (clinic: ClinicDto) => {
          this.clinic = clinic;
          this.formData = {
            logo: clinic.logo,
            contactNumber: clinic.contactNumber,
            name: clinic.name,
            location: clinic.location,
            sendgridApiKey: clinic.sendgridApiKey,
            email: clinic.email
          };
          this.selectedImageUrl = clinic.logo ? this.imageUtils.getImageSource(clinic.logo) : null;
          this.imageSource = this.getImageSource();
        }
      });
    }
  }

  resetForm() {
    this.clearFileInput();
    this.selectedImageUrl = null;
    this.formData = {};
  }

  clearFileInput() {
    if (this.fileUploader) {
      this.fileUploader.instance.reset();
    }
  }

  onHiding() {
    this.visibleChange.emit(false);
  }

  onSubmit($event: Event) {
    $event.preventDefault();

    if (!this.imageSource) {
      this.notificationService.warning(this.translate.instant("clinicForm.logoRequired"));
      return;
    }

    const theme = this.themeName || this.themeService.getTheme();

    const clinicData = {
      logo: this.formData.logo,
      ContactNumber: this.formData.contactNumber,
      Name: this.formData.name,
      Location: this.formData.location,
      SendgridApiKey: this.formData.sendgridApiKey,
      Email: this.formData.email,
      ThemeName: theme
    };

    if (this.isEditing) {
      this.themeService.applyTheme(theme);
    }

    this.saveClinic.emit(clinicData);
  }

  onFileSelected(event: any) {
    const file = event.value[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageSource = e.target.result;
        this.selectedImageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
      this.formData.logo = file;
    }
  }

  getImageSource(): string {
    if (this.selectedImageUrl) {
      return this.selectedImageUrl as string;
    } else if (this.isEditing && this.clinic.logo) {
      return this.imageUtils.getImageSource(this.clinic.logo);
    }
    return '';
  }

  onThemeChanged($event: string) {
    this.themeName = $event;
  }
}
