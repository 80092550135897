import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {MediaMatcher} from '@angular/cdk/layout';
import {Directionality} from '@angular/cdk/bidi';
import {AppSettings, defaults} from "../../interfaces/AppSettings";
import {LocalStorageService} from "./local-storage.service";
import {AppDirectionality} from "./directionality.service";

export const USER_KEY = 'usr';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private key = 'app-settings';

  private options: AppSettings;

  private readonly notify$ = new BehaviorSubject<Partial<AppSettings>>({});

  get notify() {
    return this.notify$.asObservable();
  }

  private htmlElement!: HTMLHtmlElement;

  constructor(
    private store: LocalStorageService,
    private mediaMatcher: MediaMatcher,
    @Inject(DOCUMENT) private document: Document,
    @Inject(Directionality) public dir: AppDirectionality
  ) {
    const storedOptions = this.store.get(this.key);
    this.options = Object.assign(defaults, storedOptions);
    this.htmlElement = this.document.querySelector('html')!;
  }

  getOptions(): AppSettings {
    return this.options;
  }

  setOptions(options: AppSettings) {
    this.options = Object.assign(defaults, options);
    this.store.set(this.key, this.options);
    this.notify$.next(this.options);
  }

  reset() {
    this.store.remove(this.key);
  }

  getLanguage() {
    return this.options.language;
  }

  setLanguage(lang: string) {
    this.options.language = lang;
    this.store.set(this.key, this.options);
    this.notify$.next(this.options);
  }
}
