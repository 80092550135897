import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DoctorDto, DoctorService, UpdateDoctorCommand, UserRegisterCommand} from "../../shared/services/swagger";
import {GlobalConfig} from "../../shared/services/global-variables";

@Component({
  selector: 'app-doctor-form-popup',
  templateUrl: './doctor-form-popup.component.html',
  styleUrl: './doctor-form-popup.component.scss'
})
export class DoctorFormPopupComponent {
  @Input() doctorId: number | undefined = undefined;
  @Input() visible: boolean = false;
  @Input() isEditing: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() saveDoctor = new EventEmitter<UserRegisterCommand | UpdateDoctorCommand>();

  phonePattern = GlobalConfig.phoneNumberPattern;
  formData: any = {};

  constructor(
    private doctorService: DoctorService,
  ) {

  }

  onShowing() {
    this.initializeForm();
  }

  initializeForm() {
    console.log(this.doctorId)
    if (this.isEditing && this.doctorId != undefined) {
      this.doctorService.getDoctorById(this.doctorId).subscribe({
        next: (doctorDto: DoctorDto) => {
          this.formData = {
            email: doctorDto.email,
            firstName: doctorDto.firstName,
            familyName: doctorDto.familyName,
            phoneNumber: doctorDto.phoneNumber,
            doctorFunction: doctorDto.doctorFunction,
          }
        }
      })
    }
  }

  onHiding() {
    this.visibleChange.emit(false);
    this.resetForm();
  }

  resetForm() {
    this.formData = {};
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const saveDoctorCommand: UpdateDoctorCommand | UserRegisterCommand = {
      email: this.formData.email,
      phoneNumber: this.formData.phoneNumber,
      firstName: this.formData.firstName,
      familyName: this.formData.familyName,
      doctorFunction: this.formData.doctorFunction,
    };
    this.saveDoctor.emit(saveDoctorCommand);
  }

}
