import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationResponse, AuthenticationService, LoginRequest, TwoFactorMethod, UserDto, UserService} from "./swagger";
import {TokenService} from "./token.service";
import {catchError, map, Observable, of, switchMap} from "rxjs";
import {Role} from "./role.enum";
import {NotificationService} from "./notification.service";
import {HttpErrorResponse} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

const defaultPath = '/home';

@Injectable()
export class AuthService {
  private _user: UserDto | null = null;

  constructor(
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private tokenService: TokenService,
    private notificationService: NotificationService,
    private translate: TranslateService,
  ) {
  }

  get loggedIn(): boolean {
    return !!this._user;
  }

  private _lastAuthenticatedPath: string = defaultPath;

  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  public updateUserFromToken() {
    if (this.tokenService.isTokenValid()) {
      this.userService.getUserById(this.tokenService.getUserId()).subscribe({
        next: (result) => {
          this.setUser(result)
          console.log("REDIRECT: " + this._lastAuthenticatedPath)
          this.router.navigate([this._lastAuthenticatedPath]);
          // this.router.navigate(['pages/test-result-view', 5, 1]);
        }
      })
    }
  }

  setUser(userDto: UserDto) {
    this._user = userDto;
  }

  logIn(clinicId: number, loginRequest: LoginRequest): Observable<{ isOk: boolean; data?: UserDto; message?: string }> {
    return this.authenticationService.login(clinicId, loginRequest).pipe(
      switchMap((authResponse: AuthenticationResponse) => {
        if (authResponse.hasToResetPassword) {
          return this.handleSelectLoginMethod(clinicId, authResponse.userId);
        } else if (authResponse.requiresTwoFactor) {
          return this.handleTwoFactorAuthentication(clinicId, authResponse.userId);
        } else {
          return this.handleSuccessfulLogin(authResponse);
        }
      }),
      catchError(this.handleLoginError)
    );
  }

  private handleTwoFactorAuthentication(clinicId: number, userId: number): Observable<{ isOk: boolean; message: string }> {
    return new Observable(observer => {
      this.userService.getUserById(userId).subscribe({
        next: (user: UserDto) => {
          switch (user.preferredTwoFactorMethod) {
            case TwoFactorMethod.Email:
              this.router.navigate(['/validate-email-two-factor', clinicId, userId]);
              break;
            case TwoFactorMethod.Google:
              this.router.navigate(['/validate-google-two-factor', clinicId, userId]);
              break;
            case undefined:
            default:
              this.router.navigate(["/select-two-factor-method", clinicId, userId]);
              this.notificationService.warning(this.translate.instant("authentication.twoFactorRequired"));
              break;
          }
          observer.next({isOk: false, message: 'Two factor authentication required'});
          observer.complete();
        },
        error: (error) => {
          console.error('Error fetching user data:', error);
          observer.next({isOk: false, message: 'Error occurred while processing two-factor authentication'});
          observer.complete();
        }
      });
    });
  }

  private handleSelectLoginMethod(clinicId: number, userId: number): Observable<{ isOk: boolean; message: string }> {
    this.router.navigate(['/select-login-method', clinicId, userId]);
    this.notificationService.warning(this.translate.instant("authentication.changePassword"));
    return of({isOk: false, message: 'Password reset required'});
  }

  public handleSuccessfulLogin(authResponse: AuthenticationResponse): Observable<{ isOk: boolean; data: UserDto }> {
    this.tokenService.token = authResponse.token!;
    return this.userService.getUserById(authResponse.userId).pipe(
      map(user => {
        console.log("login: "+this._lastAuthenticatedPath)
        this._user = user;
        this.router.navigate([this._lastAuthenticatedPath]);
        return {isOk: true, data: user};
      })
    );
  }


  private handleLoginError = (error: any): Observable<{ isOk: boolean; message: string }> => {
    console.error('Authentication failed', error);

    let errorMessage = 'Login failed';

    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 404:
          errorMessage = 'User not found. Please verify your email';
          this.notificationService.error(this.translate.instant("authentication.userNotFound"))
          break;
        case 401:
          errorMessage = 'Incorrect password. Please try again.';
          this.notificationService.error(this.translate.instant("authentication.incorrectPassword"))
          break;
        default:
          errorMessage = error.message || 'An unexpected error occurred';
          this.notificationService.error(this.translate.instant("notification.generalError"))
          break;
      }
    }

    return of({isOk: false, message: errorMessage});
  }


  getUser(): Observable<{ isOk: boolean; data: UserDto | null }> {
    return of({
      isOk: !!this._user,
      data: this._user
    });
  }

  async changePassword(email: string, recoveryCode: string) {
    try {
      // Send request

      return {
        isOk: true
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  }

  async logOut() {
    this._user = null;
    this._lastAuthenticatedPath = defaultPath;
    this.tokenService.removeToken();
    this.router.navigate(['/login-form']);
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getUser().pipe(
      map(result => {
        const isLoggedIn = result.isOk && result.data !== null;
        const user = result.data;
        const role: Role | null = this.tokenService.getUserRole();

        const currentPath = state.url;
        const clinicId = route.params['clinicId'];

        console.log("current: " + currentPath);

        const isAuthForm =
          currentPath.startsWith('/register-google') ||
          currentPath.startsWith('/login-form') ||
          currentPath.startsWith('/register') ||
          currentPath.startsWith('/select-login-method') ||
          currentPath.startsWith('/reset-password') ||
          currentPath.startsWith('/change-password') ||
          currentPath.startsWith('/select-two-factor-method') ||
          currentPath.startsWith('/validate-google-two-factor') ||
          currentPath.startsWith('/validate-email-two-factor') ||
          currentPath.startsWith('/validate-sms-two-factor') ||
          currentPath.startsWith('/change-default-password');

        const patientAllowedPaths = [
          '/pages/test-result-view',
          '/pages/patient-tests'
        ];

        // If user is logged in and on an auth form, redirect to home
        if (isLoggedIn && isAuthForm) {
          this.router.navigate(['/home']);
          return false;
        }

        // If user is not logged in and not on an auth form, redirect to login and notify
        if (!isLoggedIn && !isAuthForm) {
          if (!this.tokenService.isTokenValid()) {
            // this.notificationService.warning(this.translate.instant("authentication.loginRequired"));
          }

          console.log("set: " + currentPath);
          this.authService.lastAuthenticatedPath = currentPath;

          if (clinicId) {
            this.router.navigate(['/login-form', clinicId]);
          } else {
            this.router.navigate(['/login-form']);
          }
          return false;
        }

        // If user is a patient, check if they're trying to access an allowed path
        if (isLoggedIn && user && role === 'Patient') {
          const isAllowedPath = patientAllowedPaths.some(path => currentPath.startsWith(path));

          if (!isAllowedPath) {
            this.router.navigate(['/pages/patient-tests', user.id]);
            return false;
          }
        }

        return isLoggedIn || isAuthForm;
      })
    );
  }
}
