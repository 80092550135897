import {TranslateService} from '@ngx-translate/core';

// Define the Dates type to hold Date objects
export type Dates = {
  startDate: Date;
  endDate: Date;
};

// Update the PanelItem type to use Dates instead of string
export type PanelItem = {
  text: string;
  value: Dates;
  key: number;
};

export class AnalyticsPanel {
  // @ts-ignore
  analyticsPanelItems: Array<PanelItem>;

  constructor(private translate: TranslateService) {
    this.initializeAnalyticsPanelItems();
  }

  private initializeAnalyticsPanelItems() {
    // Pre-calculate the dates
    const today = new Date();
    const getStartDate = (daysAgo: number) => {
      const date = new Date(today);
      date.setDate(today.getDate() - daysAgo);
      return date;
    };

    const getStartDateMonth = () => {
      const date = new Date(today);
      date.setMonth(today.getMonth() - 1);
      return date;
    };

    const getStartDateYear = () => {
      const date = new Date(today);
      date.setFullYear(today.getFullYear() - 1);
      return date;
    };

    const getStartDateAll = () => new Date('2018-01-01');  // Fixed start date for "All"

    // Initialize the array with pre-calculated dates and translations
    this.analyticsPanelItems = [
      {
        text: this.translate.instant('dashboard.week'),
        value: {
          startDate: getStartDate(7),
          endDate: today,
        },
        key: 1,
      },
      {
        text: this.translate.instant('dashboard.twoWeeks'),
        value: {
          startDate: getStartDate(14),
          endDate: today,
        },
        key: 2,
      },
      {
        text: this.translate.instant('dashboard.month'),
        value: {
          startDate: getStartDateMonth(),
          endDate: today,
        },
        key: 3,
      },
      {
          text: this.translate.instant('dashboard.year'),
        value: {
          startDate: getStartDateYear(),
          endDate: today,
        },
        key: 4,
      },
      {
        text: this.translate.instant('dashboard.all'),
        value: {
          startDate: getStartDateAll(),
          endDate: today,
        },
        key: 5,
      }
    ];
  }
}
