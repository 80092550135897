<div class="content-block">
  <div class="dx-card responsive-paddings">
    <dx-scroll-view class="view-wrapper-scroll">
      <div class="view-wrapper">
        <app-toolbar
          [titleText]="'dashboard.title' | translate"
          [panelItems]="analyticsPanelItems"
          [selectedItems]="[5]"
          (selectionChanged)="selectionChange($event)"
          (loadDataClicked)="loadDataClickHandler()"
        ></app-toolbar>
      </div>
    </dx-scroll-view>

    <div class="cards grey">
      <ticker-card
        [titleText]="'dashboard.patients' | translate"
        [data]="patients"
        [percentage]="0"
        icon="fas fa-user fa-2x"
      ></ticker-card>

      <ticker-card
        [titleText]="'dashboard.testRequests' | translate"
        [data]="tests"
        [percentage]="0"
        icon="fas fa-vial fa-2x"
      ></ticker-card>

      <ticker-card
        [titleText]="'dashboard.completeTestRequests' | translate"
        [data]="completedTestRequests"
        [percentage]="0"
        icon="fas fa-check-circle fa-2x"
      ></ticker-card>

      <ticker-card
        [titleText]="'dashboard.incompleteTestRequests' | translate"
        [data]="uncompletedTestRequests"
        [percentage]="0"
        icon="fas fa-times-circle fa-2x"
      ></ticker-card>

    </div>

    <div class="cards">
      <card-analytics [titleText]="'dashboard.doctorTests' | translate">
        <dx-chart
          [dataSource]="doctors">
          <dxi-series
            type="bar"
            argumentField="name"
            valueField="value"
          ></dxi-series>
          <dxo-legend [visible]="false"></dxo-legend>
          <dxo-argument-axis>
            <dxo-label [wordWrap]="'none'" [textOverflow]="'ellipsis'"></dxo-label>
          </dxo-argument-axis>
          <dxo-value-axis>
            <dxo-title text="Test Count"></dxo-title>
          </dxo-value-axis>
          <dxo-size [height]="270"></dxo-size>
        </dx-chart>
      </card-analytics>


      <card-analytics [titleText]="'dashboard.laboratoryTests' | translate">
        <dx-data-grid [dataSource]="this.laboratoriesInfo" [height]="290" [loadPanel]="{ enabled: false }">
          <dxi-column [caption]="'dashboard.location' | translate" dataField="location"></dxi-column>

          <dxi-column
            alignment="left"
            [caption]="'dashboard.tests' | translate"
            dataField="testCount"
            dataType="number"
            sortOrder="desc"
            [hidingPriority]="2"
          ></dxi-column>

          <dxi-column
            alignment="left"
            [caption]="'dashboard.totalPercentage' | translate"
            dataField="percentage"
            name="percentN"
            [hidingPriority]="1"
          ></dxi-column>

          <dxi-column
            alignment="left"
            [caption]="'dashboard.percentage' | translate"
            dataField="percentage"
            name="percentB"
            cellTemplate="salesBullet"
            cssClass="sales-bullet"
            [width]="200"
          ></dxi-column>

          <div *dxTemplate="let cellInfo of 'salesBullet'">
            <dx-bullet
              [showTarget]="false"
              [showZeroLevel]="false"
              [value]="cellInfo.data.percentage"
              [startScaleValue]="0"
              [endScaleValue]="100"
            >
              <dxo-tooltip [enabled]="false"></dxo-tooltip>
              <dxo-size [width]="200" [height]="20"></dxo-size>
            </dx-bullet>
          </div>
        </dx-data-grid>
      </card-analytics>
      <card-analytics [titleText]="'dashboard.testType' | translate">
        <dx-pie-chart
          [dataSource]="testTypeInfo"
          type="doughnut"
          [diameter]="0.8"
          [innerRadius]="0.6"
        >
          <dxi-series argumentField="name" valueField="value">
            <dxo-label backgroundColor="none" [radialOffset]="-20" [visible]="true">
              <dxo-font color="#757575" [size]="15"></dxo-font>
            </dxo-label>
          </dxi-series>

          <dxo-legend>
            <dxo-margin [top]="70"></dxo-margin>
          </dxo-legend>

          <dxo-size [height]="270"></dxo-size>
        </dx-pie-chart>
      </card-analytics>
    </div>
  </div>
</div>
