<div class="login-container">
  <div class="left-side">
    <ng-container *ngIf="!clinic; else clinicInfoTemplate">
      <dx-select-box
        [placeholder]="'loginForm.selectClinic' | translate"
        class="clinic-select"
        (onValueChanged)="onClinicSelected($event)"
        [dataSource]="clinics"
        [displayExpr]="clinicDisplayExpr"
        valueExpr="id"
        [(value)]="selectedClinicId"
        [searchEnabled]="true"
        [searchMode]="'contains'"
        [searchExpr]="['name', 'location']"
        [showClearButton]="true">
      </dx-select-box>

      <div *ngIf="selectedClinicId && !clinic" class="clinic-details">
        <app-clinic-display [clinic]="getSelectedClinic()!"></app-clinic-display>
      </div>
    </ng-container>

    <ng-template #clinicInfoTemplate>
      <app-clinic-display [clinic]="clinic!"></app-clinic-display>
    </ng-template>
  </div>

  <div class="right-side">
    <form (submit)="onSubmit($event)" class="login-form">
      <dx-form [disabled]="loading" [formData]="formData">
        <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: ('labels.email' | translate), mode: 'email' }" dataField="email" editorType="dxTextBox">
          <dxi-validation-rule [message]="'validation.emailRequired' | translate" type="required"></dxi-validation-rule>
          <dxi-validation-rule type="email" [message]="'validation.emailInvalid' | translate"></dxi-validation-rule>
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>

        <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: ('labels.password' | translate), mode: 'password' }" dataField="password" editorType="dxTextBox">
          <dxi-validation-rule [message]="'validation.passwordRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>

        <dxi-item itemType="button">
          <dxo-button-options
            [template]="'signInTemplate'"
            [useSubmitBehavior]="true"
            [disabled]="!selectedClinicId"
            type="default"
            width="100%">
          </dxo-button-options>
        </dxi-item>

        <dxi-item>
          <div class="remember-forgot-container">
            <div class="remember-me-wrapper">
              <dx-check-box
                [text]="'loginForm.rememberMe' | translate"
                [(value)]="formData.rememberMe"
                [elementAttr]="{ class: 'remember-me' }"
                [readOnly]="false">
              </dx-check-box>
            </div>
            <div class="forgot-password">
              {{ 'loginForm.forgot' | translate }} <a (click)="navigateToResetPassword()">{{ 'loginForm.password' | translate }}</a>
            </div>
          </div>
<!--          <asl-google-signin-button type='icon' size='medium'></asl-google-signin-button>-->
        </dxi-item>

        <ng-container *dxTemplate="let item of 'signInTemplate'">
          <div>
            <span class="dx-button-text">
              <ng-container *ngIf="loading; else notLoading">
                <dx-load-indicator [visible]="true" height="24px" width="24px"></dx-load-indicator>
              </ng-container>
              <ng-template #notLoading>{{ 'loginForm.signIn' | translate }}</ng-template>
            </span>
          </div>
        </ng-container>
      </dx-form>
    </form>
  </div>
</div>
