import {Component, EventEmitter, Input, NgModule, Output} from '@angular/core';
import {ScreenService} from "../../../shared/services";
import {DxTabsModule, DxTabsTypes} from "devextreme-angular/ui/tabs";
import {Dates, PanelItem} from "../resource";
import {CommonModule} from "@angular/common";
import {DxButtonModule} from "devextreme-angular";
import {DxToolbarModule} from "devextreme-angular/ui/toolbar";
import DevExpress from "devextreme";
import ClickEvent = DevExpress.ui.dxButton.ClickEvent;
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {
  // @ts-ignore
  @Input() selectedItems: Array<number>;
  // @ts-ignore
  @Input() titleText: string;
  // @ts-ignore
  @Input() panelItems: Array<PanelItem>;

  @Output() selectionChanged = new EventEmitter<Dates>();
  @Output() loadDataClicked = new EventEmitter<void>(); // New Output Event

  constructor(protected screen: ScreenService) {}

  selectionChange(e: DxTabsTypes.SelectionChangedEvent) {
    this.selectionChanged.emit({ startDate: e.addedItems[0].value.startDate, endDate: e.addedItems[0].value.endDate });
  }

  // Method to emit loadDataClicked event when button is clicked
  onLoadDataClick(e: ClickEvent) {
    this.loadDataClicked.emit();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxTabsModule,
    DxToolbarModule,
    TranslateModule
  ],
  declarations: [ToolbarComponent],
  exports: [ToolbarComponent],
})
export class ToolbarAnalyticsModule {
}
