<dx-popup
  [dragEnabled]="false"
  [showCloseButton]="true"
  [showTitle]="true"
  [hideOnOutsideClick]="true"
  [title]="'updatePatient.title' | translate"
  height="90vh"
  [width]="600"
  [(visible)]="visible"
  (onHiding)="onHiding()"
>
  <div *dxTemplate="let data of 'content'">
    <form (submit)="onFormSubmit($event)">
      <dx-form [formData]="formData" [showColonAfterLabel]="true" labelLocation="left" [alignItemLabels]="true">
        <dxi-item dataField="email"
                  editorType="dxTextBox"
                  [editorOptions]="{ stylingMode: 'filled', mode: 'email', value: patient.email }">
          <dxi-validation-rule [message]="'updatePatient.emailRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'updatePatient.email' | translate"></dxo-label>
        </dxi-item>

        <dxi-item
          [editorOptions]="{ stylingMode: 'filled', value: patient.phoneNumber}"
          dataField="phoneNumber"
          editorType="dxTextBox"
        >
          <dxi-validation-rule [message]="('validation.phoneNumberRequired') | translate"
                               type="required"></dxi-validation-rule>
          <dxi-validation-rule [message]="('validation.phoneNumberInvalid') | translate"
                               [pattern]="phonePattern"
                               type="pattern"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="('updatePatient.phoneNumber') | translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="firstName"
                  editorType="dxTextBox"
                  [editorOptions]="{ stylingMode: 'filled', value: patient.firstName }">
          <dxi-validation-rule [message]="'updatePatient.firstNameRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'updatePatient.firstName' | translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="familyName"
                  editorType="dxTextBox"
                  [editorOptions]="{ stylingMode: 'filled', value: patient.familyName }">
          <dxi-validation-rule [message]="'updatePatient.familyNameRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'updatePatient.familyName' | translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="cnp"
                  editorType="dxTextBox"
                  [editorOptions]="{ value: patient.cnp }">
          <dxo-label [text]="'updatePatient.cnp' | translate"></dxo-label>
          <dxi-validation-rule type="pattern" [pattern]="'^\\d{13}$'" [message]="'updatePatient.cnpInvalid' | translate"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="dateOfBirth"
                  editorType="dxDateBox"
                  [editorOptions]="{
              value: patient.dateOfBirth,
              displayFormat: 'dd.MM.yyyy',
              dateSerializationFormat: 'yyyy-MM-dd'
               }">
          <dxo-label [text]="'updatePatient.dateOfBirth' | translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="weight"
                  editorType="dxTextBox"
                  [editorOptions]="{ value: patient.weight }">
          <dxo-label [text]="'updatePatient.weight' | translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="height"
                  editorType="dxTextBox"
                  [editorOptions]="{ value: patient.height }">
          <dxo-label [text]="'updatePatient.height' | translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="gender"
                  editorType="dxSelectBox"
                  [editorOptions]="{ items: genderItems, value: patient.gender }">
          <dxo-label [text]="'updatePatient.gender' | translate"></dxo-label>
        </dxi-item>

        <dxi-item itemType="button">
          <dxo-button-options
            [text]="'updatePatient.updatePatientInfo' | translate"
            [useSubmitBehavior]="true"
            type="success"
            width="100%">
          </dxo-button-options>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>
