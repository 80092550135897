import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HelpPopupDataService {
  constructor(private translateService: TranslateService) {}

  getManageEmailHelpDataSource() {

    const type = "Email";
    const localTranslate = (key:string, params = {}) => {
      return this.translateService.instant(key, { ...params, type });
    };
    const buttonsTitle = localTranslate('commonManageTemplateHelp.buttons.title');
    const intro = localTranslate('commonManageTemplateHelp.buttons.intro');

    const viewButtonTitle = localTranslate('commonManageTemplateHelp.buttons.viewButton.title');
    const viewButtonDescription = localTranslate('commonManageTemplateHelp.buttons.viewButton.description');
    const updateButtonTitle = localTranslate('commonManageTemplateHelp.buttons.updateButton.title');
    const updateButtonDescription = localTranslate('commonManageTemplateHelp.buttons.updateButton.description');
    const deleteButtonTitle = localTranslate('commonManageTemplateHelp.buttons.deleteButton.title');
    const deleteButtonDescription = localTranslate('commonManageTemplateHelp.buttons.deleteButton.description');
    const createButtonTitle = localTranslate('commonManageTemplateHelp.buttons.createButton.title');
    const createButtonDescription = localTranslate('commonManageTemplateHelp.buttons.createButton.description');

    const selectTitle = localTranslate('commonManageTemplateHelp.select.title');
    const selectIntro = localTranslate('commonManageTemplateHelp.select.textIntro');
    const selectFilter = localTranslate('commonManageTemplateHelp.select.textFilter');

    const formIntroductionTitle = localTranslate('commonManageTemplateHelp.formIntroduction.title');
    const formIntroductionTextIntro = localTranslate('commonManageTemplateHelp.formIntroduction.textIntro');
    const formIntroductionFormTitle = localTranslate('commonManageTemplateHelp.formIntroduction.form.title');
    const formIntroductionFormDescription = localTranslate('commonManageTemplateHelp.formIntroduction.form.description');
    const formIntroductionParameterSelectorTitle = localTranslate('commonManageTemplateHelp.formIntroduction.parameterSelector.title');
    const formIntroductionParameterSelectorDescription = localTranslate('commonManageTemplateHelp.formIntroduction.parameterSelector.description');

    const formTitle = localTranslate('commonManageTemplateHelp.form.title');
    const formIntro = localTranslate('commonManageTemplateHelp.form.intro');
    const formSubjectTitle = localTranslate('manageEmailHelp.form.subject.title');
    const formSubjectDescription = localTranslate('manageEmailHelp.form.subject.description');
    const formMessageTypeTitle = localTranslate('commonManageTemplateHelp.form.messageType.title');
    const formMessageTypeDescription = localTranslate('commonManageTemplateHelp.form.messageType.description');
    const formEditorTitle = localTranslate('commonManageTemplateHelp.form.editor.title');
    const formEditorDescription = localTranslate('commonManageTemplateHelp.form.editor.description');

    const parameterSelectorTitle = localTranslate('commonManageTemplateHelp.parameterSelector.title');
    const parameterSelectorText1 = localTranslate('commonManageTemplateHelp.parameterSelector.text1');
    const parameterSelectorText2 = localTranslate('commonManageTemplateHelp.parameterSelector.text2');

    return [
      {
        title: buttonsTitle,
        image: 'assets/tutorials/manage-emails/buttons.png',
        text: `
          <p>${intro}</p>
          <ol>
            <li><b>${viewButtonTitle}</b>: ${viewButtonDescription}</li>
            <li><b>${updateButtonTitle}</b>: ${updateButtonDescription}</li>
            <li><b>${deleteButtonTitle}</b>: ${deleteButtonDescription}</li>
            <li><b>${createButtonTitle}</b>: ${createButtonDescription}</li>
          </ol>
        `,
        layout: 'horizontal'
      },
      {
        title: selectTitle,
        image: 'assets/tutorials/manage-emails/selection.png',
        text: `
          <p>${selectIntro}</p>
          <p>${selectFilter}</p>
        `,
        layout: 'horizontal'
      },
      {
        title: formIntroductionTitle,
        image: 'assets/tutorials/manage-emails/create.png',
        text: `
          <p>${formIntroductionTextIntro}</p>
          <ol>
            <li><b>${formIntroductionFormTitle}</b>: ${formIntroductionFormDescription}</li>
            <li><b>${formIntroductionParameterSelectorTitle}</b>: ${formIntroductionParameterSelectorDescription}</li>
          </ol>
        `,
        layout: 'vertical'
      },
      {
        title: formTitle,
        image: 'assets/tutorials/manage-emails/form.png',
        text: `
          <p>${formIntro}</p>
          <ol>
            <li><b>${formSubjectTitle}</b>: ${formSubjectDescription}</li>
            <li><b>${formMessageTypeTitle}</b>: ${formMessageTypeDescription}</li>
            <li><b>${formEditorTitle}</b>: ${formEditorDescription}</li>
          </ol>
        `,
        layout: 'vertical'
      },
      {
        title: parameterSelectorTitle,
        image: 'assets/tutorials/manage-emails/parameter-selection.gif',
        text: `
          <p>${parameterSelectorText1}</p>
          <p>${parameterSelectorText2}</p>
        `,
        layout: 'vertical'
      }
    ];
  }

  getManageSmsHelpDataSource() {
    const type = "SMS";
    const localTranslate = (key:string, params = {}) => {
      return this.translateService.instant(key, { ...params, type });
    };

    const buttonsTitle = localTranslate('commonManageTemplateHelp.buttons.title');
    const intro = localTranslate('commonManageTemplateHelp.buttons.intro');

    const viewButtonTitle = localTranslate('commonManageTemplateHelp.buttons.viewButton.title');
    const viewButtonDescription = localTranslate('commonManageTemplateHelp.buttons.viewButton.description');
    const updateButtonTitle = localTranslate('commonManageTemplateHelp.buttons.updateButton.title');
    const updateButtonDescription = localTranslate('commonManageTemplateHelp.buttons.updateButton.description');
    const deleteButtonTitle = localTranslate('commonManageTemplateHelp.buttons.deleteButton.title');
    const deleteButtonDescription = localTranslate('commonManageTemplateHelp.buttons.deleteButton.description');
    const createButtonTitle = localTranslate('commonManageTemplateHelp.buttons.createButton.title');
    const createButtonDescription = localTranslate('commonManageTemplateHelp.buttons.createButton.description');

    const selectTitle = localTranslate('commonManageTemplateHelp.select.title');
    const selectIntro = localTranslate('commonManageTemplateHelp.select.textIntro');
    const selectFilter = localTranslate('commonManageTemplateHelp.select.textFilter');

    const formIntroductionTitle = localTranslate('commonManageTemplateHelp.formIntroduction.title');
    const formIntroductionTextIntro = localTranslate('commonManageTemplateHelp.formIntroduction.textIntro');
    const formIntroductionFormTitle = localTranslate('commonManageTemplateHelp.formIntroduction.form.title');
    const formIntroductionFormDescription = localTranslate('commonManageTemplateHelp.formIntroduction.form.description');
    const formIntroductionParameterSelectorTitle = localTranslate('commonManageTemplateHelp.formIntroduction.parameterSelector.title');
    const formIntroductionParameterSelectorDescription = localTranslate('commonManageTemplateHelp.formIntroduction.parameterSelector.description');

    const formTitle = localTranslate('commonManageTemplateHelp.form.title');
    const formIntro = localTranslate('commonManageTemplateHelp.form.intro');
    const formMessageTypeTitle = localTranslate('commonManageTemplateHelp.form.messageType.title');
    const formMessageTypeDescription = localTranslate('commonManageTemplateHelp.form.messageType.description');
    const formEditorTitle = localTranslate('commonManageTemplateHelp.form.editor.title');
    const formEditorDescription = localTranslate('commonManageTemplateHelp.form.editor.description');

    const parameterSelectorTitle = localTranslate('commonManageTemplateHelp.parameterSelector.title');
    const parameterSelectorText1 = localTranslate('commonManageTemplateHelp.parameterSelector.text1');
    const parameterSelectorText2 = localTranslate('commonManageTemplateHelp.parameterSelector.text2');

    return [
      {
        title: selectTitle,
        image: 'assets/tutorials/manage-sms/selection.png',
        text: `
          <p>${selectIntro}</p>
          <p>${selectFilter}</p>
        `,
        layout: 'horizontal'
      },
      {
        title: buttonsTitle,
        image: 'assets/tutorials/manage-sms/buttons.png',
        text: `
          <p>${intro}</p>
          <ol>
            <li><b>${viewButtonTitle}</b>: ${viewButtonDescription}</li>
            <li><b>${updateButtonTitle}</b>: ${updateButtonDescription}</li>
            <li><b>${deleteButtonTitle}</b>: ${deleteButtonDescription}</li>
            <li><b>${createButtonTitle}</b>: ${createButtonDescription}</li>
          </ol>
        `,
        layout: 'horizontal'
      },
      {
        title: formIntroductionTitle,
        image: 'assets/tutorials/manage-sms/form-structure.png',
        text: `
          <p>${formIntroductionTextIntro}</p>
          <ol>
            <li><b>${formIntroductionFormTitle}</b>: ${formIntroductionFormDescription}</li>
            <li><b>${formIntroductionParameterSelectorTitle}</b>: ${formIntroductionParameterSelectorDescription}</li>
          </ol>
        `,
        layout: 'vertical'
      },
      {
        title: formTitle,
        image: 'assets/tutorials/manage-sms/form.png',
        text: `
          <p>${formIntro}</p>
          <ol>
            <li><b>${formMessageTypeTitle}</b>: ${formMessageTypeDescription}</li>
            <li><b>${formEditorTitle}</b>: ${formEditorDescription}</li>
          </ol>
        `,
        layout: 'vertical'
      },
      {
        title: parameterSelectorTitle,
        image: 'assets/tutorials/manage-emails/parameter-selection.gif',
        text: `
          <p>${parameterSelectorText1}</p>
          <p>${parameterSelectorText2}</p>
        `,
        layout: 'vertical'
      }
    ];
  }

}
