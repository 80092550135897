import {CommonModule} from '@angular/common';
import {Component, NgModule} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {SingleCardModule} from './layouts';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-unauthenticated-content',
  template: `
    <app-single-card [title]="title" [description]="description">
      <router-outlet></router-outlet>
    </app-single-card>
  `,
  styles: [`
    :host {
      width: 100%;
      height: 100%;
    }
  `]
})
export class UnauthenticatedContentComponent {

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
  }

  get title() {
    const path = this.router.url.split('/')[1];
    switch (path) {
      case 'login-form':
        return this.translate.instant("login.title");
      case 'reset-password':
        return this.translate.instant("forgotPassword.title");
      case 'change-password':
        return this.translate.instant("changePassword.title");
      case 'change-default-password':
        return this.translate.instant("changeDefaultPassword.title");
      case 'select-login-method':
        return this.translate.instant("selectLoginMethod.title");
      case 'select-two-factor-method':
        return this.translate.instant("selectTwoFactorMethod.title");
      case 'validate-email-two-factor':
        return this.translate.instant("validateEmailTwoFactor.title");
      case 'validate-google-two-factor':
        return this.translate.instant("validateGoogleTwoFactor.title");
      case 'validate-sms-two-factor':
        return this.translate.instant("validateSmsTwoFactor.title");
      default:
        return '';
    }
  }

  get description() {
    const path = this.router.url.split('/')[1];
    switch (path) {
      case 'reset-password':
        return this.translate.instant("forgotPassword.description");
      case 'select-login-method':
        return this.translate.instant("selectLoginMethod.description");
      case 'select-two-factor-method':
        return this.translate.instant("selectTwoFactorMethod.description");
      case 'change-default-password':
        return this.translate.instant("changeDefaultPassword.description");
      case 'validate-email-two-factor':
        return this.translate.instant("validateEmailTwoFactor.description");
      case 'validate-google-two-factor':
        return this.translate.instant("validateGoogleTwoFactor.description");
      case 'validate-sms-two-factor':
        return this.translate.instant("validateSmsTwoFactor.description");
      case 'change-password':
        return this.translate.instant("changePassword.description");
      default:
        return '';
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SingleCardModule,
  ],
  declarations: [UnauthenticatedContentComponent],
  exports: [UnauthenticatedContentComponent]
})
export class UnauthenticatedContentModule {
}
