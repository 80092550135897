import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService, ChangeDefaultPasswordCommand, ClinicDto, ClinicService, UserDto, UserService} from "../../services/swagger";
import {NotificationService} from "../../services/notification.service";
import {ImageUtilsService} from "../../services/image-utils.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-change-default-password',
  templateUrl: './change-default-password.component.html',
  styleUrl: './change-default-password.component.scss'
})
export class ChangeDefaultPasswordComponent implements OnInit {
  formData: any = {};
  loading: boolean = false;
  clinic: ClinicDto = {} as ClinicDto;
  user: UserDto = {} as UserDto;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clinicService: ClinicService,
    private userService: UserService,
    protected imageUtils: ImageUtilsService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const clinicId = +params.get('clinicId')!;
      const userId = +params.get("userId")!;
      this.loadClinic(clinicId);
      this.loadUser(userId);
    });
  }

  private loadUser(userId: number) {
    this.userService.getUserById(userId).subscribe({
      next: result => {
        this.user = result;
      },
      error: err => {
        console.log(err);
      }
    })
  }

  private loadClinic(clinicId: number) {
    this.clinicService.getClinicById(clinicId).subscribe({
      next: result => {
        this.clinic = result;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const changeDefaultPassword: ChangeDefaultPasswordCommand = {
      email: this.user.email,
      password: this.formData.password,
      confirmPassword: this.formData.confirmPassword
    }

    this.authenticationService.changeDefaultPassword(this.clinic.id, changeDefaultPassword).subscribe({
      next: (result) => {
        this.router.navigate(["/login-form"])
        this.notificationService.success(this.translate.instant("changeDefaultPassword.passwordChanged"));
      }, error: err => {
        this.notificationService.error(this.translate.instant("changeDefaultPassword.errorChanging"));
        console.log(err)
      }
    });

  }
}
