import {Component, OnInit} from '@angular/core';
import {AuthenticationResponse, AuthenticationService, ClinicDto, ClinicService, VerifyTwoFactorCommand} from "../../services/swagger";
import {AuthService} from "../../services";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../services/notification.service";
import {ImageUtilsService} from "../../services/image-utils.service";
import { HttpErrorResponse } from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-validate-email-two-factor',
  templateUrl: './validate-email-two-factor.component.html',
  styleUrl: './validate-email-two-factor.component.scss'
})
export class ValidateEmailTwoFactorComponent implements OnInit {
  loading = false;
  clinic: ClinicDto = {} as ClinicDto;

  formData: any = {};
  clinicId: number = 0;
  userId: number = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private clinicService: ClinicService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    protected imageUtils: ImageUtilsService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    this.userId = Number(this.route.snapshot.paramMap.get('userId'));
    this.enableEmailTwoFactor();
    this.loadClinic(this.clinicId);
  }

  loadClinic(clinicId: number) {
    this.clinicService.getClinicById(clinicId).subscribe({
      next: result => {
        this.clinic = result;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  private enableEmailTwoFactor() {
    this.authenticationService.activateEmailTwoFactor(this.userId).subscribe({
      next: result => {
        this.notificationService.success(this.translate.instant("validateEmailTwoFactor.emailSent"))
      },
      error: err => {
        console.log(err);
      }
    })
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const verifyTwoFactorCommand: VerifyTwoFactorCommand = {
      twoFactorCode: this.formData.twoFactorCode
    }

    this.authenticationService.verifyEmailTwoFactor(this.clinicId, this.userId, verifyTwoFactorCommand).subscribe({
      next: (response: AuthenticationResponse) => {
        this.authService.handleSuccessfulLogin(response).subscribe({
          next: value => {
            if (value.isOk) {
              this.router.navigate(['/home']);
              this.notificationService.success(this.translate.instant('validateEmailTwoFactor.loginSuccessful'));
            }
          }
        })
      },
      error: err => {
        console.log(err);
        this.handleError(err)
      }
    })
  }

  navigateToLogin() {
    this.router.navigate(['/login-form']);
  }

  navigateToSelectLoginMethod() {
    this.router.navigate(["/select-two-factor-method", this.clinicId, this.userId]);
  }

  private handleError(err: HttpErrorResponse) {
    switch (err.status) {
      case 400:
        if (err.error.title === 'InvalidTwoFactorCodeException') {
          this.notificationService.error(this.translate.instant('errors.invalidTwoFactorCode'));
        } else {
          this.notificationService.error(this.translate.instant('errors.genericInputError'));
        }
        break;
      case 401:
        this.notificationService.error(this.translate.instant('errors.authenticationFailed'));
        break;
      case 404:
        if (err.error.title === 'UserNotFoundException') {
          this.notificationService.error(this.translate.instant('errors.userNotFound'));
        } else {
          this.notificationService.error(this.translate.instant('errors.resourceNotFound'));
        }
        break;
      case 429:
        this.notificationService.error(this.translate.instant('errors.tooManyAttempts'));
        this.navigateToLogin();
        break;
      default:
        this.notificationService.error(this.translate.instant('errors.unexpectedError'));
    }
    console.error('Error details:', err);
  }
}
