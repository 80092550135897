import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  BatchChangeValidityCommand,
  GroupingInfo,
  PatientDto,
  PatientService,
  SortingInfo,
  SummaryInfo,
  TestRequestDto,
  TestRequestService,
  TestResultDto,
  TestResultService,
  TestTypeDto,
  UpdateTestResultCommand
} from "../../shared/services/swagger";
import {lastValueFrom} from 'rxjs';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data";
import {NotificationService} from "../../shared/services/notification.service";
import {DateFormatterService} from "../../shared/services/date-formater.service";
import {TokenService} from "../../shared/services/token.service";
import {Role} from "../../shared/services/role.enum";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-test-result-files-list',
  templateUrl: './test-result-files-list.component.html',
  styleUrl: './test-result-files-list.component.scss'
})
export class TestResultFilesListComponent implements OnInit {

  patient: PatientDto = {} as PatientDto;
  testRequest: TestRequestDto = {} as TestRequestDto;
  dataSource: DataSource = {} as DataSource;

  selectedRowKeys: any[] = [];
  selectedRow: TestResultDto | null = null;
  testResultFileUploadPopupVisible: boolean = false;
  testResultUpdatePopupVisible: boolean = false;
  clinicId: number = 0;

  constructor(
    private testResultService: TestResultService,
    private testRequestService: TestRequestService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    protected dateFormatterService: DateFormatterService,
    private patientService: PatientService,
    protected tokenService: TokenService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.clinicId = this.tokenService.getClinicId();
  }

  ngOnInit(): void {
    const patientIdParam = this.route.snapshot.paramMap.get('patientId');
    const testRequestIdParam = this.route.snapshot.paramMap.get('testRequestId');

    if (patientIdParam === null || testRequestIdParam === null) {
      return;
    }

    const patientId = +patientIdParam;
    const testRequestId = +testRequestIdParam;

    this.patientService.getPatient(patientId)
      .subscribe({
        next: (patient) => {
          this.patient = patient;
          this.testRequestService.getTestRequestById(testRequestId)
            .subscribe({
              next: (testRequest) => {
                this.testRequest = testRequest;
                this.initializeDataSource(testRequestId);
              },
              error: (error) => {
                console.error('Error fetching test request:', error);
                this.notificationService.error(this.translate.instant('testResultManagement.failedToLoadTestRequest'));
              }
            });
        },
        error: (error) => {
          console.error('Error fetching patient:', error);
          this.notificationService.error(this.translate.instant('testResultManagement.failedToLoadPatientData'));
        }
      });
  }

  onSelectionChanged(e: any) {
    this.selectedRow = e.selectedRowsData[0];
  }

  navigateToViewTestResult(testResultFileId: number | null) {
    this.router.navigate(['pages/test-result-view', this.patient.id, testResultFileId]);
  }

  goBackToPatientTests() {
    this.router.navigate(['/pages/patient-tests', this.patient.id]);
  }

  getTestTypeNames(testTypes: TestTypeDto[]): string {
    return testTypes.map(testType => testType.name).join(', ');
  }

  onTestResultFileCreated($event: TestResultDto) {
    this.dataSource.reload();
    this.resetSelection();
  }

  validateSelectedTestResults() {
    const changeValidityCommand: BatchChangeValidityCommand = {
      testResultsToValidate: this.selectedRowKeys,
      validity: true
    }

    this.testResultService.changeTestResultsValidity(this.testRequest.id, changeValidityCommand).subscribe({
      next: value => {
        this.dataSource.reload();
        this.resetSelection();
        this.notificationService.success(this.translate.instant('testResultManagement.validated'));
      },
      error: err => {
        console.log(err);
        this.notificationService.error(this.translate.instant('testResultManagement.errorValidating'));
      }
    })
  }

  invalidateSelectedTestResults() {
    const changeValidityCommand: BatchChangeValidityCommand = {
      testResultsToValidate: this.selectedRowKeys,
      validity: false
    }

    this.testResultService.changeTestResultsValidity(this.testRequest.id, changeValidityCommand).subscribe({
      next: value => {
        this.dataSource.reload();
        this.resetSelection();
        this.notificationService.success(this.translate.instant('testResultManagement.invalidated'));
      },
      error: err => {
        console.log(err);
        this.notificationService.error(this.translate.instant('testResultManagement.errorInvalidating'));
      }
    })
  }

  formatDateUpdated = (testResultDto: TestResultDto): string => {
    if (!testResultDto.dateUpdated) return '';
    return this.dateFormatterService.formatToRomanianTime(testResultDto.dateUpdated);
  }

  formatDateAdded = (testResultDto: TestResultDto): string => {
    if (!testResultDto.dateAdded) return '';
    return this.dateFormatterService.formatToRomanianTime(testResultDto.dateAdded);
  }

  onTestResultUpdated($event: UpdateTestResultCommand) {
    this.testResultService.updateTestResult(this.testRequest.id, this.selectedRow?.id!, $event).subscribe({
      next: value => {
        this.dataSource.reload();
        this.notificationService.success(this.translate.instant('testResultManagement.updated'));
      },
      error: err => {
        console.log(err);
        this.notificationService.error(this.translate.instant('testResultManagement.errorUpdating'));
      }
    })
  }

  private initializeDataSource(testRequestId: number): void {
    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: async (loadOptions: LoadOptions) => {
          try {
            let response = await lastValueFrom(
              this.testResultService.getAllResultsOfRequest(
                testRequestId,
                loadOptions.requireTotalCount,
                loadOptions.requireGroupCount,
                false, // isCountQuery
                false, // isSummaryQuery
                loadOptions.skip,
                loadOptions.take,
                loadOptions.sort as SortingInfo[],
                loadOptions.group as GroupingInfo[],
                loadOptions.filter,
                loadOptions.totalSummary as SummaryInfo[],
                loadOptions.groupSummary as SummaryInfo[],
                loadOptions.select as string[],
                undefined, // preSelect
                undefined, // remoteSelect
                undefined, // remoteGrouping
                undefined, // expandLinqSumType
                undefined, // primaryKey
                undefined, // defaultSort
                undefined, // stringToLower
                undefined, // paginateViaPrimaryKey
                undefined, // sortByPrimaryKey
                undefined  // allowAsyncOverSync
              )
            );

            return {
              data: response.data,
              totalCount: response.totalCount,
              summary: response.summary,
              groupCount: response.groupCount,
            };
          } catch (e) {
            console.log(e);
            throw 'Data loading error';
          }
        },
        remove: (key) => {
          return lastValueFrom(this.testResultService.deleteTestResult(key));
        }
      }),
    });
  }

  private resetSelection() {
    this.selectedRow = null;
    this.selectedRowKeys = [];
  }

  protected readonly Role = Role;
}
