import {Component, Input, NgModule,} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TickerCardModule} from "../ticker-card/ticker-card.component";
import {PatientDto} from "../../../shared/services/swagger";

@Component({
  selector: 'tests-ticker',
  templateUrl: 'opportunities-ticker.component.html',
})

export class OpportunitiesTickerComponent {
  // @ts-ignore
  @Input() data: PatientDto[] = null;
}

@NgModule({
  imports: [
    CommonModule,
    TickerCardModule,
    TickerCardModule,
  ],
  declarations: [OpportunitiesTickerComponent],
  exports: [OpportunitiesTickerComponent],
})
export class OpportunitiesTickerModule {
}
