import {Component, Input} from '@angular/core';
import {TestRequestDto, TestResultDto} from "../../shared/services/swagger";
import {DateFormatterService} from "../../shared/services/date-formater.service";

@Component({
  selector: 'app-test-request-info-display',
  templateUrl: './test-request-info-display.component.html',
  styleUrl: './test-request-info-display.component.scss'
})
export class TestRequestInfoDisplayComponent {
  @Input() testRequest: TestRequestDto = {} as TestRequestDto;

  constructor(protected dateFormatterService: DateFormatterService) {}
}
