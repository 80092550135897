<dx-popup
  [dragEnabled]="false"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [showTitle]="true"
  [title]="'testResultUpload.title' | translate"
  [height]="'auto'"
  [width]="750"
  [maxHeight]="600"
  (onHiding)="closePopup()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'" class="custom-popup-content">
    <form (submit)="onSubmit($event)">
      <div class="form-layout">
        <dx-form validationGroup="validateFileUpload" labelLocation="left" [showColonAfterLabel]="true" [alignItemLabels]="true">

          <dxi-item>
            <dxo-label [visible]="true" [text]="'testResultUpload.testTypes' | translate"></dxo-label>
            <dx-tag-box
              [showSelectionControls]="true"
              (onValueChanged)="selectedTestTypesIds = $event.value"
              [applyValueMode]="'useButtons'"
              [dataSource]="remainingTestTypes"
              [searchEnabled]="true"
              [searchExpr]="['name']"
              [searchMode]="'contains'"
              [showClearButton]="true"
              [value]="selectedTestTypesIds"
              displayExpr="name"
              valueExpr="id"
              [selectAllText]="'labels.selectAll' | translate"
            >
              <dx-validator validationGroup="validateFileUpload">
                <dxi-validation-rule
                  type="required"
                  [message]="'testResultUpload.testTypesRequired' | translate"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-tag-box>
          </dxi-item>

          <dxi-item>
            <dx-file-uploader
              [(value)]="selectedFiles"
              [multiple]="false"
              [allowedFileExtensions]="['.pdf']"
              uploadMode="useForm"
              [selectButtonText]="('fileUploader.selectAFile') | translate"
              [readyToUploadMessage]="('fileUploader.readyToUpload') | translate"
              [invalidFileExtensionMessage]="('fileUploader.invalidFileExtension') | translate"
              [labelText]="('fileUploader.orDrop') | translate"
              (onValueChanged)="onFileUploaderValueChanged($event)"
            >
            </dx-file-uploader>
          </dxi-item>

          <dxi-item itemType="button">
            <dxo-button-options
              [text]="'testResultUpload.upload' | translate"
              [useSubmitBehavior]="true"
              type="success"
              width="100%"
              [disabled]="!selectedTestTypesIds || selectedTestTypesIds.length === 0 || selectedFiles.length == 0 || !isValidFile"
              validationGroup="emailTemplateValidation"
            ></dxo-button-options>
          </dxi-item>
        </dx-form>
      </div>
    </form>
  </div>
</dx-popup>
