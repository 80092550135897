import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CreateSmsTemplateCommand, MessageType, MessageTypeDto, SmsTemplateDto, SmsTemplateService, UpdateSmsTemplateCommand} from "../../shared/services/swagger";
import {DxHtmlEditorComponent} from "devextreme-angular";

@Component({
  selector: 'app-sms-template-form-popup',
  templateUrl: './sms-template-form-popup.component.html',
  styleUrl: './sms-template-form-popup.component.scss'
})
export class SmsTemplateFormPopupComponent {
  @ViewChild(DxHtmlEditorComponent, {static: false}) htmlEditor!: DxHtmlEditorComponent;

  @Input() visible: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() templateId: number | undefined = undefined;
  @Input() clinicId: number | null = null;
  @Input() isReadonly: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() saveTemplate = new EventEmitter<CreateSmsTemplateCommand | UpdateSmsTemplateCommand>();

  messageTypes: { value: MessageType, name: string }[] = [];
  availableParameters: string[] = [];
  formData: any = {};
  smsTemplate: SmsTemplateDto = {} as SmsTemplateDto;

  constructor(
    private smsTemplateService: SmsTemplateService
  ) {
  }

  onShowing() {
    this.resetForm();
    this.initializeForm();
  }

  initializeForm() {
    this.loadMessageTypes();

    if ((this.isEditing || this.isReadonly) && this.templateId != undefined) {
      this.smsTemplateService.getSmsTemplateById(this.templateId).subscribe({
        next: (smsTemplateDto: SmsTemplateDto) => {
          this.smsTemplate = smsTemplateDto;
          this.formData = {
            messageType: this.smsTemplate.messageType,
            text: this.smsTemplate.text
          };
          this.loadAvailableParameters(this.smsTemplate.messageType);
        },
        error: err => {
          console.error('Error fetching SMS template:', err);
        }
      });
    }
  }

  resetForm() {
    this.formData = {};
    this.availableParameters = [];
    this.messageTypes = [];
  }

  onHiding() {
    this.visibleChange.emit(false);
  }

  loadMessageTypes() {
    if (this.isEditing || this.isReadonly) {
      this.messageTypes = Object.entries(MessageType).map(([key, value]) => ({value, name: key}));
    } else {
      if (this.clinicId === null) {
        console.error('Clinic ID is null');
        return;
      }
      this.smsTemplateService.getUndefinedSmsTypes(this.clinicId).subscribe({
        next: (types: MessageType[]) => {
          this.messageTypes = types.map(type => ({value: type, name: MessageType[type]}));
        },
        error: err => {
          console.error('Error fetching undefined SMS types:', err);
          this.messageTypes = [];
        }
      });
    }
  }

  onMessageTypeChange(messageType: MessageType) {
    if (messageType !== undefined && messageType !== null) {
      this.loadAvailableParameters(messageType);
    }
  }

  loadAvailableParameters(messageType: MessageType) {
    this.smsTemplateService.getParametersForSmsType(messageType).subscribe({
      next: (response: MessageTypeDto) => {
        this.availableParameters = response.parameters!;
      },
      error: err => {
        console.error('Error fetching parameters:', err);
        this.availableParameters = [];
      }
    });
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const templateData = {
      messageType: this.formData.messageType,
      text: this.formData.text
    };

    this.saveTemplate.emit(templateData);
    this.onHiding();
  }

  insertParameterToEditor(param: string) {
    if (!this.isReadonly && this.htmlEditor) {
      const paramText = `{{${param}}}`;
      const selection = this.htmlEditor.instance.getSelection();
      const index = selection ? selection.index : 0;
      this.htmlEditor.instance.insertText(index, paramText, {});
    }
  }
}
