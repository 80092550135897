<dx-popup
  [dragEnabled]="false"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [showTitle]="true"

  [title]="(isEditing ? 'doctorForm.updateTitle' : 'doctorForm.createTitle') | translate"
  height="auto"
  width="600"

  (onShowing)="onShowing()"
  (onHiding)="onHiding()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'">
    <form (submit)="onSubmit($event)">
      <dx-form [formData]="formData" labelLocation="left" [showColonAfterLabel]="true" [alignItemLabels]="true">
        <dxi-item [editorOptions]="{ stylingMode: 'filled',  placeholder: ('doctorForm.firstName' | translate) }" dataField="firstName"
                  editorType="dxTextBox">
          <dxi-validation-rule [message]="'doctorForm.firstNameRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'doctorForm.firstName' | translate"></dxo-label>
        </dxi-item>

        <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: ('doctorForm.familyName' | translate) }" dataField="familyName"
                  editorType="dxTextBox">
          <dxi-validation-rule [message]="'doctorForm.familyNameRequired' | translate" type="required"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'doctorForm.familyName' | translate"></dxo-label>
        </dxi-item>

        <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: ('doctorForm.email' | translate) }" dataField="email" editorType="dxTextBox">
          <dxi-validation-rule [message]="'doctorForm.emailRequired' | translate" type="required"></dxi-validation-rule>
          <dxi-validation-rule [message]="'doctorForm.emailInvalid' | translate" type="email"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="'doctorForm.email' | translate"></dxo-label>
        </dxi-item>

        <dxi-item [editorOptions]="{ stylingMode: 'filled', placeholder: ('doctorForm.phoneNumber' | translate)}" dataField="phoneNumber" editorType="dxTextBox">
          <dxi-validation-rule [message]="('validation.phoneNumberRequired') | translate" type="required"></dxi-validation-rule>
          <dxi-validation-rule [message]="('validation.phoneNumberInvalid') | translate" [pattern]="phonePattern"
                               type="pattern"></dxi-validation-rule>
          <dxo-label [visible]="true" [text]="('doctorForm.phoneNumber') | translate"></dxo-label>
        </dxi-item>

        <dxi-item *ngIf="isEditing" [editorOptions]="{ stylingMode: 'filled', placeholder: ('doctorForm.doctorFunction' | translate) }" dataField="doctorFunction" editorType="dxTextBox">
          <dxo-label [visible]="true" [text]="'doctorForm.function' | translate"></dxo-label>
        </dxi-item>

        <dxi-item itemType="button">
          <dxo-button-options
            [text]="(isEditing ? 'doctorForm.updateDoctor' : 'doctorForm.registerDoctor') | translate"
            [useSubmitBehavior]="true"
            type="success"
            width="100%"
          >
          </dxo-button-options>
        </dxi-item>
      </dx-form>

    </form>
  </div>
</dx-popup>
