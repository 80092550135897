<app-patient-register-popup
  (createPatient)="createPatient($event)"
  [(visible)]="createPatientPopupVisible">
</app-patient-register-popup>

<h2 class="content-block">{{ 'patientList.title' | translate }}</h2>
<div class="content-block">

  <div class="dx-card responsive-paddings grid-container">
    <dx-data-grid
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [showBorders]="true"
      [showColumnLines]="true"
      [showRowLines]="true"

      [dataSource]="dataSource"
      (onSelectionChanged)="onSelectionChanged($event)"
      [(selectedRowKeys)]="selectedRowKeys"
      class="grid"
    >
      <dxo-toolbar>
        <dxi-item locateInMenu="auto" location="before">
          <div *dxTemplate>
            <dx-button
              (click)="navigateToPatientTests(selectedRow!.id)"
              [disabled]="!selectedRow"
              [text]="'patientList.viewPatientTests' | translate"
              icon="eyeopen"
              stylingMode="contained"
            ></dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="before">
          <div *dxTemplate>
            <dx-button
              (click)="navigateToPatientInformation(selectedRow!.id)"
              [disabled]="!selectedRow"
              [text]="'patientList.viewPatientInformation' | translate"
              icon="info"
              stylingMode="contained"
            ></dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="after" *ngIf="isAdmin">
          <div *dxTemplate>
            <dx-button
              (onClick)="deletePatient()"
              [disabled]="!this.selectedRow"
              icon='remove'
              [text]="('patientList.delete') | translate"
              type="danger"
              stylingMode="contained"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="after">
          <div *dxTemplate>
            <dx-button
              (onClick)="this.createPatientPopupVisible = true"
              icon="plus"
              [text]="'patientList.registerNewPatient' | translate"
              stylingMode="contained"
              type="success"
            ></dx-button>
          </div>
        </dxi-item>
      </dxo-toolbar>

      <dxo-selection mode="single"></dxo-selection>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"></dxo-pager>
      <dxo-filter-row [visible]="true"></dxo-filter-row>

      <dxi-column
        alignment="center"
        [caption]="'patientList.familyName' | translate"
        dataField="familyName"
        [sortIndex]="0"
        sortOrder="asc"
      ></dxi-column>

      <dxi-column
        alignment="center"
        [caption]="'patientList.firstName' | translate"
        dataField="firstName"
        [sortIndex]="1"
        sortOrder="asc"
      ></dxi-column>

      <dxi-column
        alignment="center"
        [caption]="'patientList.email' | translate"
        dataField="email"
      ></dxi-column>

      <dxi-column
        alignment="center"
        [caption]="'patientList.phoneNumber' | translate"
        dataField="phoneNumber"
      ></dxi-column>

    </dx-data-grid>
  </div>
</div>
