<dx-popup
  [dragEnabled]="false"
  [showTitle]="true"
  [hideOnOutsideClick]="true"

  [title]="(isEditing ? 'clinicForm.updateTitle' : 'clinicForm.createTitle') | translate"
  [height]="'auto'"
  width="80%"

  (onShowing)="onShowing()"
  (onHiding)="onHiding()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'" class="custom-popup-content">
    <form (submit)="onSubmit($event)">
      <div class="form-container">
        <div class="form-layout">
          <div class="left-column">
            <dx-form validationGroup="clinicFormValidation"
                     [formData]="formData"
                     labelLocation="top"
                     [showColonAfterLabel]="true"
                     [alignItemLabels]="true">
              <dxi-item
                [editorOptions]="{ stylingMode: 'filled', placeholder: ('clinicForm.name') | translate }"
                dataField="name"
                editorType="dxTextBox">
                <dxi-validation-rule [message]="('validation.nameRequired') | translate" type="required"></dxi-validation-rule>
                <dxo-label [visible]="true" [text]="('clinicForm.name') | translate"></dxo-label>
              </dxi-item>

              <dxi-item
                [editorOptions]="{ stylingMode: 'filled', placeholder: ('clinicForm.location') | translate }"
                dataField="location"
                editorType="dxTextBox">
                <dxi-validation-rule [message]="('validation.locationRequired') | translate" type="required"></dxi-validation-rule>
                <dxo-label [visible]="true" [text]="('clinicForm.location') | translate"></dxo-label>
              </dxi-item>

              <dxi-item
                [editorOptions]="{ stylingMode: 'filled', placeholder: ('clinicForm.email') | translate }"
                dataField="email"
                editorType="dxTextBox">
                <dxi-validation-rule [message]="('clinicForm.emailRequired') | translate" type="required"></dxi-validation-rule>
                <dxi-validation-rule [message]="('clinicForm.emailInvalid') | translate" type="email"></dxi-validation-rule>
                <dxo-label [visible]="true" [text]="('clinicForm.email') | translate"></dxo-label>
              </dxi-item>

              <dxi-item
                [editorOptions]="{ stylingMode: 'filled', placeholder: ('clinicForm.phoneNumber') | translate }"
                dataField="contactNumber"
                editorType="dxTextBox">
                <dxi-validation-rule [message]="('validation.phoneNumberRequired') | translate"
                                     type="required"></dxi-validation-rule>
                <dxi-validation-rule [pattern]="phonePattern"
                                     [message]="('validation.phoneNumberInvalid') | translate"
                                     type="pattern"></dxi-validation-rule>
                <dxo-label [visible]="true" [text]="('clinicForm.phoneNumber') | translate"></dxo-label>
              </dxi-item>

              <dxi-item
                [editorOptions]="{ stylingMode: 'filled', placeholder: ('clinicForm.sendgridApiKey') | translate }"
                dataField="sendgridApiKey"
                editorType="dxTextBox">
                <dxi-validation-rule [message]="('clinicForm.sendgridApiKeyRequired') | translate"
                                     type="required"></dxi-validation-rule>
                <dxo-label [visible]="true" [text]="('clinicForm.sendgridApiKey') | translate"></dxo-label>
              </dxi-item>

              <dxi-item>
                <dxo-label [text]="'clinicForm.theme' | translate" [visible]="true"></dxo-label>
                <theme-selector (themeChanged)="onThemeChanged($event)"></theme-selector>
              </dxi-item>
            </dx-form>
          </div>

          <div class="right-column">
            <div class="image-upload-container">
              <label class="image-upload-label">{{ ('clinicForm.clinicLogo') | translate }}</label>
              <dx-file-uploader
                [multiple]="false"
                [allowedFileExtensions]="['.jpg', '.jpeg', '.png']"
                uploadMode="useForm"
                (onValueChanged)="onFileSelected($event)"
                [selectButtonText]="('fileUploader.selectAFile') | translate"
                [readyToUploadMessage]="('fileUploader.readyToUpload') | translate"
                [invalidFileExtensionMessage]="('fileUploader.invalidFileExtension') | translate"
                [labelText]="('clinicForm.uploadImage') | translate"
              >
              </dx-file-uploader>
            </div>
            <div class="image-preview-container" *ngIf="getImageSource()">
              <img [src]="getImageSource()" [alt]="('clinicForm.clinicLogo') | translate" class="preview-image">
            </div>
          </div>
        </div>

        <div class="submit-button-container">
          <dx-button
            [text]="(isEditing ? 'clinicForm.updateClinic' : 'clinicForm.createNewClinic') | translate"
            [useSubmitBehavior]="true"
            type="success"
            validationGroup="clinicFormValidation">
          </dx-button>
        </div>
      </div>
    </form>
  </div>
</dx-popup>
