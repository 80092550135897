  import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
  import {UserRegisterCommand} from "../../shared/services/swagger";
  import {DxFormComponent} from "devextreme-angular";
  import {GlobalConfig} from "../../shared/services/global-variables";

  @Component({
    selector: 'app-patient-register-popup',
    templateUrl: './patient-register-popup.component.html',
    styleUrl: './patient-register-popup.component.scss'
  })
  export class PatientRegisterPopupComponent {
    @Input() visible: boolean = false;
    @Output() visibleChange = new EventEmitter<boolean>();
    @Output() createPatient = new EventEmitter<UserRegisterCommand>();

    formData: any = {};
    phonePattern = GlobalConfig.phoneNumberPattern;

    onHiding() {
      this.formData = {}
      this.visibleChange.emit(false);
    }

    onSubmit($event: SubmitEvent) {
      $event.preventDefault();

      const patientRegisterRequest: UserRegisterCommand = {
        email: this.formData.email,
        familyName: this.formData.familyName,
        firstName: this.formData.firstName,
        phoneNumber: this.formData.phoneNumber
      }

      this.createPatient.emit(patientRegisterRequest);
    }
  }
