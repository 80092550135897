import {Component, OnInit} from '@angular/core';
import DataSource from "devextreme/data/data_source";
import {CreateSmsTemplateCommand, GroupingInfo, SmsTemplateDto, SmsTemplateService, SortingInfo, SummaryInfo, UpdateSmsTemplateCommand} from "../../shared/services/swagger";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../shared/services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import CustomStore from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data";
import {lastValueFrom} from "rxjs";
import {confirm} from "devextreme/ui/dialog";
import {TokenService} from "../../shared/services/token.service";
import {HelpPopupDataService} from "../../shared/services/help-popup-data.service";

@Component({
  selector: 'app-manage-sms-templates',
  templateUrl: './manage-sms-templates.component.html',
  styleUrl: './manage-sms-templates.component.scss'
})
export class ManageSmsTemplatesComponent implements OnInit {
  // @ts-ignore
  dataSource: DataSource;
  clinicId: number = 0;
  selectedRow: SmsTemplateDto | null = null;

  formPopupVisible: boolean = false;
  isEditing: boolean = false;
  isReadonly: boolean = false;
  helpPopupVisible: boolean = false;
  helpPopupData: {} = {};
  allTemplatesAreCustom: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private smsTemplateService: SmsTemplateService,
    private translate: TranslateService,
    protected tokenService: TokenService,
    private helpPopupDataService: HelpPopupDataService
  ) {
    this.helpPopupData = helpPopupDataService.getManageSmsHelpDataSource();
  }

  ngOnInit(): void {
    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    this.initializeDataSource();
    this.areAllTemplatesCustom();
  }

  private initializeDataSource(): void {
    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: async (loadOptions: LoadOptions) => {
          try {
            let response = await lastValueFrom(
              this.smsTemplateService.getTemplatesWithDefaults(
                this.clinicId,
                loadOptions.requireTotalCount,
                loadOptions.requireGroupCount,
                false, // isCountQuery
                false, // isSummaryQuery
                loadOptions.skip,
                loadOptions.take,
                loadOptions.sort as SortingInfo[],
                loadOptions.group as GroupingInfo[],
                loadOptions.filter,
                loadOptions.totalSummary as SummaryInfo[],
                loadOptions.groupSummary as SummaryInfo[],
                loadOptions.select as string[],
                undefined, // preSelect
                undefined, // remoteSelect
                undefined, // remoteGrouping
                undefined, // expandLinqSumType
                undefined, // primaryKey
                undefined, // defaultSort
                undefined, // stringToLower
                undefined, // paginateViaPrimaryKey
                undefined, // sortByPrimaryKey
                undefined  // allowAsyncOverSync
              )
            );

            return {
              data: response.data,
              totalCount: response.totalCount,
              summary: response.summary,
              groupCount: response.groupCount,
            };
          } catch (e) {
            console.log(e);
            throw 'Data loading error';
          }
        },
        remove: (key) => lastValueFrom(this.smsTemplateService._delete(key))
      }),
    });
  }

  areAllTemplatesCustom(){
    this.smsTemplateService.getUndefinedSmsTypes(this.clinicId).subscribe({
      next: data => {
        this.allTemplatesAreCustom = data.length == 0;
      }
    })
  }

  navigateToClinicList() {
    this.router.navigate(["/pages/clinic-list"])
  }

  onSelectionChanged(e: any) {
    this.selectedRow = e.selectedRowsData[0];
  }

  openCreatePopup() {
    this.isEditing = false;
    this.isReadonly = false;
    this.formPopupVisible = true;
  }

  openUpdatePopup() {
    if (this.selectedRow) {
      this.isReadonly = false;
      this.isEditing = true;
      this.formPopupVisible = true;
    }
  }

  openDisplayPopup(){
    this.isEditing = false;
    this.isReadonly = true;
    this.formPopupVisible = true;
  }

  onSaveSmsTemplate(templateData: CreateSmsTemplateCommand | UpdateSmsTemplateCommand) {
    if (this.isEditing) {
      this.updateSmsTemplate(templateData as UpdateSmsTemplateCommand);
    } else {
      this.createSmsTemplate(templateData as CreateSmsTemplateCommand);
    }
  }

  updateSmsTemplate(templateData: UpdateSmsTemplateCommand) {
    this.smsTemplateService.updateSmsTemplate(this.clinicId, this.selectedRow!.id, templateData).subscribe({
      next: value => {
        this.notificationService.success(this.translate.instant("manageSmsTemplates.updated"));
        this.dataSource.reload();
      },
      error: err => {
        this.notificationService.error(this.translate.instant("manageSmsTemplates.errorUpdating"));
        console.log(err);
      }
    })
  }

  createSmsTemplate(templateData: CreateSmsTemplateCommand) {
    this.smsTemplateService.addSmsTemplateToClinic(this.clinicId, templateData).subscribe({
      next: value => {
        this.notificationService.success(this.translate.instant("manageSmsTemplates.created"));
        this.dataSource.reload();
        this.areAllTemplatesCustom();
      },
      error: err => {
        console.log(err);
        this.notificationService.error(this.translate.instant("manageSmsTemplates.errorCreating"));
      }
    })
  }

  deleteSmsTemplate(): void {
    if (this.selectedRow) {
      confirm(this.translate.instant("manageSmsTemplates.confirmDeletion"), this.translate.instant("testTypeList.confirmDeletionTitle"))
        .then((dialogResult: boolean) => {
          if (dialogResult) {
            this.dataSource.store().remove(this.selectedRow!.id)
              .then(() => {
                this.notificationService.success(this.translate.instant("manageSmsTemplates.deleted"));
                this.deselectRow();
                this.dataSource.reload();
                this.areAllTemplatesCustom();
              })
              .catch((err: any) => {
                console.log(err);
                this.notificationService.error(this.translate.instant("manageSmsTemplates.errorDeleting"));
              });
          }
        });
    }
  }

  private deselectRow() {
    this.selectedRow = null;
  }

}
