<dx-popup
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  [dragEnabled]="false"
  [title]="isReadonly ? ('emailTemplateForm.viewTitle' | translate) : (isEditing ? 'emailTemplateForm.updateTitle' : 'emailTemplateForm.createTitle') | translate"
  height="auto"
  width="80%"
  (onHiding)="onHiding()"
  (onShowing)="onShowing()"
  [(visible)]="visible"
>
  <div *dxTemplate="let data of 'content'" class="email-template-content">
    <form (submit)="onSubmit($event)" class="email-template-form">
      <dx-form validationGroup="emailTemplateValidation" [formData]="formData" labelLocation="top" [showColonAfterLabel]="true"
               [alignItemLabels]="true">
        <dxi-item>
          <dxo-label [text]="'emailTemplateForm.messageType' | translate" [visible]="true"></dxo-label>
          <dx-select-box
            [(value)]="formData.messageType"
            [items]="messageTypes"
            displayExpr="name"
            valueExpr="value"
            [placeholder]="'emailTemplateForm.messageType' | translate"
            [width]="'100%'"
            (onValueChanged)="onMessageTypeChange($event.value)"
            [disabled]="isEditing || isReadonly">
            <dx-validator validationGroup="emailTemplateValidation">
              <dxi-validation-rule
                type="required"
                [message]="'emailTemplateForm.messageTypeRequired' | translate"
              ></dxi-validation-rule>
            </dx-validator>

          </dx-select-box>
        </dxi-item>

        <dxi-item
          dataField="subject"
          editorType="dxTextBox"
          [editorOptions]="{
              stylingMode: 'filled',
              placeholder:('emailTemplateForm.subject' | translate),
              readOnly: isReadonly
            }">
          <dxo-label [text]="'emailTemplateForm.subject' | translate" [visible]="true"></dxo-label>
          <dxi-validation-rule
            type="required"
            [message]="'emailTemplateForm.subjectRequired' | translate"
          ></dxi-validation-rule>
        </dxi-item>

        <dxi-item>
          <dxo-label [text]="'emailTemplateForm.htmlEditor' | translate" [visible]="true"></dxo-label>
          <dx-html-editor height="auto" [(value)]="formData.htmlContent" [readOnly]="isReadonly">
            <dxo-toolbar [multiline]="true" *ngIf="!isReadonly">
              <dxi-item name="undo"></dxi-item>
              <dxi-item name="redo"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item
                name="size"
                [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
                [options]="{ inputAttr: { 'aria-label': 'Font size' } }"
              ></dxi-item>
              <dxi-item
                name="font"
                [acceptedValues]="[
              'Arial',
              'Courier New',
              'Georgia',
              'Impact',
              'Lucida Console',
              'Tahoma',
              'Times New Roman',
              'Verdana'
            ]"
                [options]="{ inputAttr: { 'aria-label': 'Font family' } }"
              ></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="bold"></dxi-item>
              <dxi-item name="italic"></dxi-item>
              <dxi-item name="strike"></dxi-item>
              <dxi-item name="underline"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="alignLeft"></dxi-item>
              <dxi-item name="alignCenter"></dxi-item>
              <dxi-item name="alignRight"></dxi-item>
              <dxi-item name="alignJustify"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="orderedList"></dxi-item>
              <dxi-item name="bulletList"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item
                name="header"
                [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                [options]="{ inputAttr: { 'aria-label': 'Header' } }"
              ></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="color"></dxi-item>
              <dxi-item name="background"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="link"></dxi-item>
              <dxi-item name="image"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="clear"></dxi-item>
              <!--              <dxi-item name="codeBlock"></dxi-item>-->
              <!--              <dxi-item name="blockquote"></dxi-item>-->
              <!--              <dxi-item name="separator"></dxi-item>-->
              <!--              <dxi-item name="insertTable"></dxi-item>-->
              <!--              <dxi-item name="deleteTable"></dxi-item>-->
              <!--              <dxi-item name="insertRowAbove"></dxi-item>-->
              <!--              <dxi-item name="insertRowBelow"></dxi-item>-->
              <!--              <dxi-item name="deleteRow"></dxi-item>-->
              <!--              <dxi-item name="insertColumnLeft"></dxi-item>-->
              <!--              <dxi-item name="insertColumnRight"></dxi-item>-->
              <!--              <dxi-item name="deleteColumn"></dxi-item>-->
            </dxo-toolbar>
            <dxo-media-resizing [enabled]="true"></dxo-media-resizing>
            <dx-validator validationGroup="emailTemplateValidation">
              <dxi-validation-rule
                type="required"
                [message]="'emailTemplateForm.contentRequired' | translate"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-html-editor>
        </dxi-item>
      </dx-form>

      <div class="submit-button-container" *ngIf="!isReadonly">
        <dx-button
          [text]="'emailTemplateForm.save' | translate"
          type="success"
          [width]="200"
          validationGroup="emailTemplateValidation"
          [useSubmitBehavior]="true"
        ></dx-button>
      </div>

    </form>

    <div class="email-type-parameters">
      <h3>{{ 'emailTemplateForm.availableParameters'| translate }}</h3>
      <div *ngIf="availableParameters.length > 0">
        <div *ngFor="let param of availableParameters" class="parameter-item">
          <p><strong>
            <a href="javascript:void(0)" (click)="!isReadonly && insertParameterToEditor(param)">{{ param }}</a>
          </strong></p>
          <p>{{ 'emailTemplateForm.parameterDescriptions.' + param | translate }}</p>
        </div>
      </div>
      <p *ngIf="availableParameters.length === 0">{{ 'emailTemplateForm.noParametersAvailable'  | translate }}</p>
    </div>
  </div>
</dx-popup>
