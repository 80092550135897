<h2 class="content-block">{{ 'doctorList.title' | translate }}</h2>
<div class="content-block">
  <div class="dx-card responsive-paddings grid-container">
    <dx-data-grid
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [showBorders]="true"
      [showColumnLines]="true"
      [showRowLines]="true"

      (onSelectionChanged)="onSelectionChanged($event)"
      [(selectedRowKeys)]="selectedRowKeys"
      [dataSource]="dataSource"
      class="grid"
    >

      <dxo-selection mode="single"></dxo-selection>
      <dxo-remote-operations [groupPaging]="true"></dxo-remote-operations>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"></dxo-pager>
      <dxo-filter-row [visible]="true">
        <dxo-operation-descriptions
          [between]="'filterRow.operationDescriptions.between' | translate"
          [contains]="'filterRow.operationDescriptions.contains' | translate"
          [endsWith]="'filterRow.operationDescriptions.endsWith' | translate"
          [equal]="'filterRow.operationDescriptions.equal' | translate"
          [greaterThan]="'filterRow.operationDescriptions.greaterThan' | translate"
          [greaterThanOrEqual]="'filterRow.operationDescriptions.greaterThanOrEqual' | translate"
          [lessThan]="'filterRow.operationDescriptions.lessThan' | translate"
          [lessThanOrEqual]="'filterRow.operationDescriptions.lessThanOrEqual' | translate"
          [notContains]="'filterRow.operationDescriptions.notContains' | translate"
          [notEqual]="'filterRow.operationDescriptions.notEqual' | translate"
          [startsWith]="'filterRow.operationDescriptions.startsWith' | translate"
        ></dxo-operation-descriptions>
      </dxo-filter-row>

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto" *ngIf="role===Role.Admin">
          <div *dxTemplate>
            <dx-button
              (onClick)="openUpdatePopup()"
              [disabled]="!this.selectedRow"
              icon="edit"
              type="default"
              [text]="'doctorList.updateDoctor' | translate"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item locateInMenu="auto" location="before" *ngIf="isAdmin">
          <div *dxTemplate>
            <dx-button
              (onClick)="deleteDoctor()"
              [disabled]="!this.selectedRow"
              icon="remove"
              [text]="('doctorList.delete') | translate"
              type="danger"
              stylingMode="contained"
            >
            </dx-button>
          </div>
        </dxi-item>

        <dxi-item location="after" locateInMenu="auto" *ngIf="role===Role.Admin">
          <div *dxTemplate>
            <dx-button
              (onClick)="openCreatePopup()"
              icon="plus"
              [text]="'doctorList.registerNewDoctor' | translate"
              stylingMode="contained"
              type="success"
            >
            </dx-button>
          </div>
        </dxi-item>
      </dxo-toolbar>

      <dxi-column
        alignment="center"
        [caption]="'doctorList.firstName' | translate"
        dataField="firstName">
      </dxi-column>

      <dxi-column
        alignment="center"
        [caption]="'doctorList.lastName' | translate"
        dataField="familyName">
      </dxi-column>

      <dxi-column
        alignment="center"
        [caption]="'doctorList.email' | translate"
        dataField="email">
      </dxi-column>

      <dxi-column
        alignment="center"
        [caption]="'doctorList.phoneNumber' | translate"
        dataField="phoneNumber">
      </dxi-column>

      <dxi-column
        alignment="center"
        [caption]="'doctorList.function' | translate"
        dataField="doctorFunction">
      </dxi-column>

    </dx-data-grid>

  </div>
</div>

<app-doctor-form-popup
  [(visible)]="popupVisible"
  [isEditing]="isEditing"
  [doctorId]="selectedRow?.id"
  (saveDoctor)="onSaveDoctor($event)"
>
</app-doctor-form-popup>
