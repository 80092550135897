import {Component, Input} from '@angular/core';
import {PatientDto} from "../../shared/services/swagger";

@Component({
  selector: 'app-patient-info-display',
  templateUrl: './patient-info-display.component.html',
  styleUrl: './patient-info-display.component.scss'
})
export class PatientInfoDisplayComponent {
  @Input() patient: PatientDto = {} as PatientDto;
}
