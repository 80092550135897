import {CommonModule} from '@angular/common';
import {Component, NgModule, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {DxFormModule} from 'devextreme-angular/ui/form';
import {DxLoadIndicatorModule} from 'devextreme-angular/ui/load-indicator';
import {AuthenticationService, ClinicDto, ClinicService, ForgotPasswordCommand} from "../../services/swagger";
import {NotificationService} from "../../services/notification.service";
import {ClinicDisplayComponent} from "../../../components/clinic-display/clinic-display.component";
import {ImageUtilsService} from "../../services/image-utils.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DxButtonModule} from "devextreme-angular";


@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {
  loading = false;
  clinic: ClinicDto = {} as ClinicDto;

  formData: ForgotPasswordCommand = {
    email: ''
  };

  constructor(
    private authenticationService: AuthenticationService,
    private clinicService: ClinicService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const clinicId = +params.get('clinicId')!;
      this.loadClinic(clinicId);
    });
  }

  loadClinic(clinicId: number) {
    this.clinicService.getClinicById(clinicId).subscribe({
      next: result => {
        this.clinic = result;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  async onSubmit(e: Event) {
    e.preventDefault();

    const forgotPasswordRequest: ForgotPasswordCommand = {
      email: this.formData.email
    }

    this.loading = true;
    this.authenticationService.forgotPassword(this.clinic.id, forgotPasswordRequest).subscribe({
      next: () => {
        this.notificationService.success(this.translate.instant("forgotPassword.resetEmailSent"));
        this.router.navigate(['/change-password', this.clinic.id]);
      },
      error: (err) => {
        switch (err.status) {
          case 404:
            this.notificationService.warning(this.translate.instant("authentication.userNotFound"));
            break;
          default:
            this.notificationService.error(this.translate.instant("forgotPassword.errorUpdatingPassword"));
            console.log(err);
            break;
        }

        this.loading = false;
      },
      complete: () => this.loading = false
    });
  }
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxFormModule,
        DxLoadIndicatorModule,
        ClinicDisplayComponent,
        TranslateModule,
        DxButtonModule
    ],
  declarations: [ForgotPasswordFormComponent],
  exports: [ForgotPasswordFormComponent]
})
export class ResetPasswordFormModule {
}
