import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  formatToRomanianTime(date: Date | string, format: string = 'dd.MM.yyyy HH:mm'): string {
    return formatDate(date, format, this.locale);
  }
}
