<div *ngIf="clinic" class="clinic-info">
  <div class="clinic-logo-container">
    <img *ngIf="clinic.logo" [src]="this.imageUtils.getImageSource(this.clinic.logo)" [alt]="'clinicInfo.clinicLogo' | translate" class="clinic-logo">
  </div>
  <h2 class="clinic-name">{{ clinic.name }}</h2>
  <div class="clinic-details">
    <p><strong>{{ 'clinicInfo.email' | translate }}:</strong> {{ clinic.email }}</p>
    <p><strong>{{ 'clinicInfo.location' | translate }}:</strong> {{ clinic.location }}</p>
    <p><strong>{{ 'clinicInfo.contact' | translate }}:</strong> {{ clinic.contactNumber }}</p>
  </div>
</div>
